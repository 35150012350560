import React, { useState } from "react";
import { Steps, Step } from "react-step-builder";
import FormStep2 from "./step2";
import FormStep3 from "./step3";
import VarsityBabyAndUpperclassmen from "./varsityBabystep4";
import UpperClassmenStep5 from "./upperClassmenStep5";

export default ({ name, packageData, error }) => {
  const formHeaderbg =
    name === "littleknowitall"
      ? "formHeadingPink"
      : name === "varsitybaby"
      ? "formHeadingBlue"
      : name === "upperclassmen"
      ? "formHeadingPurple"
      : "formHeadingPurple";

  const [gloabalState, setGloabalState] = useState({});
  const handleChangeState = (e) => {
    setGloabalState({
      ...gloabalState,
      [e.target.name]: e.target.files ? e.target.files[0] : e.target.value,
    });
  };

  const formHeading =
    name === "littleknowitall"
      ? "Little Know It All - Option 1"
      : name === "varsitybaby"
      ? "Varsity Baby - Option 2"
      : name === "upperclassmen"
      ? "Upperclassmen - Option 3"
      : "Upperclassmen - Option 3";

  if (name === "littleknowitall") {
    return (
      <div className="container py-5">
        <div className="FormMainDiv">
          <div className={`${formHeaderbg} p-3 d-flex justify-content-center`}>
            <p className="m-0 text-white fw-600 font-size-26">{formHeading}</p>
          </div>
          <div>
            <div className="px-0 px-md-5">
              <div className="">
                <Steps>
                  {/* <Step
                    name={name}
                    handleChangeState={handleChangeState}
                    gloabalState={gloabalState}
                    component={FormStep1}
                  /> */}
                  <Step
                    error={error}
                    packageData={packageData}
                    name={name}
                    handleChangeState={handleChangeState}
                    gloabalState={gloabalState}
                    component={FormStep2}
                  />

                  <Step
                    name={name}
                    handleChangeState={handleChangeState}
                    component={FormStep3}
                  />
                </Steps>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (name === "varsitybaby") {
    return (
      <div className="container py-5">
        <div className="FormMainDiv">
          <div className={`${formHeaderbg} p-3 d-flex justify-content-center`}>
            <p className="m-0 text-white fw-600 font-size-26">{formHeading}</p>
          </div>
          <div>
            <div className="px-md-5 px-0">
              <div className="">
                <Steps>
                  <Step
                    packageData={packageData}
                    name={name}
                    handleChangeState={handleChangeState}
                    gloabalState={gloabalState}
                    component={FormStep2}
                  />

                  <Step
                    name={name}
                    handleChangeState={handleChangeState}
                    gloabalState={gloabalState}
                    component={VarsityBabyAndUpperclassmen}
                  />
                  <Step
                    name={name}
                    handleChangeState={handleChangeState}
                    component={FormStep3}
                  />
                </Steps>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (name === "upperclassmen") {
    return (
      <div className="container py-5">
        <div className="FormMainDiv">
          <div className={`${formHeaderbg} p-3 d-flex justify-content-center`}>
            <p className="m-0 text-white fw-600 font-size-26">{formHeading}</p>
          </div>
          <div>
            <div className="px-md-5 px-0">
              <div className="">
                <Steps>
                  <Step
                    name={name}
                    packageData={packageData}
                    handleChangeState={handleChangeState}
                    gloabalState={gloabalState}
                    component={FormStep2}
                  />
                  <Step
                    name={name}
                    handleChangeState={handleChangeState}
                    gloabalState={gloabalState}
                    component={VarsityBabyAndUpperclassmen}
                  />
                  <Step
                    name={name}
                    handleChangeState={handleChangeState}
                    gloabalState={gloabalState}
                    component={UpperClassmenStep5}
                  />
                  <Step
                    name={name}
                    handleChangeState={handleChangeState}
                    component={FormStep3}
                  />
                </Steps>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
