import React, { useState, useEffect } from "react";
import pinkHr from "Assets/images/packagesAndDetail/descriptionPinkHr.svg";
import blueHr from "Assets/images/landingpage/educationalHr.svg";
import purpleHr from "Assets/images/packagesAndDetail/purpleHr.svg";
import modalBrowseImg from "Assets/images/forms/modalBrowseImg.svg";
import PhotoUploadModal from "./uploadPhotoModal";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/index";

const VarsityBabyAndUpperclassmen = (props) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [imageFamily, setImageFamily] = useState("");
  const [photoForm, setPhotoForm] = useState("momPhoto");
  const { id } = useParams();
  const [images, setImages] = useState({
    momPhoto: [],
    dadPhoto: [],
    bortherPhoto: [],
    sisterPhoto: [],
    familyPhoto: [],
    grandParentsPhoto: [],
  });

  useEffect(() => {
    let data = {
      userVideoId: id,
    };
    dispatch(actions.packageLoading(true));
    dispatch(actions.getFamilyPics(data));
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const familyImagesData = useSelector(({ PackageReducer }) => PackageReducer);
  let familyImages = familyImagesData?.familyPicsData?.UserVideos[0];
  const loading = useSelector(
    (PackageReducer) => PackageReducer?.PackageReducer?.loading
  );

  useEffect(() => {
    setImageFamily(familyImages);
  }, [familyImagesData]);

  // momPhoto
  const momsPhoto = images?.momPhoto?.filter((item) => {
    if (item.img !== undefined && item.imgLocal !== undefined) {
      return item;
    }
  });
  // dadPhoto
  const dadsPhoto = images?.dadPhoto?.filter((item) => {
    if (item.img !== undefined) {
      return item;
    }
  });

  // brotherPhoto
  const brothersPhoto = images?.bortherPhoto?.filter((item) => {
    if (item.img !== undefined) {
      return item;
    }
  });

  // sisterPhoto
  const sistersPhoto = images?.sisterPhoto?.filter((item) => {
    if (item.img !== undefined) {
      return item;
    }
  });

  // familyPhoto
  const familysPhoto = images?.familyPhoto?.filter((item) => {
    if (item.img !== undefined) {
      return item;
    }
  });

  // grandParentsPhoto
  const grandParentsPhoto = images?.grandParentsPhoto?.filter((item) => {
    if (item.img !== undefined) {
      return item;
    }
  });

  const toggle = (num) => {
    setModal(!modal);
    setPhotoForm(num);
  };

  const onSubmit = () => {
    let data = {
      userVideoId: id,
      MomPics: momsPhoto.map((item) => item.img),
      MomLocalServerPicLink: momsPhoto.map((item) => item.imgLocal),
      DadPics: dadsPhoto.map((item) => item.img),
      ChildDadLoclaserverPicLink: dadsPhoto.map((item) => item.imgLocal),
      BrotherPics: brothersPhoto.map((item) => item.img),
      BrotherLocalServerPicLink: brothersPhoto.map((item) => item.imgLocal),
      SisterPics: sistersPhoto.map((item) => item.img),
      ChildSisterLocalServerPicLink: sistersPhoto.map((item) => item.imgLocal),
      GrandParentPics: grandParentsPhoto.map((item) => item.img),
      ChildGrandParentLocalServerPicLink: grandParentsPhoto.map(
        (item) => item.imgLocal
      ),
      ChildAdditionalFamilyPics: familysPhoto.map((item) => item.img),
      LocalServerFamilyPic: familysPhoto.map((item) => item.imgLocal),
    };
    dispatch(actions.packageLoading(true));
    dispatch(actions.AddFamilyPics(data, props.next));
  };

  const HeadingHr =
    props.name === "littleknowitall"
      ? pinkHr
      : props.name === "varsitybaby"
      ? blueHr
      : props.name === "upperclassmen"
      ? purpleHr
      : purpleHr;

  const payButton =
    props.name === "littleknowitall"
      ? "payButtonPink"
      : props.name === "varsitybaby"
      ? "payButtonBlue"
      : props.name === "upperclassmen"
      ? "payButtonPurple"
      : "payButtonPurple";

  const wizardActive =
    props.name === "littleknowitall"
      ? "circleActivePink"
      : props.name === "varsitybaby"
      ? "circleActiveBlue"
      : props.name === "upperclassmen"
      ? "circleActivePurple"
      : "circleActivePurple";
  const wizardComplete =
    props.name === "littleknowitall"
      ? "circleCompletePink"
      : props.name === "varsitybaby"
      ? "circleCompleteBlue"
      : props.name === "upperclassmen"
      ? "circleCompletePurple"
      : "circleCompletePurple";

  if (loading) {
    return <div style={{ height: "75vh" }}></div>;
  }

  return (
    <div className="p-3">
      <div className="my-4">
        <div className="text-center">
          <h1 className="font-size-22 m-0 color-DarkGray fw-600 ">
            You're doing great!...
          </h1>
          <div className="">
            <p className="font-size-16 my-2  color-Gray fw-600">
              Upload photos for each category (used for family association
              segment/alphabet). Please use the appropriate field for each
              upload..
            </p>
            <img className="" src={HeadingHr} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 d-none d-md-block">
          {props.allSteps?.map((item) => (
            <div
              className={`cursor-pointer ${
                item.order < props.current
                  ? wizardComplete
                  : item.order === props.current
                  ? wizardActive
                  : "circle"
              } `}
              onClick={() => props.jump(item.order)}
            >
              {item.order}
            </div>
          ))}
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-6 mb-4">
              <div style={{ width: "fit-content" }}>
                <p className="font-size-16 my-2 color-Gray fw-700">MOM Photo</p>
                <div
                  style={{ width: "min-content" }}
                  onClick={() => toggle("momPhoto")}
                  className="cursor-pointer"
                >
                  <img src={modalBrowseImg} />
                </div>
                {images?.momPhoto ? (
                  <div className="d-flex">
                    {images?.momPhoto?.map((item) => (
                      <div className="m-2" style={{ width: "40px" }}>
                        <img className="w-100" src={item.img} />
                      </div>
                    ))}
                  </div>
                ) : null}
                <p className="font-size-14 color-Gray fw-700">Up to 2 photos</p>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div style={{ width: "fit-content" }}>
                <p className="font-size-16 my-2 color-Gray fw-700">DAD Photo</p>
                <div
                  style={{ width: "min-content" }}
                  onClick={() => toggle("dadPhoto")}
                  className="cursor-pointer"
                >
                  <img src={modalBrowseImg} />
                </div>
                {images?.dadPhoto ? (
                  <div className="d-flex">
                    {images?.dadPhoto?.map((item) => (
                      <div className="m-2" style={{ width: "40px" }}>
                        <img className="w-100" src={item.img} />
                      </div>
                    ))}
                  </div>
                ) : null}
                <p className="font-size-14 color-Gray fw-700">Up to 2 photos</p>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div style={{ width: "fit-content" }}>
                <p className="font-size-16 my-2 color-Gray fw-700">
                  BROTHER(s) Photo
                </p>
                <div
                  style={{ width: "min-content" }}
                  onClick={() => toggle("brotherPhoto")}
                  className="cursor-pointer"
                >
                  <img src={modalBrowseImg} />
                </div>
                {images?.bortherPhoto ? (
                  <div className="d-flex align-items-center">
                    {images?.bortherPhoto?.map((item) => (
                      <div className="m-2" style={{ width: "40px" }}>
                        <img className="w-100" src={item.img} />
                      </div>
                    ))}
                  </div>
                ) : null}
                <p className="font-size-14 color-Gray fw-700">Up to 4 photos</p>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div style={{ width: "fit-content" }}>
                <p className="font-size-16 my-2 color-Gray fw-700">
                  SISTER(s) Photo
                </p>
                <div
                  style={{ width: "min-content" }}
                  onClick={() => toggle("sisterPhoto")}
                  className="cursor-pointer"
                >
                  <img src={modalBrowseImg} />
                </div>
                {images?.sisterPhoto ? (
                  <div className="d-flex align-items-center">
                    {images?.sisterPhoto?.map((item) => (
                      <div className="m-2" style={{ width: "40px" }}>
                        <img className="w-100" src={item.img} />
                      </div>
                    ))}
                  </div>
                ) : null}
                <p className="font-size-14 color-Gray fw-700">Up to 4 photos</p>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div style={{ width: "fit-content" }}>
                <p className="font-size-16 my-2 color-Gray fw-700">
                  FAMILY(additional photos)
                </p>
                <div
                  style={{ width: "min-content" }}
                  onClick={() => toggle("familyPhoto")}
                  className="cursor-pointer"
                >
                  <img src={modalBrowseImg} />
                </div>
                {images?.familyPhoto ? (
                  <div className="d-flex align-items-center">
                    {images?.familyPhoto?.map((item) => (
                      <div className="m-2" style={{ width: "40px" }}>
                        <img className="w-100" src={item.img} />
                      </div>
                    ))}
                  </div>
                ) : null}
                <p className="font-size-14 color-Gray fw-700">Up to 4 photos</p>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div style={{ width: "fit-content" }}>
                <p className="font-size-16 my-2 color-Gray fw-700">
                  GRANDPARENT(s) Photo
                </p>
                <div
                  style={{ width: "min-content" }}
                  onClick={() => toggle("grandParentsPhoto")}
                  className="cursor-pointer"
                >
                  <img src={modalBrowseImg} />
                </div>
                {images?.grandParentsPhoto ? (
                  <div className="d-flex align-items-center">
                    {images?.grandParentsPhoto?.map((item) => (
                      <div className="m-2" style={{ width: "40px" }}>
                        <img className="w-100" src={item.img} />
                      </div>
                    ))}
                  </div>
                ) : null}
                <p className="font-size-14 color-Gray fw-700">Up to 4 photos</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4 mb-3">
        <button
          // hidden={props.isFirst()}
          disabled={props.isFirst()}
          onClick={props.prev}
          className="backButton  mx-3"
        >
          Back
        </button>
        <button
          // hidden={props.isLast()}
          disabled={props.isLast()}
          onClick={onSubmit}
          className={payButton}
        >
          Save & Next
        </button>
      </div>
      {imageFamily ? (
        <PhotoUploadModal
          familyImages={imageFamily}
          images={images}
          setImages={setImages}
          modal={modal}
          toggle={toggle}
          photoForm={photoForm}
          id={id}
        />
      ) : null}
    </div>
  );
};

export default VarsityBabyAndUpperclassmen;
