import history from "@history";
import axios from "axios";
import Domain from "lib/Config";
import { NotificationManager } from "react-notifications";

export const SIGN_UP_CUSTOMER = "SIGN_UP_CUSTOMER";
export const SIGN_UP_CUSTOMER_ERROR = "SIGN_UP_CUSTOMER_ERROR";
export const SIGN_UP_LOADING = "SIGN_UP_LOADING";

export function signUp(data) {
  const request = axios.post(`${Domain}/user/signup`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response, "Sign up response");
        NotificationManager.success("Registered Successfully Please login");
        dispatch(SignUpLoading(false));
        history.replace("/login");
        return dispatch({
          type: SIGN_UP_CUSTOMER,
          payload: response.data.result,
        });
      })
      .catch((error) => {
        console.log(error?.response, "this is error responsed");
        dispatch(SignUpLoading(false));
        NotificationManager.error(error?.response?.data?.msg);
        // NotificationManager.error(error.response.data.msg);
        // return dispatch({
        //   type: SIGN_UP_CUSTOMER_ERROR,
        //   payload:
        //     error.response && error.response.data.msg
        //       ? error.response.data.msg
        //       : " ERROR! Cannot Signup Customer",
        // });
      });
}

export function SignUpLoading(val) {
  console.log(val);
  return (dispatch) => {
    return dispatch({
      type: SIGN_UP_LOADING,
      payload: val,
    });
  };
}
