import React, { useState, useEffect } from "react";
import pinkHr from "Assets/images/packagesAndDetail/descriptionPinkHr.svg";
import blueHr from "Assets/images/landingpage/educationalHr.svg";
import purpleHr from "Assets/images/packagesAndDetail/purpleHr.svg";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions";
import { useParams } from "react-router";
import { NotificationManager } from "react-notifications";

const FormStep3 = (props) => {
  const dispatch = useDispatch();

  const [LearningvideosIds, setLearningvideosIds] = useState([]);
  const [radioButtonId, setRadioButtonId] = useState({});
  const { id } = useParams();

  const userLearning = useSelector(
    ({ PackageReducer }) => PackageReducer?.userLearningData
  );
  const loading = useSelector(
    (PackageReducer) => PackageReducer?.PackageReducer?.loading
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    let data = {
      userVideoId: id,
    };
    dispatch(actions.packageLoading(true));
    dispatch(actions.getUserLearningData(data));
  }, []);

  useEffect(() => {
    setLearningvideosIds(userLearning?.checkIds);
  }, [userLearning]);

  useEffect(() => {
    const found = userLearning?.UserLearningVideoTypes?.find(
      (element) => element.Isselected === true
    );
    const foundName = found?.FullLearningContents?.Name;
    setRadioButtonId({ id: found?.id, name: foundName });
  }, [userLearning]);

  const HeadingHr =
    props.name === "littleknowitall"
      ? pinkHr
      : props.name === "varsitybaby"
      ? blueHr
      : props.name === "upperclassmen"
      ? purpleHr
      : purpleHr;

  const payButton =
    props.name === "littleknowitall"
      ? "payButtonPink"
      : props.name === "varsitybaby"
      ? "payButtonBlue"
      : props.name === "upperclassmen"
      ? "payButtonPurple"
      : "payButtonPurple";

  const wizardActive =
    props.name === "littleknowitall"
      ? "circleActivePink"
      : props.name === "varsitybaby"
      ? "circleActiveBlue"
      : props.name === "upperclassmen"
      ? "circleActivePurple"
      : "circleActivePurple";

  const wizardComplete =
    props.name === "littleknowitall"
      ? "circleCompletePink"
      : props.name === "varsitybaby"
      ? "circleCompleteBlue"
      : props.name === "upperclassmen"
      ? "circleCompletePurple"
      : "circleCompletePurple";

  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
      LearningvideosIds:
        radioButtonId.name !==
        "Hand pick your content below. Must choose 24 video blocks. Feel free to mix and match!"
          ? []
          : LearningvideosIds,
      userVideoId: parseInt(id),
      PredefinedContentId: [radioButtonId.id],
    };
    let videoLength = 24;
    if (
      radioButtonId.name ===
      "Hand pick your content below. Must choose 24 video blocks. Feel free to mix and match!"
    ) {
      if (LearningvideosIds.length === videoLength) {
        dispatch(actions.packageLoading(true));
        dispatch(actions.upDateUserLearningVideo(data, id, props?.name));
      } else {
        NotificationManager.warning(`You must Choose ${videoLength} Videos`);
      }
    } else {
      dispatch(actions.packageLoading(true));
      dispatch(actions.upDateUserLearningVideo(data, id, props?.name));
    }
  };

  const onChangeRadioButton = (e) => {
    setRadioButtonId({ id: e.target.id, name: e.target.value });
  };

  const onChangeChexkBox = (e) => {
    const found = LearningvideosIds.find(
      (element) => element === parseInt(e.target.id)
    );
    if (found === parseInt(e.target.id)) {
      setLearningvideosIds(
        LearningvideosIds.filter((item) => {
          return item !== parseInt(e.target.id);
        })
      );
    } else {
      setLearningvideosIds([...LearningvideosIds, parseInt(e.target.id)]);
    }
  };

  function capitalize(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }

  if (loading) {
    return <div style={{ height: "75vh" }}></div>;
  }

  return (
    <div className="p-3">
      <div className="my-4">
        <div className="text-center">
          <h1 className="font-size-22 m-0 color-DarkGray fw-600 ">
            Last Step...
          </h1>
          <div className="">
            <p className="font-size-16 my-2  color-Gray fw-600">
              Choose Your Learning Content
            </p>
            <img className="" src={HeadingHr} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 d-none d-md-block">
          {props.allSteps?.map((item) => (
            <div
              className={`cursor-pointer ${
                item.order < props.current
                  ? wizardComplete
                  : item.order === props.current
                  ? wizardActive
                  : "circle"
              } `}
              onClick={() => props.jump(item.order)}
            >
              {item.order}
            </div>
          ))}
        </div>
        <div className="col-md-9">
          <form onSubmit={onSubmit}>
            <div>
              {/*  */}
              <div>
                <div>
                  <p className="font-size-16 my-3  color-Gray fw-600">
                    Choose a preselected content bundle or opt to hand select
                    your content! *
                  </p>
                </div>
                <div className="row">
                  {userLearning?.UserLearningVideoTypes?.map((item) => (
                    <div
                      key={item.id}
                      className="col-md-12 pb-2 d-flex align-items-center"
                    >
                      {console.log(item?.FullLearningContents?.Name, "nameeee")}
                      <input
                        className="mr-10"
                        type="radio"
                        id={item?.id}
                        name="contentToChoose"
                        checked={item.id === parseInt(radioButtonId.id)}
                        required
                        onChange={onChangeRadioButton}
                        value={item?.FullLearningContents?.Name}
                      />
                      <label className="font-size-16 color-Gray fw-500">
                        &nbsp;
                        {item?.FullLearningContents?.Name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              {/* Birth */}
              {userLearning?.LearningVideos?.map((item) => (
                <div key={item.id}>
                  <div>
                    <p className="font-size-16 my-3  color-Gray fw-700">
                      {item?.LearningVideoCategoryName}
                    </p>
                  </div>
                  <div className="row">
                    {item?.learningVideos.map((item) => (
                      <div
                        key={item.id}
                        className="col-md-6 pb-2 d-flex align-items-center"
                      >
                        <input
                          className="mr-10"
                          disabled={
                            radioButtonId.name ===
                            "Hand pick your content below. Must choose 24 video blocks. Feel free to mix and match!"
                              ? LearningvideosIds?.length > 23
                                ? LearningvideosIds?.indexOf(
                                    item?.userlearningContent[0]?.id
                                  ) !== -1
                                  ? false
                                  : true
                                : false
                              : true
                          }
                          checked={
                            LearningvideosIds?.indexOf(
                              item?.userlearningContent[0]?.id
                            ) !== -1
                              ? true
                              : false
                          }
                          type="checkbox"
                          id={item?.userlearningContent[0]?.id}
                          name={"birth12"}
                          onChange={onChangeChexkBox}
                          value={item.LearningVideoName}
                        />
                        <label className="font-size-16 color-Gray fw-500">
                          &nbsp;{capitalize(item.LearningVideoName)}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

              {/* this is extra bottom */}
              {/* <form> */}
              <div className="row">
                <div className="d-flex justify-content-end mt-4 mb-3">
                  <button
                    // hidden={props.isFirst()}
                    disabled={props.isFirst()}
                    onClick={props.prev}
                    className="backButton  mx-3"
                  >
                    Back
                  </button>
                  <button
                    // hidden={props.isLast()}
                    type={"submit"}
                    // disabled={props.isLast()}
                    className={payButton}
                  >
                    Save & Next
                  </button>
                </div>
              </div>
              {/* </form> */}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormStep3;
