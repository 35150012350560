export const FORM_DATA = "FORM_DATA";

export function formData(data) {
  return (dispatch) => {
    return dispatch({
      type: FORM_DATA,
      payload: data,
    });
  };
}
