import React from "react";
import Slider from "react-slick";
import simpleAndEasyOrdering from "Assets/images/landingpage/simpleAndEasyOrdering.png";
import personalization from "Assets/images/packagesAndDetail/packageImages/budlepersonalization.png";
import greetings from "Assets/images/packagesAndDetail/packageImages/bundlegreetings.png";
import chooseContent from "Assets/images/packagesAndDetail/packageImages/bundlechoosecontent.png";

//

const SimpleAndEasyOredering = () => {
  const SliderData = [
    {
      id: 1,
      img: greetings,
      heading: "Choose Your Video",
      heading2: "Personalization Package",
    },
    {
      id: 2,
      img: personalization,
      heading: "Enter Some Information",
      heading2: "& Upload Photos",
    },
    {
      id: 3,
      img: chooseContent,
      heading: `Same Day Download`,
      heading2: `From "U" Baby Portal`,
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  return (
    <div className="py-5 bg-lightGreen">
      <div className="container">
        <div className="text-center">
          {/* <PypalBtn /> */}
          <h1 className="font-size-38 color-darkPink fw-600">
            SIMPLE & EASY ONLINE ORDERING
          </h1>
          <p className="font-size-26 m-0 color-darkGreen fw-600">
            From Any Device
          </p>
        </div>
        <div className="d-flex my-4 justify-content-center">
          <img
            style={{
              width: "70%",
            }}
            src={simpleAndEasyOrdering}
          />
        </div>
        <div className="">
          <Slider className="" {...settings}>
            {SliderData?.map((item) => (
              <div
                key={item.id}
                className="d-flex flex-column align-items-center"
              >
                <div style={{ maxWidth: "370px" }} className="d-flex">
                  <div className="d-flex text-center">
                    <div>
                      <p className="color-darkPink font-size-26 fw-600">
                        {item?.id}.
                      </p>
                    </div>
                    <div>
                      <p className="color-darkPink font-size-28 fw-600">
                        &nbsp; {item?.heading} <br /> {item?.heading2}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SimpleAndEasyOredering;
