import React from "react";
import personalization from "Assets/images/packagesAndDetail/packageImages/budlepersonalization.png";
import greetings from "Assets/images/packagesAndDetail/packageImages/bundlegreetings.png";
import chooseContent from "Assets/images/packagesAndDetail/packageImages/bundlechoosecontent.png";
import Scroll from "react-scroll";

const VideoAndLearning = () => {
  const SliderData = [
    {
      id: 1,
      img: greetings,
      heading: "Personalized Intro & Choice of Greeting w/audio message",
    },
    {
      id: 2,
      img: personalization,
      heading: "Personalized Learning Content w/ your voice & family photos",
    },
    {
      id: 3,
      img: chooseContent,
      heading:
        "Videos Promote- Math & reading readiness, observational, creative-thinking, language skills, & interactive exercises",
    },
  ];

  return (
    <div className="my-5 container">
      <div className="text-center">
        {/* <PypalBtn /> */}
        <h1 className="font-size-38 color-darkPink fw-600">
          Video & Learning Features
        </h1>
        <p className="font-size-22 color-darkGreen fw-600">
          Colorful, Exciting, & Engaging- Animation, Music, & Rhymes!
        </p>
      </div>
      <div className="mt-5 d-block d-md-flex">
        {SliderData?.map((item) => (
          <div
            key={item.id}
            className="d-flex flex-column mb-5 align-items-center">
            <div className="text-center mb-4">
              <img width="100%" src={item?.img} />
            </div>
            <div style={{ maxWidth: "470px" }} className="text-center">
              <p className="color-darkPink font-size-22 fw-600">
                {item?.heading}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="d-flex d-md-none justify-content-center">
        <Scroll.Link
          activeClass="aboutActive"
          to="comparison-chart"
          spy={true}
          smooth={true}>
          <button className="color-DarkGray fw-600 font-size-16 m-4 px-3 br-10 bg-purple py-2">
            Compare Packages
          </button>
        </Scroll.Link>
      </div>
    </div>
  );
};

export default VideoAndLearning;
