import React from "react";
import bundleImg from "Assets/images/landingpage/bundleImg.png";
import pictureFrame from "Assets/images/landingpage/pictureFrame.png";

const StarringYourLittle = () => {
  return (
    <div className="d-none d-lg-block bg-lightGreen">
      <div className="py-5 container">
        <div>
          <div className="text-center mb-5">
            <p className="font-size-38 fw-700 color-darkPink">
              Learning Videos Starring Your Little & You
            </p>
            <span className="font-size-20-26 fw-700 color-purple">
              Videos personalized with baby’s name, birthday, photos, personal
              message from you, and more!
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <img style={{ width: "62%" }} src={pictureFrame} />
          </div>
          <div className="col-md-6 text-center">
            <div>
              <img style={{ width: "70%" }} className="" src={bundleImg} />
            </div>
            {/* <div>
              <span className="font-size-38 fw-700 color-darkPink">
                Starring Your Little & You
              </span>
            </div> */}
            <div>
              <span className="font-size-26 fw-700 color-darkPink">
                Videos Promote- Math & Reading Readiness, Observational,
                Creative-Thinking, Language Skills & More
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StarringYourLittle;
