import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import fileUpload from "Assets/images/forms/fileUpload.svg";
import caution from "Assets/images/forms/caution.png";
import cross from "Assets/images/landingpage/cross.svg";
import { uploadFile } from "components/uploadFile/uploadFile";
import ImageCropModal from "components/modal/imageCropModal";

const PhotoUploadModal = ({
  images,
  setImages,
  modal,
  toggle,
  photoForm,
  familyImages,
  id,
}) => {
  const [momPhoto, setMomPhoto] = useState([
    {
      id: 1,
      label: "Photo # 1",
      name: "photo1",
      loading: false,
    },
    {
      id: 2,
      label: "Photo # 2",
      name: "photo2",
      loading: false,
    },
  ]);
  const [dadPhoto, setDadPhoto] = useState([
    {
      id: 1,

      label: "Photo # 1",
      name: "photo1",
      loading: false,
    },
    {
      id: 2,

      label: "Photo # 2",
      name: "photo2",
      loading: false,
    },
  ]);
  const [bortherPhoto, setBortherPhoto] = useState([
    {
      id: 1,

      label: "Photo # 1",
      name: "photo1",
      loading: false,
    },
    {
      id: 2,

      label: "Photo # 2",
      name: "photo2",
      loading: false,
    },
    {
      id: 3,

      label: "Photo # 3",
      name: "photo3",
      loading: false,
    },
    {
      id: 4,

      label: "Photo # 4",
      name: "photo4",
      loading: false,
    },
  ]);
  const [sisterPhoto, setSisterPhoto] = useState([
    {
      id: 1,

      label: "Photo # 1",
      name: "photo1",
      loading: false,
    },
    {
      id: 2,

      label: "Photo # 2",
      name: "photo2",
      loading: false,
    },
    {
      id: 3,

      label: "Photo # 3",
      name: "photo3",
      loading: false,
    },
    {
      id: 4,

      label: "Photo # 4",
      name: "photo4",
      loading: false,
    },
  ]);
  const [familyPhoto, setFamilyPhoto] = useState([
    {
      id: 1,

      label: "Photo # 1",
      name: "photo1",
      loading: false,
    },
    {
      id: 2,

      label: "Photo # 2",
      name: "photo2",
      loading: false,
    },
    {
      id: 3,

      label: "Photo # 3",
      name: "photo3",
      loading: false,
    },
    {
      id: 4,

      label: "Photo # 4",
      name: "photo4",
      loading: false,
    },
  ]);
  const [grandParentsPhoto, setGrandParentsPhoto] = useState([
    {
      id: 1,

      label: "Photo # 1",
      name: "photo1",
      loading: false,
    },
    {
      id: 2,

      label: "Photo # 2",
      name: "photo2",
      loading: false,
    },
    {
      id: 3,

      label: "Photo # 3",
      name: "photo3",
      loading: false,
    },
    {
      id: 4,

      label: "Photo # 4",
      name: "photo4",
      loading: false,
    },
  ]);

  const [fileUploadDisable, setFileUploadDisable] = useState(false);
  const [modalCentered, setModalCentered] = useState({
    bool: false,
    file: null,
  });
  const imageEvent = useRef();

  const cancelFileupload = useRef();

  useEffect(() => {
    setImages({
      ...images,
      momPhoto,
      dadPhoto,
      bortherPhoto,
      sisterPhoto,
      familyPhoto,
      grandParentsPhoto,
    });
  }, [
    momPhoto,
    dadPhoto,
    bortherPhoto,
    sisterPhoto,
    familyPhoto,
    grandParentsPhoto,
  ]);

  // states
  const photoInput =
    photoForm === "momPhoto"
      ? momPhoto
      : photoForm === "dadPhoto"
      ? dadPhoto
      : photoForm === "brotherPhoto"
      ? bortherPhoto
      : photoForm === "sisterPhoto"
      ? sisterPhoto
      : photoForm === "familyPhoto"
      ? familyPhoto
      : photoForm === "grandParentsPhoto"
      ? grandParentsPhoto
      : grandParentsPhoto;

  const modalHeading =
    photoForm === "momPhoto"
      ? "Upload up to 2 photos of mommy"
      : photoForm === "dadPhoto"
      ? "Upload up to 2 photos of daddy"
      : photoForm === "brotherPhoto"
      ? "Upload up to 4 photos of brothers"
      : photoForm === "sisterPhoto"
      ? "Upload up to 4 photos of sisters"
      : photoForm === "familyPhoto"
      ? "Upload up to 4 photos of family"
      : photoForm === "grandParentsPhoto"
      ? "Upload up to 4 photos of grand parents"
      : "Mom";

  // setStates
  const setPhotoInput =
    photoForm === "momPhoto"
      ? setMomPhoto
      : photoForm === "dadPhoto"
      ? setDadPhoto
      : photoForm === "brotherPhoto"
      ? setBortherPhoto
      : photoForm === "sisterPhoto"
      ? setSisterPhoto
      : photoForm === "familyPhoto"
      ? setFamilyPhoto
      : photoForm === "grandParentsPhoto"
      ? setGrandParentsPhoto
      : setGrandParentsPhoto;

  // useEffect for set data from reducer photo
  useEffect(() => {
    // set data from reducer for momphoto
    if (familyImages?.childMom?.length) {
      for (let i = 0; i < familyImages?.childMom?.length; i++) {
        momPhoto[i].img = familyImages.childMom[i].PicLink;
        momPhoto[i].imgLocal = familyImages.childMom[i].MomLocalServerPicLink;
      }
    }
    // set data from reducer for dadPhoto
    if (familyImages?.childDad?.length) {
      for (let i = 0; i < familyImages?.childDad?.length; i++) {
        dadPhoto[i].img = familyImages.childDad[i].PicLink;
        dadPhoto[i].imgLocal =
          familyImages.childDad[i].ChildDadLoclaserverPicLink;
      }
    }

    // set data from reducer for bortherPhoto
    if (familyImages?.childBrother?.length) {
      for (let i = 0; i < familyImages?.childBrother?.length; i++) {
        bortherPhoto[i].img = familyImages.childBrother[i].PicLink;
        bortherPhoto[i].imgLocal =
          familyImages.childBrother[i].BrotherLocalServerPicLink;
      }
    }

    // set data from reducer for sisterPhoto
    if (familyImages?.childsister?.length) {
      for (let i = 0; i < familyImages?.childsister?.length; i++) {
        sisterPhoto[i].img = familyImages.childsister[i].PicLink;
        sisterPhoto[i].imgLocal =
          familyImages.childsister[i].ChildSisterLocalServerPicLink;
      }
    }

    // set data from reducer for familyPhoto
    if (familyImages?.childAdditionalFamilyPics?.length) {
      for (
        let i = 0;
        i < familyImages?.childAdditionalFamilyPics?.length;
        i++
      ) {
        familyPhoto[i].img = familyImages.childAdditionalFamilyPics[i].PicLink;
        familyPhoto[i].imgLocal =
          familyImages.childAdditionalFamilyPics[i].LocalServerFamilyPic;
      }
    }

    // set data from reducer for familyPhoto
    if (familyImages?.childGrandParents?.length) {
      for (let i = 0; i < familyImages?.childGrandParents?.length; i++) {
        grandParentsPhoto[i].img = familyImages.childGrandParents[i].PicLink;
        grandParentsPhoto[i].imgLocal =
          familyImages.childGrandParents[i].ChildGrandParentLocalServerPicLink;
      }
    }
  }, [familyImages]);

  // file upload Image....................
  async function handleChange(event) {
    console.log(event.target.files[0]);
    // loading and disable field states
    setModalCentered({ bool: true, file: event.target.files[0] });
    imageEvent.current = event;
  }

  async function uploadIamge(file) {
    let event = imageEvent.current;

    setFileUploadDisable(true);
    setModalCentered({ bool: false, file: null });

    setPhotoInput(
      photoInput.map((el) =>
        el.id == event.target.id ? { ...el, loading: true } : el
      )
    );

    uploadFile(event, id, cancelFileupload, file)
      .then((res) => {
        setFileUploadDisable(false);
        setPhotoInput(
          photoInput.map((el) =>
            el.id == event.target.id
              ? {
                  ...el,
                  img: res[1]?.data?.result?.Location,
                  imgLocal: res[0]?.data?.FileData?.FileUrl,
                  imgError: null,
                  loading: false,
                }
              : el
          )
        );
        setModalCentered({ bool: false, file: null });
      })
      .catch((error) => {
        setFileUploadDisable(false);
        setPhotoInput(
          photoInput.map((el) =>
            el.id == event.target.id
              ? {
                  ...el,
                  imgError: caution,
                  loading: false,
                }
              : el
          )
        );
        setModalCentered({ bool: false, file: null });
      });
  }

  // const handleOnChange = (e) => {
  //   // loading and disable field states
  //   setFileUploadDisable(true);
  //   setPhotoInput(
  //     photoInput.map((el) =>
  //       el.id == e.target.id ? { ...el, loading: true } : el
  //     )
  //   );

  //   // upload promise called
  //   uploadFile(e, id, cancelFileupload)
  //     .then((res) => {
  //       setFileUploadDisable(false);
  //       setPhotoInput(
  //         photoInput.map((el) =>
  //           el.id == e.target.id
  //             ? {
  //                 ...el,
  //                 img: res[1]?.data?.result?.Location,
  //                 imgLocal: res[0]?.data?.FileData?.FileUrl,
  //                 imgError: null,
  //                 loading: false,
  //               }
  //             : el
  //         )
  //       );
  //     })
  //     .catch((error) => {
  //       setFileUploadDisable(false);
  //       setPhotoInput(
  //         photoInput.map((el) =>
  //           el.id == e.target.id
  //             ? {
  //                 ...el,
  //                 imgError: caution,
  //                 loading: false,
  //               }
  //             : el
  //         )
  //       );
  //     });
  // };

  const onCancelPic = (id) => {
    // let state = { ...photoInput };
    let photoInputs = photoInput;
    const obj = photoInputs.find((x) => x.id === id);
    delete obj.img;
    delete obj.imgLocal;
    setPhotoInput(photoInputs.map((el) => el));
  };

  const cancelUpload = () => {
    if (cancelFileupload.current) {
      cancelFileupload.current("user cancel this req");
    }
  };

  return (
    <div>
      <Modal className="modalBg p-4" isOpen={modal} toggle={toggle}>
        <div
          onClick={toggle}
          className="d-flex justify-content-end cursor-pointer">
          <img width="15" src={cross} />
        </div>
        <ModalBody>
          <h4 className="text-center mb-4">{modalHeading}</h4>
          <div className="d-flex flex-wrap justify-content-center">
            {photoInput.map((item) => (
              <div key={item.id} className="d-flex align-items-center mb-4">
                <div
                  className="mx-3"
                  style={{
                    width: "112px",
                    height: "112px",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}>
                  <div
                    style={{ position: "relative" }}
                    className="imageUploadModal h-100 d-flex align-items-center justify-content-center">
                    <div
                      className={`${
                        item.img ? "d-block" : "d-none"
                      } cursor-pointer`}
                      onClick={() => onCancelPic(item?.id)}
                      style={{ position: "absolute", top: 5, right: 15 }}>
                      <img width="15" src={cross} />
                    </div>

                    {item.loading ? (
                      <div
                        className={` cursor-pointer`}
                        onClick={() => cancelUpload()}
                        style={{ position: "absolute", top: 5, right: 15 }}>
                        <img width="15" src={cross} />
                      </div>
                    ) : null}
                    <div className="">
                      {item.loading === false ? (
                        <label for={item.id}>
                          {item.imgError ? (
                            <img
                              className="cursor-pointer"
                              width="60"
                              src={item.imgError}
                            />
                          ) : (
                            <img
                              style={{
                                maxWidth: "112px",
                                maxHeight: "112px",
                              }}
                              className="cursor-pointer"
                              src={item.img ? item.img : fileUpload}
                            />
                          )}
                        </label>
                      ) : (
                        <div className="d-flex justify-content-center h-100 align-items-center">
                          <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                          </div>
                        </div>
                      )}
                    </div>

                    {fileUploadDisable ? null : (
                      <input
                        accept="image/x-png,image/jpeg"
                        id={item.id}
                        onChange={(e) => handleChange(e)}
                        type="file"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
        <div className="text-end">
          <button
            onClick={toggle}
            className="modaldoneButtonBlue px-4 py-2 text-white fw-600 font-size-16">
            Done
          </button>
        </div>
      </Modal>
      <ImageCropModal modalCentered={modalCentered} uploadIamge={uploadIamge} />
    </div>
  );
};

export default PhotoUploadModal;
