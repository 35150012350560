import React from "react";
import "./style.css";
import ReactPlayer from "react-player";

const BlogDetail3 = ({ blog }) => {
  const isImage = (url) => {
    return /^\[webp|jpeg|jpg|png|avif|gif|svg]\s?I{3}$/.test(url);
  };
  return (
    <div className="container videosMainDiv p-5 my-5">
      <div className="">
        <div className="d-flex justify-content-center">
          <div className="logDetailImgDiv">
            {isImage(blog?.BlogImage) ? (
              <img src={blog?.BlogImage} alt="" className="w-100" />
            ) : (
              // <video autoPlay className="w-100">
              //   <source src={blog?.BlogImage}></source>
              // </video>
              <video src={blog?.BlogImage} className="w-100" />
            )}
          </div>
        </div>
        <div className="mt-3">
          <h3 className="color-darkGreen font-size-24 fw-600 text-center">
            {blog?.BlogTitle}
          </h3>
          <div dangerouslySetInnerHTML={{ __html: blog?.BlogDescription }} />
        </div>

        <div className="row">
          <div
            style={{ gap: "40px" }}
            className="mt-5 d-flex flex-wrap col-md-12 row flex-md-nowrap"
          >
            <div
              style={{ maxWidth: "550px", minWidth: "200px" }}
              className="col-md-6"
            >
              <div dangerouslySetInnerHTML={{ __html: blog?.BlogBody }} />
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              {/* <img className="w-100" alt="" src={blog?.BlogVideo1} /> */}
              {isImage(blog?.BlogVideo1) ? (
                <img src={blog?.BlogVideo1} alt="" className="w-100" />
              ) : (
                // <video autoPlay className="w-100">
                //   <source src={blog?.BlogVideo1}></source>
                // </video>
                <video src={blog?.BlogVideo1} className="w-100" />
              )}
              {/* <ReactPlayer
                height="100%"
                width="100%"
                preload={true}
                url={blog?.BlogVideo1}
                controls={true}
              /> */}
            </div>
          </div>

          {/*  */}
          <div
            style={{ gap: "40px" }}
            className="mt-5 d-flex flex-wrap col-md-12 row flex-md-nowrap"
          >
            <div className="col-md-6 d-flex justify-content-center">
              {isImage(blog?.BlogVideo2) ? (
                <img src={blog?.BlogVideo2} alt="" className="w-100" />
              ) : (
                // <video autoPlay className="w-100">
                //   <source src={blog?.BlogVideo2}></source>
                // </video>
                <video src={blog?.BlogVideo2} className="w-100" />
              )}
              {/* <ReactPlayer
                height="100%"
                width="100%"
                preload={true}
                url={blog?.BlogVideo2}
                controls={true}
              /> */}
            </div>
            <div
              style={{ maxWidth: "550px", minWidth: "200px" }}
              className="col-md-6"
            >
              <div dangerouslySetInnerHTML={{ __html: blog?.BlogBody2 }} />
            </div>
          </div>
          {/*  */}
          <div
            style={{ gap: "40px" }}
            className="mt-5 d-flex flex-wrap col-md-12 row flex-md-nowrap"
          >
            <div
              style={{ maxWidth: "550px", minWidth: "200px" }}
              className="col-md-6"
            >
              <div dangerouslySetInnerHTML={{ __html: blog?.BlogBody3 }} />
            </div>
            <div className="col-md-6 d-flex justify-content-center">
              {isImage(blog?.BlogVideo3) ? (
                <img src={blog?.BlogVideo3} alt="" className="w-100" />
              ) : (
                // <video autoPlay className="w-100">
                //   <source src={blog?.BlogVideo3}></source>
                // </video>
                <video
                  src={blog?.BlogVideo3}
                  controls="controls"
                  autoplay="true"
                  className="w-100"
                />
              )}
              {/* <ReactPlayer
                height="100%"
                width="100%"
                preload={true}
                url={blog?.BlogVideo3}
                controls={true}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail3;
