import React, { useEffect, useState } from "react";
// littleknow it alla images
import little1 from "../../Assets/images/packagesAndDetail/packageImages/frontlittle.png";
import little2 from "../../Assets/images/packagesAndDetail/packageImages/backcover.png";
import little3 from "../../Assets/images/packagesAndDetail/packageImages/dvdRevised.png";
// varsity it alla images
import varsity1 from "../../Assets/images/packagesAndDetail/packageImages/frontvarsity.png";
import varsity2 from "../../Assets/images/packagesAndDetail/packageImages/backcover.png";
import varsity3 from "../../Assets/images/packagesAndDetail/packageImages/dvdRevised.png";
// upperclass it alla images
import upperclass1 from "../../Assets/images/packagesAndDetail/packageImages/frontupper.png";
import upperclass2 from "../../Assets/images/packagesAndDetail/packageImages/backcover.png";
import upperclass3 from "../../Assets/images/packagesAndDetail/packageImages/dvdRevised.png";
import Slider from "react-slick";
import "./style.css";
import { Link } from "react-router-dom";

const PackageDetailTop = ({ name, packageData }) => {
  const detailLittile = {
    headingAge:
      "Cute, sweet, & easy to create! Features 3 personalization elements!",
    para: "This option gets straight to the point and makes a great gift or keepsake!",
    headingUl: "Personalized with:",
    ulBullet: [
      {
        id: 1,
        name: "Personalized video intro w/ child's name & photo",
      },
      {
        id: 2,
        name: "Written greeting message w/ photo of you",
      },
      {
        id: 3,
        name: "Optional audio message from you",
      },
      {
        id: 4,
        name: "Select your learning content (optional)",
      },
    ],
  };

  const detailVarsity = {
    headingAge: "Handcrafted! Over 5 personalization Features",
    para: "This option allows you to create an ultra-unique learning experience personalized just for them!",
    headingUl: "Personalized with:",
    ulBullet: [
      {
        id: 5,
        name: "Personalized learning content",
      },
      {
        id: 1,
        name: "Personalized video intro w/ child's name & photo",
      },
      {
        id: 2,
        name: "Written greeting message w/ photo of you",
      },
      {
        id: 3,
        name: "Optional audio message from you",
      },
      {
        id: 4,
        name: "Select your learning content (optional)",
      },
    ],
  };

  const detailUpper = {
    headingAge: "Tailor Made! Over 7 personalization Features",
    para: "This option lets you create the ultimate personalized learning experience for your learning little, allowing them to learn to your voice!",
    headingUl: "Personalized with:",
    ulBullet: [
      {
        id: 5,
        name: "Personalized learning content",
      },
      {
        id: 1,
        name: "Personalized video intro w/ child's name & photo",
      },
      {
        id: 2,
        name: "Written greeting message w/ photo of you",
      },
      {
        id: 3,
        name: "Optional audio message from you",
      },
      {
        id: 4,
        name: "Select your learning content (optional)",
      },
      {
        id: 6,
        name: "Add your voice to learning content",
      },
    ],
  };

  const detailDesc =
    name === "littleknowitall"
      ? detailLittile
      : name === "varsitybaby"
      ? detailVarsity
      : name === "upperclassmen"
      ? detailUpper
      : null;

  const cardButton =
    name === "littleknowitall"
      ? "cardButtonPink"
      : name === "varsitybaby"
      ? "cardButtonBlue"
      : name === "upperclassmen"
      ? "cardButtonPurple"
      : "cardButtonPurple";

  const image1 =
    name === "littleknowitall"
      ? little1
      : name === "varsitybaby"
      ? varsity1
      : name === "upperclassmen"
      ? upperclass1
      : upperclass1;

  const image2 =
    name === "littleknowitall"
      ? little2
      : name === "varsitybaby"
      ? varsity2
      : name === "upperclassmen"
      ? upperclass2
      : upperclass2;

  const image3 =
    name === "littleknowitall"
      ? little3
      : name === "varsitybaby"
      ? varsity3
      : name === "upperclassmen"
      ? upperclass3
      : upperclass3;

  const DetailImg =
    name === "littleknowitall"
      ? little1
      : name === "varsitybaby"
      ? varsity1
      : name === "upperclassmen"
      ? upperclass1
      : upperclass1;

  const PackageName =
    name === "littleknowitall"
      ? "Little Know It All"
      : name === "varsitybaby"
      ? "Varsity Baby"
      : name === "upperclassmen"
      ? "Upperclassmen"
      : null;

  const PackageOption =
    name === "littleknowitall"
      ? "Option 1"
      : name === "varsitybaby"
      ? "Option 2"
      : name === "upperclassmen"
      ? "Option 3"
      : null;

  const [image, setImage] = useState(DetailImg);

  useEffect(() => {
    setImage(DetailImg);
  }, [name]);

  const priceColor =
    name === "littleknowitall"
      ? "color-lightPink"
      : name === "varsitybaby"
      ? "color-lightBlue"
      : name === "upperclassmen"
      ? "color-lightPurple"
      : "color-lightPurple";

  const packagePrice =
    name === "littleknowitall"
      ? packageData && packageData[0]?.PackagePrice
      : name === "varsitybaby"
      ? packageData && packageData[1]?.PackagePrice
      : name === "upperclassmen"
      ? packageData && packageData[2]?.PackagePrice
      : null;

  const imgBg =
    name === "littleknowitall"
      ? "imgBgLittile"
      : name === "varsitybaby"
      ? "imgBgVarsity"
      : name === "upperclassmen"
      ? "imgBgUpper"
      : null;

  const onCLickImg = (img) => {
    setImage(img);
  };

  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  return (
    <div className="container">
      <div className="row my-5 d-flex justify-content-center">
        <div
          style={{ gap: "30px" }}
          className="d-sm-flex col-md-6 col-sm-4 d-none"
        >
          <div style={{ gap: "30px" }} className="d-flex flex-column">
            <div
              onClick={() => onCLickImg(image1)}
              className={`cursor-pointer p-4 d-flex align-items-center sidePic justify-content-center ${imgBg}`}
            >
              <div className="text-center">
                <img className="w-100 h-100" src={image1} />
              </div>
            </div>

            <div
              onClick={() => onCLickImg(image2)}
              className={`cursor-pointer p-3 d-flex align-items-center sidePic justify-content-center ${imgBg}`}
            >
              <div className="text-center">
                <img className="w-50 h-100 mb-2" src={image2} />
              </div>
            </div>
            <div
              onClick={() => onCLickImg(image3)}
              className={`cursor-pointer p-4 d-flex align-items-center sidePic justify-content-center ${imgBg}`}
            >
              <div className="text-center">
                <img className="w-100 h-100 mb-2" src={image3} />
              </div>
            </div>
          </div>

          <div
            style={{ maxHeight: "447px", maxWidth: "400px" }}
            className={`${imgBg} d-lg-flex d-none p-5 justify-content-center sidePic align-items-center`}
          >
            <div className="text-center">
              <h2 className={`m-0 my-3 ${priceColor}`}>{PackageOption}</h2>
              <img width="250" className="" src={image} />
              <h2 className={`m-0 my-3 ${priceColor}`}>{PackageName}</h2>
            </div>
          </div>
        </div>

        {/* slider for mobile */}
        <div className="d-block d-sm-none">
          <Slider className="" {...settings}>
            <div className="px-4">
              <div
                onClick={() => onCLickImg(image1)}
                className={`cursor-pointer d-flex p-4 align-items-center sidePic justify-content-center ${imgBg}`}
              >
                <div className="text-center">
                  <img className="w-100 h-100" src={image1} />
                </div>
              </div>
            </div>
            <div className="px-4">
              <div
                onClick={() => onCLickImg(image2)}
                className={`cursor-pointer p-3 d-flex align-items-center sidePic justify-content-center ${imgBg}`}
              >
                <div className="text-center">
                  <img className="w-50 h-100 mb-2" src={image2} />
                </div>
              </div>
            </div>

            <div className="px-4">
              <div
                onClick={() => onCLickImg(image3)}
                className={`cursor-pointer p-4 d-flex align-items-center sidePic justify-content-center ${imgBg}`}
              >
                <div className="d-flex justify-content-center">
                  <img className="w-100 h-100 mb-2" src={image3} />
                </div>
              </div>
            </div>
          </Slider>
        </div>

        <div className="col-md-6 col-sm-8 px-5 mt-5 mt-sm-0 d-flex justify-content-center">
          <div>
            <h4 className="font-size-22 fw-600 color-black">
              {detailDesc?.headingAge}
            </h4>
            <p className="color-Gray font-size-16 m-0 fw-600">
              {detailDesc?.para}
            </p>
            <div>
              <h4 className="font-size-22 my-2 fw-600 color-black">
                {detailLittile?.headingUl}
              </h4>
              <ul className="color-Gray px-3 fw-600 font-size-20">
                {detailDesc?.ulBullet?.map((item) => (
                  <li key={item.id} className="">
                    {item.name}
                  </li>
                ))}
              </ul>
              <h4 className={`font-size-38 fw-600 ${priceColor}`}>
                $ {packagePrice}
              </h4>
              <div>
                <Link to={`/payment/${name}`}>
                  <button
                    className={`p-3 w-50 br-10 mt-3 text-nowrap fw-600 ${cardButton} `}
                  >
                    Create Video
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDetailTop;
