import React, { useState, useEffect } from "react";
import pinkHr from "Assets/images/packagesAndDetail/descriptionPinkHr.svg";
import blueHr from "Assets/images/landingpage/educationalHr.svg";
import purpleHr from "Assets/images/packagesAndDetail/purpleHr.svg";
import reviewHr from "Assets/images/forms/reviewHr.svg";
import reviewImg from "Assets/images/forms/reviewImg.svg";
import VarsityRemainigPreview from "./varsityBabyRemainingPreview";
import UpperClassmenPreview from "./upperClassmenRemainigpreview";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions";
import history from "@history";
import FinalSubmitModal from "components/modal/finalsubmitmodal";
import PackageSubmittedModal from "components/modal/packageSubmittedModal";
import LoadingOverlay from "react-loading-overlay";

const Preview = () => {
  const dispatch = useDispatch();
  const [modalCentered, setModalCentered] = useState(false);
  const [submittedModal, setSubmittedModal] = useState(false);
  const [successSubmittedModal, setSuccessSubmittedModal] = useState(false);

  const PreviewData = useSelector(
    ({ PackageReducer }) => PackageReducer?.userPreviewData
  );

  const loading = useSelector(
    (PackageReducer) => PackageReducer?.PackageReducer?.loading
  );

  const recipientsData = PreviewData?.data?.package1recipients[0];
  const ChildPicData =
    PreviewData?.data?.package1recipients[0]?.Package1RecipientsChildpics[0];
  const RecipientsPhoto =
    PreviewData?.data?.package1recipients[0]?.package1Recipientsphotos[0];

  const { name } = useParams();
  const { id } = useParams();
  const names = name;
  const HeadingHr =
    names === "littleknowitall"
      ? pinkHr
      : names === "varsitybaby"
      ? blueHr
      : names === "upperclassmen"
      ? purpleHr
      : purpleHr;

  const payButton =
    names === "littleknowitall"
      ? "payButtonPink"
      : names === "varsitybaby"
      ? "payButtonBlue"
      : names === "upperclassmen"
      ? "payButtonPurple"
      : "payButtonPurple";

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    let data = {
      userVideoId: id,
    };
    dispatch(actions.packageLoading(true));
    dispatch(actions.getPreviewDataOfVideo(data));
  }, []);

  const submittedModalFun = (toggle, data) => {
    setSubmittedModal(toggle);
    setSuccessSubmittedModal(data);
  };

  const onSubmitPackage = () => {
    let data = {
      VideoId: id,
    };
    dispatch(actions.finalSubmitModalLoading(true));
    dispatch(
      actions.submitFinalVideo(data, submittedModalFun, setModalCentered)
    );
  };

  let mySentence = "hey have many apples";
  let ans = mySentence.split(" ");
  let length = ans?.length;
  const stifngi = async (ans) => {
    await console.log(ans?.[length - 1]);
  };

  return (
    <LoadingOverlay active={loading} spinner text="Loading your content...">
      <div className="p-3 container">
        <div className="my-4">
          <div className="text-center">
            <h1 className="font-size-22 m-0 color-DarkGray fw-600 ">Review</h1>
            <div className="">
              <p className="font-size-16 my-2  color-Gray fw-600">
                Order Summary
              </p>
              <img className="" src={HeadingHr} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">Name</h1>
              <p className="font-size-16 color-Gray fw-600">
                {PreviewData?.data?.customer?.username}
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">Email</h1>
              <p className="font-size-16 color-Gray fw-600">
                {PreviewData?.data?.customer?.email}
              </p>
            </div>
          </div>

          <div className="col-md-6">
            <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">
              Video Selection
            </h1>
            <p className="font-size-16 m-0 color-Gray fw-600">
              {PreviewData?.data?.packages?.PackageName}
            </p>
            {PreviewData?.data?.IsDVD ? (
              <p className="font-size-16 m-0 color-Gray fw-600">
                DVD Request - S&H
              </p>
            ) : null}
            <p className="font-size-16 m-0 color-Gray fw-600">TOTAL</p>
          </div>
          <div className="col-md-6">
            <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">Price</h1>
            <p className="font-size-16 m-0 color-Gray fw-600">
              $ {PreviewData?.data?.PakagePrice}
            </p>
            {PreviewData?.data?.IsDVD ? (
              <p className="font-size-16 m-0 color-Gray fw-600">
                $ {PreviewData?.data?.DVDPrice}
              </p>
            ) : null}
            <p className="font-size-16 m-0 color-Gray fw-600">
              $ {PreviewData?.data?.PaymentPaid}
            </p>
          </div>
        </div>
        {/* Hr.......... */}
        <div className="text-center my-5">
          <img src={reviewHr} />
        </div>
        <div>
          {/* Receipents Name........ */}
          <div className="row">
            <div className="col-md-6">
              <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">
                Recipient's Name
              </h1>
              <p className="font-size-16 color-Gray fw-600">
                {recipientsData?.RecipientsName}
              </p>
            </div>
            <div className="col-md-6">
              <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">
                Recipient's Age
              </h1>
              <p className="font-size-16 color-Gray fw-600">
                {recipientsData?.RecipientAge}
              </p>
            </div>
          </div>
          {/* upload Photo */}
          <div>
            <div className="mb-4">
              <h1 className="font-size-16 color-DarkGray fw-600 ">
                Upload a photo of child (to be used in video intro)
              </h1>
              <div className="m-2 previePageImage d-flex justify-content-center align-items-center">
                <img
                  style={{ maxWidth: "77px", maxHeight: "77px" }}
                  src={ChildPicData?.Link ? ChildPicData?.Link : reviewImg}
                />
              </div>
            </div>
            <div className="mb-4">
              <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">
                Choose personal greeting and theme
              </h1>
              <p className="font-size-16 color-Gray fw-600">
                {recipientsData?.greetingThemes?.GreetingThemeName}
              </p>
            </div>
            {recipientsData?.greetingThemes?.GreetingThemeName ===
              "Happy Birthday" && (
              <div className="mb-4">
                <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">
                  If this is for a birthday, please indicate year
                </h1>
                <p className="font-size-16 color-Gray fw-600">
                  {recipientsData?.BirthdayYear}
                </p>
              </div>
            )}
            <div className="mb-4">
              <h1 className="font-size-16 color-DarkGray fw-600 ">
                Upload a photo of yourself (giver) or family photo (giver) for
                personal greeting
              </h1>
              <div className="m-2 previePageImage d-flex justify-content-center align-items-center">
                <img
                  style={{ maxWidth: "77px", maxHeight: "77px" }}
                  src={
                    RecipientsPhoto?.FamilyPhotoLink
                      ? RecipientsPhoto?.FamilyPhotoLink
                      : reviewImg
                  }
                />
              </div>
            </div>
            <div className="mb-4">
              <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">
                Who is in the photo? (i.e. Mommy & Daddy, Auntie Carol, Dyson
                Family, etc.)
              </h1>
              <p className="font-size-16 color-Gray fw-600">
                {RecipientsPhoto?.RelationWithChild}
              </p>
            </div>
            {PreviewData?.audios?.whoIsInThePhotoAudio ? (
              <div className="mb-4">
                <h1 className="font-size-16 color-DarkGray fw-600 ">
                  Record a brief audio message (optional)
                </h1>
                <div className="d-flex">
                  <audio
                    className=""
                    src={PreviewData?.audios?.whoIsInThePhotoAudio}
                    controls
                  />
                </div>
              </div>
            ) : null}
          </div>
          {/* extra previe */}
          {name === "varsitybaby" || name === "upperclassmen" ? (
            <>
              <div className="text-center my-5">
                <img src={reviewHr} />
              </div>

              <VarsityRemainigPreview
                momPhoto={PreviewData?.UserFamilyPics[0]?.childMom}
                dadPhoto={PreviewData?.UserFamilyPics[0]?.childDad}
                brotherPhoto={PreviewData?.UserFamilyPics[0]?.childBrother}
                sisterPhoto={PreviewData?.UserFamilyPics[0]?.childsister}
                familyPhoto={
                  PreviewData?.UserFamilyPics[0]?.childAdditionalFamilyPics
                }
                grandParentsPhoto={
                  PreviewData?.UserFamilyPics[0]?.childGrandParents
                }
              />
            </>
          ) : null}

          {name === "upperclassmen" ? (
            <>
              <div className="text-center mb-5 mt-3">
                <img src={reviewHr} />
              </div>
              <UpperClassmenPreview
                videoAudio={PreviewData?.AudioOverVideoInformation}
              />
            </>
          ) : null}
          <div className="text-center mb-5 mt-3">
            <img src={reviewHr} />
          </div>
          {/* Not Sure...... */}

          <div>
            <h1 className="font-size-16 m-0 color-DarkGray fw-600 ">
              Not sure what content to choose? No worries let us help!
            </h1>

            <p className="font-size-16 color-Gray fw-600">
              {PreviewData?.ContentInfo?.FullLearningContents?.Name}
            </p>
          </div>

          {PreviewData?.ContentInfo?.FullLearningContentVideoId === 3 && (
            <div className="row mb-4">
              {PreviewData?.LearningData?.map((item) => (
                <div className="col-md-6 mb-4">
                  <h1 className="font-size-16 m-0 color-DarkGray fw-600">
                    {item?.LearningVideoCategoryName}
                  </h1>
                  {item?.learningVideos?.map((item) => (
                    <p className="font-size-16 my-1 color-Gray fw-600">
                      {item?.LearningVideoName}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          )}

          <div className="row">
            <div className="d-flex justify-content-end mt-4 mb-3">
              <button
                onClick={() => history.goBack()}
                // hidden={props.isFirst()}
                // disabled={props.isFirst()}
                // onClick={props.prev}
                className="backButton"
              >
                Back
              </button>
              <button
                onClick={() => setModalCentered(!modalCentered)}
                // hidden={props.isLast()}
                // disabled={props.isLast()}
                // onClick={props.next}
                className={payButton}
              >
                Submit & Create
              </button>
            </div>
          </div>
        </div>
        <FinalSubmitModal
          setModalCentered={setModalCentered}
          modalCentered={modalCentered}
          onSubmitPackage={onSubmitPackage}
        />
        <PackageSubmittedModal
          setModalCentered={setSubmittedModal}
          modalCentered={submittedModal}
          successSubmittedModal={successSubmittedModal}
        />
      </div>
    </LoadingOverlay>
  );
};

export default Preview;
