import React, { useState, useEffect } from "react";
import pinkHr from "Assets/images/packagesAndDetail/descriptionPinkHr.svg";
import blueHr from "Assets/images/landingpage/educationalHr.svg";
import purpleHr from "Assets/images/packagesAndDetail/purpleHr.svg";
import history from "@history";
import PypalBtn from "components/paypalButton";
import * as actions from "store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import PurchasedModal from "components/modal/purchasedSuccessModal";
import LoadingOverlay from "react-loading-overlay";

const FormStep1 = (props) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const [pyplBtnLding, setPyplBtnLding] = useState(true);
  const [isDvd, setIsDvd] = useState(false);
  const [getPayPalInfo, setPayPalInfo] = useState();
  const [modalCentered, setModalCentered] = useState(false);

  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    localStorage.removeItem("url");
  }, []);
  
  useEffect(() => {
    console.log("isDVD:", isDvd);
    let data = {
      PackageId: packageId,
      TotalPayment: getPayPalInfo?.purchase_units[0]?.amount?.value,
      PaymentPaid: getPayPalInfo?.purchase_units[0]?.amount?.value,
      IsDVD: isDvd,
      IsPaymentClear: true,
      PaymentMethds: "http google",
      paymentToken: "abc 29 d",
      IsVideoCompleted: false,
    };

    console.log("packageId:", packageId);
    console.log("Data:", data);

    if (paymentSuccess) {
      dispatch(actions.createVideo(data));
    }
  }, [paymentSuccess]);

  const { name } = useParams();

  console.log("isDVD:", isDvd);
  const authCheck = useSelector(({ auth }) => auth.login.success);
  const userVideo = useSelector(
    ({ PackageReducer }) => PackageReducer?.userVideoId
  );

  // console.log(userVideo, "userVideo");
  const packageById = useSelector(
    ({ PackageReducer }) => PackageReducer?.packagesDetailByID?.package
  );
  const loading = useSelector(
    (PackageReducer) => PackageReducer?.PackageReducer?.loading
  );

  const packageId =
    name === "littleknowitall"
      ? 1
      : name === "varsitybaby"
      ? 2
      : name === "upperclassmen"
      ? 3
      : 3;

  useEffect(() => {
    let data = {
      PackageNumber: packageId,
    };

    dispatch(actions.packageLoading(data));
    dispatch(actions.getPackageById(data));
  }, [packageId]);

  const videoCreate = () => {};

  const packageHeading =
    name === "littleknowitall"
      ? "Little Know It All - Option 1"
      : name === "varsitybaby"
      ? "Varsity Baby - Option 2"
      : name === "upperclassmen"
      ? "Upperclassmen - Option 3"
      : "Upperclassmen - Option 3";

  let price = {
    // packageId: 1,
    normalPkg: packageById?.PackagePrice,
    dvd: packageById?.DVDPrice,
    packageName: packageHeading,
  };

  const total =
    selected === true ? price.normalPkg + price.dvd : price.normalPkg;

  const roundTotal = Math.round((total + Number.EPSILON) * 100) / 100;
  console.log(roundTotal, "------");
  const onSelect = () => {
    setSelected(!selected);
    if (selected === false) {
      price.dvd = packageById?.DVDPrice;
      price.total = total;
      setIsDvd(true);

      setPyplBtnLding(true);
      // setTimeout(()=>{
      //   setPyplBtnLding(false)
      // }, 1000)
    } else {
      delete price.dvd;
      setIsDvd(false);

      setPyplBtnLding(true);
      // setTimeout(()=>{
      //   setPyplBtnLding(false)
      // }, 1000)
    }
  };

  // useEffect(()=>{
  //   console.log("idDvd:", isDvd)
  // }, [isDvd])

  // let data = {
  //   PackageId: packageId,
  //   TotalPayment: getPayPalInfo?.purchase_units[0]?.amount?.value,
  //   PaymentPaid: getPayPalInfo?.purchase_units[0]?.amount?.value,
  //   IsDVD: isDvd,
  //   IsPaymentClear: true,
  //   PaymentMethds: "http google",
  //   paymentToken: "abc 29 d",
  //   IsVideoCompleted: false,
  // };
  // console.log("data:", data)

  const HeadingHr =
    name === "littleknowitall"
      ? pinkHr
      : name === "varsitybaby"
      ? blueHr
      : name === "upperclassmen"
      ? purpleHr
      : purpleHr;

  const videoPackageDiv =
    name === "littleknowitall"
      ? "videoPackageDivPink"
      : name === "varsitybaby"
      ? "videoPackageDivBlue"
      : name === "upperclassmen"
      ? "videoPackageDivPurple"
      : "videoPackageDivPurple";

  const payButton =
    name === "littleknowitall"
      ? "payButtonPink"
      : name === "varsitybaby"
      ? "payButtonBlue"
      : name === "upperclassmen"
      ? "payButtonPurple"
      : "payButtonPurple";

  setTimeout(function () {
    setPyplBtnLding(false);
  }, 1000);

  useEffect(() => {
    if (getPayPalInfo) {
      setModalCentered(true);
    }
  }, [getPayPalInfo]);

  const onNext = () => {
    console.log(userVideo, "onNext");
    history.push(`/create/${name}/${userVideo}`);
  };

  const handleRedirect = () => {
    var path = window.location.pathname;
    localStorage.setItem("url", JSON.stringify(path));
    history.push("/login");
  };

  return (
    <LoadingOverlay active={loading} spinner text="Loading your content...">
      {loading ? (
        <div style={{ height: "65vh" }}></div>
      ) : (
        <div className="p-3">
          <div className="my-4">
            <div className="text-center">
              <h1 className="font-size-22 m-0 color-DarkGray fw-600 ">
                We're excited to get started on your video...
              </h1>
              <div className="">
                <p className="font-size-16 my-2  color-Gray fw-600">
                  but first we need a few pieces of information from you
                </p>
                <img className="" src={HeadingHr} />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4 className="fw-600 color-Gray font-size-16">
                  Select Your Video
                </h4>
                <div className="">
                  <div
                    className={`${videoPackageDiv} px-2 py-3 mb-3 cursor-pointer`}
                  >
                    <h5 className="font-size-14 fw-600 color-DarkGray">
                      {packageHeading}
                    </h5>
                    <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                      <p className="font-size-13 m-0 color-Gray">
                        Great for babies and early learners of any age! You can
                        choose your content or select based on age range during
                        set-up. Includes personalized intro with animation of
                        child's name and photo, along with personalized greeting
                        from giver with written and/or recorded audio message
                        and photo. Greeting option themes include; Happy
                        Birthday (year included i.e 1st, 2nd, ect.), With Love,
                        Happy Holidays, or Merry Christmas- each greeting signed
                        with either family titles (Mommy & Daddy), names (John &
                        Tiffany), or family last name (Dyson Family)
                      </p>
                      <p className="fw-600 color-darkGray font-size-16 text-nowrap mx-3 my-0 mt-4 mt-md-0">
                        $ {price.normalPkg}
                      </p>
                    </div>
                  </div>
                  {/*  */}
                  <div
                    onClick={() => onSelect()}
                    className={`${
                      selected === true
                        ? videoPackageDiv
                        : "videoPackageDivWhite"
                    }   px-2 py-3 mb-3 cursor-pointer`}
                  >
                    <h5 className="font-size-14 fw-600 color-DarkGray">
                      DVD Request - S&H
                    </h5>
                    <div className="d-flex align-items-center flex-wrap flex-md-nowrap">
                      <p className="font-size-13 m-0 color-Gray">
                        Select this option to have a physical DVD shipped to
                        you. Orders are processed within 3-5 business days from
                        order completion. In the meantime, you will be able to
                        enjoy your video via electronic link shortly after you
                        complete. (average link processing/accessibility time 60
                        minutes)
                      </p>
                      <p className="fw-600 color-darkGray font-size-16 text-nowrap mx-3 my-0 mt-4 mt-md-0 ">
                        $ {price?.dvd}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <div style={{ width: "350px" }}>
                      <div className="d-flex justify-content-between w-100">
                        <p className="m-0 font-size-16 color-Gray fw-600">
                          Subtotal
                        </p>
                        <p className="m-0 font-size-16 color-Gray fw-600">
                          $ {price.normalPkg}
                        </p>
                      </div>
                      {selected === true ? (
                        <div className="d-flex justify-content-between w-100">
                          <p className="m-0 font-size-16 color-Gray fw-600"></p>
                          <p className="m-0 font-size-16 color-Gray fw-600">
                            $ {price.dvd}
                          </p>
                        </div>
                      ) : null}
                      <div className="d-flex justify-content-between w-100">
                        <p className="m-0 font-size-16 fw-600 color-darkGray">
                          Total
                        </p>
                        <p className="m-0 font-size-16 fw-600 color-darkGray">
                          $ {roundTotal}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-4 mb-3">
                    <>
                      <div className="position-R">
                        {authCheck && (
                          <div
                            style={{
                              zIndex: 1000,
                            }}
                            className={`${
                              pyplBtnLding
                                ? "d-flex justify-content-center w-100 h-100  align-items-center position-A"
                                : "d-none"
                            } `}
                          >
                            <div
                              style={{
                                height: "23px",
                                width: "23px",
                              }}
                              className="spinner-border mb-1"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                          </div>
                        )}
                        {authCheck ? (
                          <PypalBtn
                            isDvd={isDvd}
                            amount={roundTotal}
                            // videoCreate={videoCreate}
                            videoCreate={setPaymentSuccess}
                            setPayPalInfo={setPayPalInfo}
                          />
                        ) : (
                          <button
                            onClick={() => handleRedirect()}
                            className={payButton}
                          >
                            Login To Continue
                          </button>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PurchasedModal
            modalCentered={modalCentered}
            setModalCentered={setModalCentered}
            onNext={onNext}
          />
        </div>
      )}
    </LoadingOverlay>
  );
};

export default FormStep1;
