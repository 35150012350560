import React from "react";
import "./style.css";
import ReactPlayer from "react-player";

const BlogDetail1 = ({ blog }) => {
  const isImage = (url) => {
    return /^\[webp|jpeg|jpg|png|avif|gif|svg]\s?I{3}$/.test(url);
  };
  return (
    <div className="container videosMainDiv p-5 my-5">
      <div className="">
        <div className="d-flex justify-content-center">
          <div className="logDetailImgDiv">
            {/* <img className="w-100" alt="" src={blog?.BlogImage} /> */}
            {isImage(blog?.BlogImage) ? (
              <img src={blog?.BlogImage} alt="" className="w-100" />
            ) : (
              // <video controls className="w-100">
              //   <source src={blog?.BlogImage}></source>
              // </video>
              <video
                className="w-100"
                controls="controls"
                autoplay="true"
                src={blog?.BlogImage}
              />
            )}
          </div>
        </div>
        <div className="mt-3 px-1">
          <h3 className="color-darkGreen font-size-24 fw-600 text-center">
            {blog?.BlogTitle}
          </h3>
          <div dangerouslySetInnerHTML={{ __html: blog?.BlogDescription }} />
        </div>
        <div className="row px-5 mt-5">
          <div className="col-md-6">
            <div dangerouslySetInnerHTML={{ __html: blog?.BlogBody }} />
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            {isImage(blog?.BlogVideo1) ? (
              <img src={blog?.BlogVideo1} alt="" className="w-100" />
            ) : (
              // <video autoPlay className="w-100">
              //   <source src={blog?.BlogVideo1}></source>
              // </video>
              <video
                src={blog?.BlogVideo1}
                controls="controls"
                autoplay="true"
                className="w-100"
              />
            )}

            {/* <ReactPlayer
              height="100%"
              width="100%"
              preload={true}
              url={blog?.BlogVideo1}
              controls={true}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail1;
