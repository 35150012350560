import React, { useEffect } from "react";
import PackageNav from "../package/packageNav";
import DescriptionAndDetail from "./descriptionAndDetail";
import PackageDetailTop from "./packageDetailTop";
import "./style.css";
import { useParams } from "react-router-dom";
import * as actions from "store/actions/index";
import { useDispatch, useSelector } from "react-redux";

const PackageDetail = () => {
  const dispatch = useDispatch();

  const data = useSelector(({ PackageReducer }) => PackageReducer);
  const packageData = data?.packagesDetail?.package;

  const { id } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
  });

  useEffect(() => {
    dispatch(actions.getPackages());
  }, []);

  const NavText =
    id === "littleknowitall"
      ? "HOME / PRODUCTS / Little Know It All - Option 1"
      : id === "varsitybaby"
      ? "HOME / PRODUCTS / Varsity Baby - Option 2"
      : id === "upperclassmen"
      ? "HOME / PRODUCTS / Upperclassmen - Option 3"
      : "HOME / PRODUCTS / Upperclassmen - Option 3";

  return (
    <div>
      <div className="">
        <PackageNav NavText={NavText} name={id} />
        <PackageDetailTop packageData={packageData} name={id} />
        <DescriptionAndDetail name={id} />
      </div>
    </div>
  );
};

export default PackageDetail;
