import axios from "axios";
import Domain from "lib/Config";
import history from "@history";
import { NotificationManager } from "react-notifications";

export const USER_VIDEO_ID = "USER_VIDEO_ID";
export const USER_LEARNING_DATA = "USER_LEARNING_DATA";
export const USER_PREVIEW_DATA = "USER_PREVIEW_DATA";
export const GET_FAMILY_PICS_DATA = "GET_FAMILY_PICS_DATA";
export const GET_VIDEOS_DATA = "GET_VIDEOS_DATA";
export const GET_PACKAGES_DETAIL = "GET_PACKAGES_DETAIL";
export const GET_PACKAGES_DETAIL_BY_ID = "GET_PACKAGES_DETAIL_BY_ID";
export const PACKAGE_LOADING = "PACKAGE_LOADING";
export const USER_PREVIEW_DATA_ERROR = "USER_PREVIEW_DATA_ERROR";
export const FINAL_SUBMIT_LOADING = "FINAL_SUBMIT_LOADING";
export const MEDIA_PERMISSION = "MEDIA_PERMISSION";
export const GET_BIRTHDAY_AUDIO = "GET_BIRTHDAY_AUDIO";
export const GET_GREETING_AUDIO = "GET_GREETING_AUDIO";

export function createVideo(data) {
  const request = axios.post(`${Domain}/package1/createVideo`, data);
console.log("data--action", data)
debugger
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response?.data?.userVideoId, "userVideoId");
        return dispatch({
          type: USER_VIDEO_ID,
          payload: response?.data?.userVideoId,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function uploadrecipientDataLittle(data, greetingData, next) {
  const upLoadRecipient = axios.post(
    `${Domain}/package1/UploadRecipientAndUserData`,
    data
  );
  const addGreeting = axios.post(
    `${Domain}/userAddAudioForVideo/addGreetingTheme`,
    greetingData
  );
  const promise = Promise.all([upLoadRecipient, addGreeting]);
  return (dispatch) =>
    promise
      .then((response) => {
        dispatch(packageLoading(false));
        console.log(response);
        next();
      })
      .catch((error) => {
        dispatch(packageLoading(false));
        NotificationManager.error("problem in submit");
        console.log(error);
      });
}

export function uploadrecipientData(data, birthdAyData, greetingData, next) {
  const upLoadRecipient = axios.post(
    `${Domain}/package1/UploadRecipientAndUserData`,
    data
  );
  const addBirthday = axios.post(
    `${Domain}/userAddAudioForVideo/addBirthdayAudio`,
    birthdAyData
  );
  const addGreeting = axios.post(
    `${Domain}/userAddAudioForVideo/addGreetingTheme`,
    greetingData
  );
  const promise = Promise.all([upLoadRecipient, addGreeting, addBirthday]);
  return (dispatch) =>
    promise
      .then((response) => {
        console.log(response);
        dispatch(packageLoading(false));
        next();
      })
      .catch((error) => {
        dispatch(packageLoading(false));
        NotificationManager.error("problem in submit");
        console.log(error);
      });
}

export function getBirthdayAudio(data) {
  const request = axios.post(
    `${Domain}/userAddAudioForVideo/GetBirthdayAudio`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response?.data);
        return dispatch({
          type: GET_BIRTHDAY_AUDIO,
          payload: response?.data?.BirthdayAudio,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function getGreetingAudio(data) {
  const request = axios.post(
    `${Domain}/userAddAudioForVideo/getGreetingAudio`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response?.data?.GreetingAudio);
        return dispatch({
          type: GET_GREETING_AUDIO,
          payload: response?.data?.GreetingAudio,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function getUserLearningData(data) {
  const request = axios.post(`${Domain}/userVideo/GetUserLearningData`, data);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: USER_LEARNING_DATA,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function upDateUserLearningVideo(data, id, name) {
  const request = axios.put(
    `${Domain}/userVideo/UpdateUserLearningVideo`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        dispatch(packageLoading(false));
        history.push(`/preview/${name}/${id}`);
      })
      .catch((error) => {
        dispatch(packageLoading(false));
        NotificationManager.error("problem in submit");
        console.log(error);
      });
}

export function getPreviewDataOfVideo(data) {
  const request = axios.post(`${Domain}/uservideo/GetpreviewDataOfVideo`, data);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: USER_PREVIEW_DATA,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error.response);
        return dispatch({
          type: USER_PREVIEW_DATA_ERROR,
          payload: error,
        });
      });
}

export function AddFamilyPics(data, next) {
  const request = axios.post(
    `${Domain}/familypics/AddOrUpdateFamilyPicsMentionedInaVideo`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        dispatch(packageLoading(false));
        next();
      })
      .catch((error) => {
        dispatch(packageLoading(false));
        NotificationManager.error("problem in submit");
        console.log(error);
      });
}

export function getFamilyPics(data) {
  const request = axios.post(
    `${Domain}/familypics/GetAllFamilyPicsMentionedInAVideo`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_FAMILY_PICS_DATA,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function getVideosDataForAudio(data) {
  const request = axios.post(
    `${Domain}/userAddAudioForVideo/GetUserAudioAgainstVideo`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_VIDEOS_DATA,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function addVideosDataForAudio(data, next) {
  const request = axios.post(
    `${Domain}/userAddAudioForVideo/UserAddAudioAgainstAVideo`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        dispatch(packageLoading(false));
        next();
      })
      .catch((error) => {
        dispatch(packageLoading(false));
        NotificationManager.error("problem in submit");
        console.log(error);
      });
}

export function getPackages(data) {
  const request = axios.get(`${Domain}/packages/getAllPackages`, data);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_PACKAGES_DETAIL,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function getPackageById(data) {
  const request = axios.post(`${Domain}/package/getPackage`, data);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_PACKAGES_DETAIL_BY_ID,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function submitFinalVideo(data, submittedModalFun, setModalCentered) {
  const request = axios.post(
    `${Domain}/uservideo/startFinalVideoProcessing`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        setModalCentered(false);
        submittedModalFun(true, "success");
        return dispatch(finalSubmitModalLoading(false));
      })
      .catch((error) => {
        console.log(error);
        setModalCentered(false);
        submittedModalFun(true, "fail");
        return dispatch(finalSubmitModalLoading(false));
      });
}

export function uploadFiletoLocal(data) {
  console.log("this is data for local");
  const request = axios.post(
    `${Domain}/userVideos/UploadFileToLocalServer`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response, "this is data");
      })
      .catch((error) => {
        console.log(error);
      });
}

export function mediaPermisssion(data) {
  console.log(data);
  return (dispatch) => {
    return dispatch({
      type: MEDIA_PERMISSION,
      payload: data,
    });
  };
}

export function packageLoading(data) {
  return (dispatch) => {
    return dispatch({
      type: PACKAGE_LOADING,
      payload: data,
    });
  };
}

export function finalSubmitModalLoading(data) {
  return (dispatch) => {
    return dispatch({
      type: FINAL_SUBMIT_LOADING,
      payload: data,
    });
  };
}
