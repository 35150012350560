import axios from "axios";
import Domain from "lib/Config";

export const MY_ACCOUNT_DRAFTS = "MY_ACCOUNT_DRAFTS";
export const MY_ACCOUNT_USER_DETAIL = "MY_ACCOUNT_USER_DETAIL";
export const MY_ACCOUNT_GET_ALL_BLOG = "MY_ACCOUNT_GET_ALL_BLOG";
export const MY_ACCOUNT_GET_BLOG_BY_ID = "MY_ACCOUNT_GET_BLOG_BY_ID";
export const MY_ACCOUNT_LOADING = "MY_ACCOUNT_LOADING";

export function myAccountDrafts(data) {
  const request = axios.post(`${Domain}/package/getDrafts`, data);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: MY_ACCOUNT_DRAFTS,
          payload: response?.data?.Drafts,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function userDetail() {
  const request = axios.get(`${Domain}/user/UserDetails`);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: MY_ACCOUNT_USER_DETAIL,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function getAllBlog(data) {
  const request = axios.post(`${Domain}/blog/getallBlog`, data);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: MY_ACCOUNT_GET_ALL_BLOG,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}

export function getBlogByID(data) {
  const request = axios.post(`${Domain}/Blog/getBlogById`, data);
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: MY_ACCOUNT_GET_BLOG_BY_ID,
          payload: response?.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
}
export function myAccountLoading(data) {
  return (dispatch) => {
    return dispatch({
      type: MY_ACCOUNT_LOADING,
      payload: data,
    });
  };
}
