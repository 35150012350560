import React, { useEffect, useState } from "react";

import { Button, Modal } from "react-bootstrap";
import "./style.css";
import Cropper from "react-easy-crop";

const ImageCropModal = ({ modalCentered, uploadIamge }) => {
  const CROP_AREA_ASPECT = 2 / 2;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [objectURL, setObjectURL] = useState(null);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (modalCentered?.file) {
      setObjectURL(URL.createObjectURL(modalCentered?.file));
    }
  }, [modalCentered]);

  useEffect(() => {
    setTimeout(setRender(!render), 100);
  }, [objectURL]);

  const croppedPicture = async () => {
    let pic = objectURL;
    let croppedImgArea = croppedArea;
    let rotation = 1;
    const getImage = await createCroppedImage(pic, croppedImgArea, rotation);
    uploadIamge(getImage);
  };

  async function createCroppedImage(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, "image/webp");
    });
  }

  // image function.................
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }

  return (
    <>
      <Modal className="imageCropModal" centered show={modalCentered.bool}>
        <div className="mb-3 p-4">
          <Cropper
            image={objectURL}
            aspect={CROP_AREA_ASPECT}
            crop={crop}
            zoom={zoom}
            style={{ containerStyle: { height: "400px", width: "100%" } }}
            onCropChange={setCrop}
            onCropComplete={(e, d) => setCroppedArea(d)}
            onZoomChange={setZoom}
          />
        </div>

        <div className="position-A btn">
          <Button onClick={croppedPicture}>Crop and upload</Button>
        </div>
      </Modal>
    </>
  );
};

export default ImageCropModal;
