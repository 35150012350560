import React, { useEffect, useState } from "react";
import ChoosePersonalization from "./choosePersonalization";
import ComparisonChart from "./comparisonChart";
import OurVideosPromote from "./ourvideospromote";
import TopBanner from "./topBanner";
import "./style.css";
import learningContentBenifits from "Assets/images/landingpage/learningContentBenifits.png";
import learningContentBg from "Assets/images/landingpage/learningContentBg.svg";
import learningContentBenifitsMob from "Assets/images/landingpage/learningContentBenifitsMob.png";
import StarringYourLittle from "./staringYourLittle";
import VideoAndLearning from "./videoAndLearningFeatures";
import SimpleAndEasyOredering from "./simpleAndEasyOrdering";
import * as actions from "store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Input } from "reactstrap";

//
const Landingpage = () => {
  const [letters, setLetters] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    dispatch(actions.getPackages());
  }, []);

  const data = useSelector(({ PackageReducer }) => PackageReducer);
  const packageData = data?.packagesDetail?.package;

  return (
    <div>
      <div className="ourVideoPromoteBack">
        <TopBanner />
        <OurVideosPromote />
      </div>

      <StarringYourLittle />
      <VideoAndLearning />
      {/* <EducationalAndVisual /> */}
      <SimpleAndEasyOredering />
      <ChoosePersonalization />
      <ComparisonChart packageData={packageData} />

      <div
        className="d-none d-md-block"
        style={{ background: `url(${learningContentBg})` }}
      >
        <div className="container">
          <img alt="" className="w-100" src={learningContentBenifits} />
        </div>
      </div>
      <div className="d-block d-md-none">
        <img alt="" className="w-100" src={learningContentBenifitsMob} />
      </div>
      {/* <OurGallery /> */}
    </div>
  );
};

export default Landingpage;
