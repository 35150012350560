import React from "react";
import TopBannerElementImg from "Assets/images/landingpage/topbanner_Element_Img.svg";
import TopBannerImg3 from "Assets/images/landingpage/topBanner3crop-min.png";
import { Link } from "react-router-dom";
import topbannermob from "Assets/images/landingpage/topbannermob.png";

const TopBanner = () => {
  const sliderData = [
    {
      id: 1,
      img: TopBannerImg3,
      imgMob: topbannermob,
      heading:
        "for babies and early learners preparing for preschool, pre-k, &",
      spanHeading: "kindergarten!",
      spanHeading2: "Perfect",
      paraPersonalized: "Our personalized videos bring learning to life!",
      para: "Engaging your little with personalized learning features",
      spanPara: "starring them, & you!",
      para2:
        " Fostering math & reading readiness, observational, creative thinking, and language skills to enrich your littles learning.",
    },
  ];

  return (
    <div>
      {sliderData.map((item) => (
        <div
          key={item.id}
          className="d-flex align-items-end align-items-md-center position-R"
        >
          <div className="topBannerBackground d-none d-lg-block w-100">
            <img
              style={{ minHeight: "800px" }}
              className="topbannerImg w-100"
              src={item.img}
              alt=""
            />
          </div>

          <div className="topBannerBackground p-4 d-lg-none d-block w-100">
            <img className="w-100" src={item.imgMob} alt="" />
          </div>
          <div
            className="position-A d-none d-lg-block textPositionRight"
            style={{ width: "720px" }}
          >
            <img src={TopBannerElementImg} alt="" />
            <div className="mx-5 position-A" style={{ top: 140 }}>
              <h1 className="fw-700 px-4 font-size-44 color-purple">
                <span className="color-darkPink">
                  {item.spanHeading2}&nbsp;
                </span>
                {item.heading} <br />
                <span className="color-orange">{item.spanHeading}</span>
              </h1>
              <p className="px-4 color-DarkGray fw-600 font-size-18 mt-4">
                <span style={{ color: "#a60053" }}>
                  {item.paraPersonalized}{" "}
                </span>
                {item.para}{" "}
                <span
                  style={{ textDecoration: "underline" }}
                  className="color-purple fs-700"
                >
                  {item.spanPara}
                </span>
                {item.para2}
              </p>

              <Link to="/packages">
                <button className="color-DarkGray fw-600 font-size-16 m-4 px-3 br-10 bg-purple py-2">
                  Create Now
                </button>
              </Link>
            </div>
          </div>
        </div>
      ))}

      {/* mobnile responsive top banner */}
      {sliderData.map((item) => (
        <div className="pt-5 pb-4 bg-Extralightpurple d-block d-lg-none">
          <div className="">
            <h1 className="fw-700 px-4 font-size-38 color-purple">
              <span className="color-orange">{item.spanHeading2}&nbsp;</span>
              {item.heading} <br />
              <span className="color-orange">{item.spanHeading}</span>
            </h1>
            <p className="px-4 color-DarkGray fw-600 font-size-18 mt-4">
              {item.para}{" "}
              <span
                style={{ textDecoration: "underline" }}
                className="color-purple fs-700"
              >
                {item.spanPara}
              </span>{" "}
              {item.para2}
            </p>
            <Link to="/packages">
              <button className="color-DarkGray fw-600 font-size-16 m-4 px-3 br-10 bg-purple py-2">
                Create Now
              </button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TopBanner;
