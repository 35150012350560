import React, { useEffect } from "react";
import "./style.css";
import { useParams } from "react-router";
import * as actions from "store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import BlogDetail1 from "./blogDetail1";
import BlogDetail2 from "./blogDetail2";
import BlogDetail3 from "./blogDetail3";
import history from "@history";

const BlogDetail = ({}) => {
  const dispatch = useDispatch();
  const { id, name } = useParams();

  const blog = useSelector(
    ({ myAccountReducer }) => myAccountReducer?.blogById?.Blog
  );

  const userCheck = useSelector(({ auth }) => auth?.login?.success);
  useEffect(() => {
    if (userCheck === false) {
      history.push("/");
    }
  }, [userCheck]);

  useEffect(() => {
    let data = {
      id: id,
    };
    dispatch(actions.getBlogByID(data));
  }, []);

  return (
    <div>
      {name === "design1" && <BlogDetail1 blog={blog} />}
      {name === "design2" && <BlogDetail2 blog={blog} />}
      {name === "design3" && <BlogDetail3 blog={blog} />}
    </div>
  );
};

export default BlogDetail;
