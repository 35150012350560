import * as Actions from "../actions";

const initialState = {
  success: false,
  loading: false,
  error: null,
  msg: null,
};

const SignUP = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SIGN_UP_CUSTOMER: {
      return {
        ...initialState,
        success: true,
      };
    }
    case Actions.SIGN_UP_CUSTOMER_ERROR: {
      return {
        success: false,
        loading: false,
        error: action.payload,
      };
    }

    case Actions.SIGN_UP_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default SignUP;
