import React from "react";
import "./style.css";
import ReactPlayer from "react-player";
import { Row, Col } from "react-bootstrap";

const BlogDetail2 = ({ blog }) => {
  const isImage = (url) => {
    return /^\[webp|jpeg|jpg|png|avif|gif|svg]\s?I{3}$/.test(url);
  };
  return (
    <div className="container videosMainDiv p-5 my-5">
      <div className="">
      <Row style={{ gap: "15px" }} className="d-flex justify-content-between">
          <Col xl="8" md="12" className="mt-3">
            <h3 className="color-darkGreen font-size-24 fw-600">
              {blog?.BlogTitle}
            </h3>
            <div dangerouslySetInnerHTML={{ __html: blog?.BlogDescription }} />
          </Col>
          <Col xl="4" md="12"
            style={{ minWidth: "300px", maxWidth: "400px" }}
            className="d-flex justify-content-center"
          >
            <div className="logDetailImgDiv">
              {/* <img className="w-100" alt="" src={blog?.BlogImage} /> */}
              {isImage(blog?.BlogImage) ? (
                <img src={blog?.BlogImage} alt="" className="w-100" />
              ) : (
                <video
                  src={blog?.BlogImage}
                  controls="controls"
                  autoplay="true"
                  className="w-100"
                />
                // <video autoPlay className="w-100">
                //   <source src={blog?.BlogImage}></source>
                // </video>
              )}
            </div>
          </Col>
        </Row>

        <div className="mt-5">
          <div className="my-5">
            <div dangerouslySetInnerHTML={{ __html: blog?.BlogBody }} />
          </div>
          <div
            style={{ gap: "85px" }}
            className="d-flex flex-wrap flex-md-nowrap  justify-content-center"
          >
            <div
              style={{ maxWidth: "350px", minWidth: "150px" }}
              className="d-flex justify-content-center"
            >
              {isImage(blog?.BlogVideo1) ? (
                <img src={blog?.BlogVideo1} alt="" className="w-100" />
              ) : (
                // <video autoPlay className="w-100">
                //   <source src={blog?.BlogVideo1}></source>
                // </video>
                <video
                  src={blog?.BlogVideo1}
                  controls="controls"
                  autoplay="true"
                  className="w-100"
                />
              )}
              {/* <ReactPlayer
                height="100%"
                width="100%"
                preload={true}
                url={blog?.BlogVideo1}
                controls={true}
              /> */}
            </div>
            <div
              style={{ maxWidth: "350px", minWidth: "150px" }}
              className="d-flex justify-content-center"
            >
              {isImage(blog?.BlogVideo2) ? (
                <img src={blog?.BlogVideo2} alt="" className="w-100" />
              ) : (
                // <video autoPlay className="w-100">
                //   <source src={blog?.BlogVideo2}></source>
                // </video>
                <video
                  src={blog?.BlogVideo2}
                  controls="controls"
                  autoplay="true"
                  className="w-100"
                />
              )}
              {/* <ReactPlayer
                height="100%"
                width="100%"
                preload={true}
                url={blog?.BlogVideo2}
                controls={true}
              /> */}
            </div>
            <div
              style={{ maxWidth: "350px", minWidth: "150px" }}
              className="d-flex justify-content-center"
            >
              {isImage(blog?.BlogVideo3) ? (
                <img src={blog?.BlogVideo3} alt="" className="w-100" />
              ) : (
                // <video autoPlay className="w-100">
                //   <source src={blog?.BlogVideo3}></source>
                // </video>
                <video
                  src={blog?.BlogVideo3}
                  controls="controls"
                  autoplay="true"
                  className="w-100"
                />
              )}
              {/* <ReactPlayer
                height="100%"
                width="100%"
                preload={true}
                url={blog?.BlogVideo3}
                controls={true}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail2;
