import React from "react";
import "./style.css";
import pinkHr from "../../Assets/images/packagesAndDetail/descriptionPinkHr.svg";
import blueHr from "../../Assets/images/landingpage/educationalHr.svg";
import purpleHr from "../../Assets/images/packagesAndDetail/purpleHr.svg";
import greetings from "../../Assets/images/packagesAndDetail/packageImages/bundlegreetings.png";
import simpleOrdering from "../../Assets/images/packagesAndDetail/packageImages/simpleordering.png";
import chooseContent from "../../Assets/images/packagesAndDetail/packageImages/bundlechoosecontent.png";
import personalization from "../../Assets/images/packagesAndDetail/packageImages/budlepersonalization.png";
import addvoice from "../../Assets/images/packagesAndDetail/packageImages/addvoice.png";

const personalizeVideo = {
  id: 1,
  heading: "Personalized video intro includes:",
  listItem: [
    {
      id: 1,
      item: "Animation of child's name and photo ",
    },
  ],
};

const personalizeGreeting = {
  id: 1,
  heading: "Personalized greeting includes:",
  listItem: [
    {
      id: 1,
      item: "Selected written Message w/ themed animation",
    },
    {
      id: 2,
      item: "Recorded audio message from you and your photo",
    },
    {
      id: 3,
      item: "Signature (typed)",
    },
  ],
};

const greetingThemes = {
  id: 1,
  heading: "Greeting themes:",
  listItem: [
    {
      id: 1,
      item: "With Love",
    },
    {
      id: 2,
      item: "Happy Birthday",
    },
    {
      id: 3,
      item: "Merry Christmas",
    },
    {
      id: 4,
      item: "Happy Holidays",
    },
  ],
};

const signatureSelection = {
  id: 1,
  heading: "Signature selection:",
  listItem: [
    {
      id: 1,
      item: "Family titles (i.e. Mommy & Daddy)",
    },
    {
      id: 2,
      item: "Names (i.e. John & Tiffany)",
    },
    {
      id: 3,
      item: "Family last name (i.e. Dyson Family)",
    },
  ],
};

const ChooseContent = {
  id: 1,
  heading: "Choose your content:",
  listItem: [
    {
      id: 1,
      item: "Mix & Match, 30+ content blocks to choose from",
    },
    {
      id: 2,
      item: "Pre-selected content bundle, birth- 2 years",
    },
    {
      id: 3,
      item: "Pre-selected content bundle, 2-5 years",
    },
  ],
};

const contentSelection = {
  id: 1,
  heading: "Content selections include:",
  listItem: [
    {
      id: 1,
      item: "Reading & Math readiness",
    },
    {
      id: 2,
      item: "ABCs & 123s",
    },
    {
      id: 3,
      item: "Shapes & Colors",
    },
    {
      id: 4,
      item: "Animals & Habitats",
    },
    {
      id: 5,
      item: "Skip counting & Sight words",
    },
    {
      id: 6,
      item: "Nouns & Verbs",
    },
    {
      id: 7,
      item: "Days of the week, months of the year and much much more!",
    },
  ],
};

const personalizedLearningContent = {
  id: 1,
  heading: "Personalized learning content:",
  listItem: [
    {
      id: 1,
      item: "Animated spelling of name w/ photo",
    },
    {
      id: 2,
      item: "Animated birthday w/ photo",
    },
    {
      id: 3,
      item: "Family association: photos & titles incorporated into alphabet",
    },
  ],
};

const addYourVoiceToLearningContent = {
  id: 1,
  heading: "Add your voice to learning content:",
  listItem: [
    {
      id: 1,
      item: "alphabet",
    },
    {
      id: 2,
      item: "shapes",
    },
    {
      id: 3,
      item: "colors",
    },
    {
      id: 4,
      item: "numbers",
    },
  ],
};

const DescriptionAndDetail = ({ name }) => {
  const descColor =
    name === "littleknowitall"
      ? "color-lightPink"
      : name === "varsitybaby"
      ? "color-lightBlue"
      : name === "upperclassmen"
      ? "color-lightPurple"
      : "color-DarkGray";

  const decHr =
    name === "littleknowitall"
      ? pinkHr
      : name === "varsitybaby"
      ? blueHr
      : name === "upperclassmen"
      ? purpleHr
      : purpleHr;
  return (
    <div className="container">
      <div className="text-center">
        <p className={`font-size-38 fw-600 ${descColor}`}>
          Description & Details
        </p>
        <img src={decHr} />
      </div>
      <div className="row">
        <div className="col-md-6 my-5">
          <div>
            <h4 className="font-size-22 my-4 fw-600 color-black">
              {personalizeVideo.heading}
            </h4>
            <ul className="color-Gray fw-600 font-size-20">
              {personalizeVideo.listItem.map((item) => (
                <li key={item?.id} className="">
                  {item.item}
                </li>
              ))}
            </ul>
          </div>
          {/*  */}
          <div>
            <h4 className="font-size-22 my-4 fw-600 color-black">
              {personalizeGreeting.heading}
            </h4>
            <ul className="color-Gray fw-600 font-size-20">
              {personalizeGreeting.listItem.map((item) => (
                <li key={item?.id} className="">
                  {item.item}
                </li>
              ))}
            </ul>
          </div>
          {/*  */}
          <div>
            <h4 className="font-size-22 my-4 fw-600 color-black">
              {greetingThemes.heading}
            </h4>
            <ul className="color-Gray fw-600 font-size-20">
              {greetingThemes.listItem.map((item) => (
                <li key={item?.id} className="">
                  {item.item}
                </li>
              ))}
            </ul>
          </div>
          {/*  */}
          <div>
            <h4 className="font-size-22 my-4 fw-600 color-black">
              {signatureSelection.heading}
            </h4>
            <ul className="color-Gray fw-600 font-size-20">
              {signatureSelection.listItem.map((item) => (
                <li key={item?.id} className="">
                  {item.item}
                </li>
              ))}
            </ul>
          </div>
          {/*  */}
          <div>
            <h4 className="font-size-22 my-4 fw-600 color-black">
              {ChooseContent.heading}
            </h4>
            <ul className="color-Gray fw-600 font-size-20">
              {ChooseContent.listItem.map((item) => (
                <li key={item?.id} className="">
                  {item.item}
                </li>
              ))}
            </ul>
          </div>
          {/*  (name === "" && */}
          {name === "varsitybaby" && (
            <div>
              <h4 className="font-size-22 my-4 fw-600 color-black">
                {personalizedLearningContent.heading}
              </h4>
              <ul className="color-Gray fw-600 font-size-20">
                {personalizedLearningContent.listItem.map((item) => (
                  <li key={item?.id} className="">
                    {item.item}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {name === "upperclassmen" && (
            <div>
              <h4 className="font-size-22 my-4 fw-600 color-black">
                {personalizedLearningContent.heading}
              </h4>
              <ul className="color-Gray fw-600 font-size-20">
                {personalizedLearningContent.listItem.map((item) => (
                  <li key={item?.id} className="">
                    {item.item}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="col-md-6 my-5">
          <div className="mb-5">
            {name === "littleknowitall" && (
              <img className="w-100" src={greetings} />
            )}
            {name === "varsitybaby" && (
              <img className="w-100" src={personalization} />
            )}
            {name === "upperclassmen" && (
              <img className="w-100" src={addvoice} />
            )}
          </div>
          <div>
            <h4 className="font-size-22 my-4 fw-600 color-black">
              {contentSelection.heading}
            </h4>
            <ul className="color-Gray fw-600 font-size-20">
              {contentSelection.listItem.map((item) => (
                <li key={item?.id} className="">
                  {item.item}
                </li>
              ))}
            </ul>
          </div>
          {/*  */}
          {name === "upperclassmen" && (
            <div>
              <h4 className="font-size-22 my-4 fw-600 color-black">
                {addYourVoiceToLearningContent.heading}
              </h4>
              <ul className="color-Gray fw-600 font-size-20">
                {addYourVoiceToLearningContent.listItem.map((item) => (
                  <li key={item?.id} className="">
                    {item.item}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        {name === "littleknowitall" && (
          <div
            style={{ gap: "30px" }}
            className="d-flex justify-content-between mb-5 flex-wrap flex-md-nowrap"
          >
            <div className="w-100">
              <img className="w-100" src={simpleOrdering} />
            </div>
            <div className="w-100">
              <img className="w-100" src={chooseContent} />
            </div>
          </div>
        )}

        {name === "varsitybaby" && (
          <div
            style={{ gap: "30px" }}
            className="d-flex justify-content-between mb-5 flex-wrap flex-md-nowrap"
          >
            <div>
              <img className="w-100" src={greetings} />
            </div>
            <div>
              <img className="w-100" src={chooseContent} />
            </div>
          </div>
        )}

        {name === "upperclassmen" && (
          <div
            style={{ gap: "30px" }}
            className="d-flex justify-content-between mb-5 flex-wrap flex-md-nowrap"
          >
            <div className="w-100">
              <img className="w-100" src={greetings} />
            </div>
            <div className="w-100">
              <img className="w-100" src={chooseContent} />
            </div>
            <div className="w-100">
              <img className="w-100" src={simpleOrdering} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DescriptionAndDetail;
