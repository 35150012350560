import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import cross from "Assets/images/myAccounts/cross.svg";
import ReactPlayer from "react-player";

const ReviewVideoModal = ({
  modalCentered,
  setModalCentered,
  videoAndAudioData,
}) => {
  const [playAudio, setPlayAudio] = useState(false);

  const videoStart = () => {
    setPlayAudio(true);
  };

  const videoBuffer = () => {
    setPlayAudio(false);
  };

  const onPlay = () => {
    setPlayAudio(true);
  };

  const modalClose = () => {
    setModalCentered(!modalCentered);
    setPlayAudio(false);
  };

  return (
    <>
      <Modal className="profileEditModal" centered show={modalCentered}>
        <div className="mb-3 p-4">
          <div
            style={{ height: "16px" }}
            className={`d-flex justify-content-end cursor-pointer mb-3`}
          >
            <img onClick={modalClose} src={cross} />
          </div>
          <div>
            <ReactPlayer
              onStart={videoStart}
              onBuffer={videoBuffer}
              onPlay={onPlay}
              width="100%"
              height="100%"
              preload="auto"
              playing={true}
              playIcon
              url={videoAndAudioData?.videocontents?.VideoOverview}
              muted
            />
          </div>
          <div>
            <ReactPlayer
              height="1px"
              preload="auto"
              playing={playAudio}
              url={videoAndAudioData?.AudioLink}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReviewVideoModal;
