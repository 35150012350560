import React from "react";
import selfAwarness from "Assets/images/landingpage/selfawarness.png";
import readingReadiness from "Assets/images/landingpage/readingReadiness.png";
import mathReadiness from "Assets/images/landingpage/mathReadiness.png";
import interactiveExercise from "Assets/images/landingpage/interactiveExercise.png";
import swingHr from "Assets/images/landingpage/ourVideoPromoteHr.svg";

const ourVideoData = [
  {
    id: 1,
    name: "Self-Awareness",
    img: selfAwarness,
    tootltip: {
      heading: "Self-Awareness:",
      list: [
        {
          id: 1,
          listItem: "Child’s name & photos featured throughout video",
        },
        {
          id: 2,
          listItem: "Child can learn to the voice of loved ones",
        },
        {
          id: 3,
          listItem: "Child’s name spelled & pronounced",
        },
        {
          id: 4,
          listItem: "12+ family photos and titles incorporated",
        },
      ],
    },
  },
  {
    id: 2,
    name: "Reading Readiness",
    img: readingReadiness,
    tootltip: {
      heading:
        "Reading Readiness Support Choose from 13+ phonics learning blocks:",
      list: [
        {
          id: 1,
          listItem: "Alphabets & Letter sounds",
        },
        {
          id: 2,
          listItem: "Diagraphs & Blends",
        },
        {
          id: 3,
          listItem: "Sight words, vowels & word families",
        },
      ],
    },
  },
  {
    id: 3,
    name: "Math Readiness",
    img: mathReadiness,
    tootltip: {
      heading:
        "Math Readiness Support Over 12+ math learning blocks to choose from:",
      list: [
        {
          id: 1,
          listItem: "Numbers 1-10, 1-25, 1-50 and 1-100",
        },
        {
          id: 2,
          listItem: "Skip counting",
        },
        {
          id: 3,
          listItem: "Quantities & Measurements",
        },
        {
          id: 4,
          listItem: "Patterns, sizes & more",
        },
      ],
    },
  },
  {
    id: 4,
    name: "Interactive Learning Exercises",
    img: interactiveExercise,
    tootltip: {
      heading: "15+ Interactive learning exercises available:",
      list: [
        {
          id: 1,
          listItem: "Letter sounds",
        },
        {
          id: 2,
          listItem: "Counting",
        },
        {
          id: 3,
          listItem: "Rhyming",
        },
        {
          id: 4,
          listItem: "Patterns, & more",
        },
      ],
    },
  },
];

const OurVideosPromote = () => {
  return (
    <div style={{ padding: "60px 0px" }} className="container">
      <div className="text-center">
        <h3 className={"color-darkPink fw-600 font-size-38"}>
          CHOOSE YOUR LEARNING CONTENT (mix & match)
        </h3>
        <h3 className={"color-darkPink fw-600 font-size-38"}>
          Our Videos Promote & Include
        </h3>
        <img src={swingHr} />
      </div>

      <div className="mt-5 d-flex m-0 row">
        {ourVideoData?.map((item) => (
          <div
            key={item.id}
            className="col-lg-3 col-md-6 d-flex flex-column align-items-center"
          >
            <div className="tootltip">
              <div className="d-none d-lg-block">
                <div className="tootltipText br-10 p-3">
                  <span className="fw-700">{item.tootltip.heading}</span>
                  <ul>
                    {item.tootltip.list.map((item) => (
                      <li key={item.id} className="fw-600">
                        {item.listItem}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div
                style={{
                  overflow: "hidden",
                  width: "122px",
                  height: "122px",
                }}
              >
                <img className="w-100 object-fit-cover h-100" src={item.img} />
              </div>
            </div>

            <p className="color-black text-center font-size-26 my-2 fw-600">
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurVideosPromote;
