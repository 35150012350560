import jwtService from "services/jwtService";
import { NotificationManager } from "react-notifications";
import { setUserData, USER_LOGGED_OUT } from "./user.actions";
import axios from "axios";
import Domain from "lib/Config";
import history from "@history";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const USER_LOGGED_OUT_LOADING = "USER_LOGGED_OUT_LOADING";

// export function loginUser(data) {
//   return (dispatch) => {
//     return dispatch({
//       type: LOGIN_SUCCESS,
//       payload: data,
//     });
//   };
// }

export function loginUser({ email, password }) {
  return (dispatch) =>
    jwtService
      .signInUser(email, password)
      .then((user) => {
        dispatch(setUserData(user));

        const path = JSON.parse(localStorage.getItem("url"));
        console.log("path:", path);

        if (path === null) {
          history.goBack();
        } else {
          history.push(path);
        }

        return dispatch({
          type: LOGIN_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error?.msg, "error");
        NotificationManager.error(error ? error : "Incorrect Credentials");
        return dispatch({
          type: LOGIN_ERROR,
          payload: error,
        });
      });
}

export function SetLogin() {
  return (dispatch) => {
    return dispatch({
      type: LOGIN_SUCCESS,
    });
  };
}

export function loginLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: LOGIN_LOADING,
      payload: val,
    });
  };
}

// email verification action API.......
// ....................................
export function emailverify(data) {
  const request = axios.post(`${Domain}/user/emailVerification`, data);
  console.log(data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success("Account Verified");
        history.replace("/login");
        dispatch(loginLoading(false));
      })
      .catch((error) => {
        NotificationManager.error("Code may be expired or Wrong");
        dispatch(loginLoading(false));
      });
}

// resetPassword.......................
// ....................................

export function resetPasswordMail(data) {
  // const noData = 0;
  const request = axios.post(`${Domain}/user/resetPasswordMail`, data);
  console.log(data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        NotificationManager.success(response.data.msg);
        history.replace("/forgetpasswordsend");
        dispatch(loginLoading(false));
      })
      .catch((error) => {
        console.log(error.response);
        dispatch(loginLoading(false));
        NotificationManager.error(error.response.data.msg);
      });
}

export function resetPassword(data) {
  const noData = 0;
  const request = axios.post(`${Domain}/user/resetPassword`, data);
  console.log("Data", data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success(response.data.msg);
        setTimeout(() => {
          history.replace("/login");
        }, 1500);
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.msg);
        console.log(error);
      });
}

//
// resend verify email
export function resendVerifyEmail(data) {
  const request = axios.post(`${Domain}/user/resendVerifyEmail`, data);
  console.log(data);
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success(response.data.msg);
        dispatch(loginLoading(false));
        history.replace("/verificationcode");
        console.log(response);
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.error);
        dispatch(loginLoading(false));
      });
}
//
// logout
export function logOut() {
  return (dispatch, getState) => {
    jwtService.logout();
    dispatch(logOutLoading(false));
    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}

export function logOutLoading(val) {
  return (dispatch, getState) => {
    dispatch({
      type: USER_LOGGED_OUT_LOADING,
      payload: val,
    });
  };
}
//
// update password
export function updatePassword(data) {
  const request = axios.post(`${Domain}/updatePassword`, data);
  console.log(data, "this is update password");
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success(response.data.msg);
        console.log(response, "update password");
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error(error.response.data.error);
      });
}
//
// update profile

export function updateProfile(data) {
  const request = axios.put(`${Domain}/updateCustomer`, data);
  console.log(data, "this is update profile");
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success(response.data.msg);
        console.log(response, "update profile");
      })
      .catch((error) => {
        console.log(error);
        NotificationManager.error(error.response.data.error);
      });
}

// /password change

export function changePassword(data) {
  const request = axios.post(`${Domain}/user/ChangePassword`, data);
  console.log(data, "this is update profile");
  return (dispatch) =>
    request
      .then((response) => {
        NotificationManager.success(response.data.msg);
        console.log(response, "update profile");
        window.location.reload();
      })
      .catch((error) => {
        console.log("Error:", error.response);
        NotificationManager.error(error.response.data.error);
      });
}
