import { combineReducers } from "redux";
import auth from "auth/store/reducers";
import FormData from "./form.reducer";
import PackageReducer from "./package.reducer";
import myAccountReducer from "./myAccount.reducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    auth: auth,
    FormData: FormData,
    PackageReducer: PackageReducer,
    myAccountReducer: myAccountReducer,

    ...asyncReducers,
  });

export default createReducer;
