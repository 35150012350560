import PackageNav from "routes/package/packageNav";
import React, { useEffect, useState } from "react";
import "./style.css";
import editEmail from "Assets/images/myAccounts/editEmail.svg";
import MyAccountNav from "./myAccountNav";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import ProfileEditModal from "components/modal/profileEditModal";
import * as actions from "store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import history from "@history";

const MyVideos = loadable(() => pMinDelay(import("./myVideos"), 500));
const MediaGallery = loadable(() => pMinDelay(import("./mediaGallery"), 500));
const Drafts = loadable(() => pMinDelay(import("./drafts"), 500));
const Blogs = loadable(() => pMinDelay(import("./Blogs"), 500));

const MyAccount = () => {
  const dispatch = useDispatch();

  const [MyAccNavActive, setMyAccNavActive] = useState(1);
  const [modalCentered, setModalCentered] = useState(false);
  const userData = useSelector(({ auth }) => auth?.user);
  const userCheck = useSelector(({ auth }) => auth?.login?.success);
  const draftsData = useSelector(
    ({ myAccountReducer }) => myAccountReducer?.drafts
  );
  const galleryData = useSelector(({ myAccountReducer }) => myAccountReducer);

  useEffect(() => {
    if (userCheck === false) {
      history.push("/");
    }
  }, [userCheck]);

  const userVideos = galleryData?.userVideos;
  const gallery = galleryData?.gallery;
  const audioLinks = galleryData?.audioLinks;
  const blogs = galleryData?.allBlogs?.Blogs;
  const loading = galleryData?.loading;

  const togglePage = (data) => {
    setMyAccNavActive(data);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    let data = {};
    dispatch(actions.myAccountLoading(true));
    dispatch(actions.myAccountDrafts());
    dispatch(actions.userDetail());
    dispatch(actions.getAllBlog());
  }, []);

  const NavText =
    MyAccNavActive === 1
      ? "MY ACCOUNT/MY VIDEOS"
      : MyAccNavActive === 2
      ? "MY ACCOUNT/MEDIA GALLERY"
      : MyAccNavActive === 3
      ? "MY ACCOUNT/DRAFTS"
      : MyAccNavActive === 4
      ? "MY ACCOUNT/BLOGS"
      : MyAccNavActive === 4
      ? "MY ACCOUNT/BLOGDetail"
      : "MY ACCOUNT/BLOGDetail";

  return (
    <div>
      <PackageNav name="" NavText={NavText} />
      <div>
        <div className="d-flex justify-content-center flex-wrap emailDivBg align-items-center">
          <div className="p-2">
            <p className="m-0 color-DarkGray fw-600">{userData?.username}</p>
          </div>
          <div className="p-2">
            <p className="m-0 color-Gray fw-500">{userData?.email}</p>
          </div>
          <div
            onClick={() => setModalCentered(!modalCentered)}
            style={{ marginLeft: "40px" }}
            className="cursor-pointer p-2 mb-1"
          >
            <img alt="" src={editEmail} />
          </div>
        </div>
      </div>

      <MyAccountNav
        draftCount={draftsData?.length}
        MyVideos={MyVideos}
        MediaGallery={MediaGallery}
        Drafts={Drafts}
        Blogs={Blogs}
        setMyAccNavActive={setMyAccNavActive}
        MyAccNavActive={MyAccNavActive}
        togglePage={togglePage}
      />
      <div style={{ minHeight: "400px" }} className="px-3 px-md-none">
        {MyAccNavActive === 1 ? (
          <MyVideos loading={loading} userVideos={userVideos} />
        ) : null}
        {MyAccNavActive === 2 ? (
          <MediaGallery
            loading={loading}
            gallery={gallery}
            audioLinks={audioLinks}
          />
        ) : null}
        {MyAccNavActive === 3 ? (
          <Drafts loading={loading} draftsData={draftsData} />
        ) : null}
        {MyAccNavActive === 4 ? (
          <Blogs loading={loading} blogs={blogs} />
        ) : null}
      </div>
      <ProfileEditModal
        userData={userData}
        modalCentered={modalCentered}
        setModalCentered={setModalCentered}
        action="deactivate"
      />
    </div>
  );
};

export default MyAccount;
