import { combineReducers } from "redux";
import user from "./user.reducer";
import login from "./login.reducer";
import SignUP from "./registration.reducer";

const authReducers = combineReducers({
  SignUP,
  user,
  login,
});

export default authReducers;
