import React, { useEffect } from "react";
import "./style.css";
// import  from "Assets/images/login/loginImg.svg";
import loginImg from "../../Assets/images/login/loginImg.png";
import logo from "../../Assets/images/landingpage/logo.png";
import emailIcon from "Assets/images/login/emailIcon.svg";
import passIcon from "Assets/images/login/passIcon.svg";
import history from "@history";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "auth/store/actions";
import LinearProgress from "@material-ui/core/LinearProgress";

const LoginPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(({ auth }) => auth.login.loading);
  const authCheck = useSelector((auth) => auth?.auth?.login?.success);

  useEffect(() => {
    if (authCheck) {
      history.push("/");
    }
  }, [authCheck]);

  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    dispatch(authActions.loginLoading(true));
    dispatch(authActions.loginUser(data));
  };

  console.log(history);
  return (
    <div>
      {loading && <LinearProgress />}
      <div
        style={{
          backgroundImage: `url(${loginImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center">
          <div className="loginContainer p-5">
            <div className="text-center">
              <img className="w-100" src={logo} />
            </div>
            <form onSubmit={onSubmit}>
              <div>
                <h5 className="text-center color-darkGreen fw-700 mt-4 mb-3">
                  Login
                </h5>
                <div className="d-flex align-items-center my-3">
                  <img className="inputIcon" src={emailIcon} />
                  <input
                    name="email"
                    placeholder="Email"
                    className="w-100 loginInput"
                    type="email"
                    required
                  />
                </div>
                <div className="d-flex align-items-center">
                  <img className="inputIcon" src={passIcon} />
                  <input
                    name="password"
                    placeholder="Password"
                    className="w-100 loginInput"
                    type="password"
                    required
                  />
                </div>
                <div className="text-end font-size-14 mt-1 fw-500">
                  <div onClick={() => history.replace("/forgetpassword")}>
                    <span className="color-Gray cursor-pointer">
                      Forgot Password?
                    </span>
                  </div>

                  {/* <div onClick={() => history.replace("/resendverifyemail")}>
                    <span className="color-Gray cursor-pointer">
                      Verify Email
                    </span>
                  </div> */}
                </div>
                <div className="text-center mt-4">
                  <div className="d-flex justify-content-center mb-3">
                    <button type="submit" className="loginButton mx-1">
                      Login
                    </button>
                  </div>
                  <span className="color-Gray font-size-20 fw-600">
                    <span
                      onClick={() => history.replace("/signup")}
                      className="color-darkGreen text-nowrap cursor-pointer">
                      Create New Login Account
                    </span>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
