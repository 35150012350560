import * as Actions from "../actions";

const initialState = {
  userLearningData: null,
  userPreviewData: null,
  familyPicsData: null,
  videosData: null,
  packagesDetail: null,
  packagesDetailByID: null,
  userVideoId: null,
  loading: false,
  userPreviewDataError: null,
  finalModalLoading: false,
  mediaPermisssion: null,
  birthdayAudio: null,
  greetingAudio: null,
};

const PackageReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.USER_VIDEO_ID: {
      return {
        ...state,
        userVideoId: action.payload,
      };
    }

    case Actions.USER_LEARNING_DATA: {
      return {
        ...state,
        userLearningData: action.payload,
        loading: false,
      };
    }

    case Actions.USER_PREVIEW_DATA: {
      return {
        ...state,
        userPreviewData: action.payload,
        loading: false,
      };
    }

    case Actions.USER_PREVIEW_DATA_ERROR: {
      return {
        ...state,
        userPreviewDataError: action.payload,
        loading: false,
      };
    }

    case Actions.GET_FAMILY_PICS_DATA: {
      return {
        ...state,
        familyPicsData: action.payload,
        loading: false,
      };
    }

    case Actions.GET_VIDEOS_DATA: {
      return {
        ...state,
        videosData: action.payload,
        loading: false,
      };
    }

    case Actions.GET_PACKAGES_DETAIL: {
      return {
        ...state,
        packagesDetail: action.payload,
      };
    }

    case Actions.GET_BIRTHDAY_AUDIO: {
      return {
        ...state,
        birthdayAudio: action.payload,
      };
    }

    case Actions.GET_GREETING_AUDIO: {
      return {
        ...state,
        greetingAudio: action.payload,
      };
    }
    case Actions.GET_PACKAGES_DETAIL_BY_ID: {
      return {
        ...state,
        packagesDetailByID: action.payload,
        loading: false,
      };
    }

    case Actions.MEDIA_PERMISSION: {
      return {
        ...state,
        mediaPermisssion: action.payload,
      };
    }

    case Actions.PACKAGE_LOADING: {
      return {
        ...state,
        loading: action.payload,
        userPreviewData: null,
        familyPicsData: null,
        packagesDetailByID: null,
      };
    }

    case Actions.FINAL_SUBMIT_LOADING: {
      return {
        ...state,
        finalModalLoading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default PackageReducer;
