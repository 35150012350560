import React from "react";
import { Link } from "react-router-dom";
import logoWhite from "../../Assets/images/landingpage/logowhite.png";
import "./style.css";
import fb from "Assets/images/landingpage/fb.ii.svg";
import youtube from "Assets/images/landingpage/youtube.ii.svg";

const Footer = () => {
  return (
    <div className="footerbg">
      <div className="p-5">
        <div style={{ maxWidth: "320px" }} className="mb-4">
          <Link to="/">
            <img className="w-100" src={logoWhite} />
          </Link>
        </div>
        <div className="row" id="about">
          <div className="col-lg-3 d-flex justify-content-center">
            <div>
              <h1 className="font-size-26 color-white fw-700 mb-2">About Us</h1>
              <p className="color-white font-size-16 fw-600">
                We seek to provide early learning developmental resources and
                solutions to parents of children 0 to 5+ years of age. Part of
                our mission is to develop and create, innovative early learning
                platforms that are great for gifted children, but can be used by
                all. Additionally, after purchasing your video you will have
                access to the From “U” University member portal, which provides
                access to free early learning and development resources,
                information, and learning tools. If you prefer to learn more
                about our organization please&nbsp;
                <a
                  className="fw-600 text-decoration-none"
                  href="https://www.fromuuniversity.com/"
                  target="_blank"
                >
                  CLICK HERE
                </a>
                &nbsp;to exit this site.
              </p>
            </div>
          </div>

          {/*  */}
          <div className="col-md-4 col-lg-3 d-flex justify-content-md-center mt-5">
            <div>
              <h1 className="font-size-26 color-white fw-700 mb-4">
                Video Packages
              </h1>
              <ul
                style={{ listStyle: "none" }}
                className="font-size-16 color-white fw-700 m-0 p-0"
              >
                <Link
                  className="color-white text-decoration-none"
                  to="/package/littleknowitall"
                >
                  <li className="mb-2">Little Know It All</li>
                </Link>

                <Link
                  className="color-white text-decoration-none"
                  to="/package/varsitybaby"
                >
                  <li className="mb-2">Varsity Baby</li>
                </Link>

                <Link
                  className="color-white text-decoration-none"
                  to="/package/upperclassmen"
                >
                  <li className="mb-2">Upperclassmen</li>
                </Link>
              </ul>
            </div>
          </div>
          {/*  */}
          <div className="col-md-4 col-lg-3 d-flex justify-content-md-center mt-5">
            <div>
              <h1 className="font-size-26 text-white fw-700 mb-4">
                Useful Links
              </h1>
              <ul
                style={{ listStyle: "none" }}
                className="font-size-16 text-white fw-700 m-0 p-0"
              >
                <Link className="color-white text-decoration-none" to="/">
                  <li className="mb-2">Home</li>
                </Link>
                {/*  */}
                <a
                  className="color-white text-decoration-none"
                  target="_blank"
                  href="https://policy-terms.fromubaby.com/"
                >
                  <li className="mb-2">Policy & Terms</li>
                </a>
                <a
                  className="color-white text-decoration-none"
                  target="_blank"
                  href="https://support.fromubaby.com/"
                >
                  <li className="mb-2">Helpful Links</li>
                </a>
                <a
                  className="color-white text-decoration-none"
                  target="_blank"
                  href="https://help.fromubaby.com/"
                >
                  <li className="mb-2">FAQ</li>
                </a>
              </ul>
            </div>
          </div>
          {/*  */}
          <div
            className="col-md-3 col-lg-3 d-flex justify-content-md-center mt-5"
            id="contact"
          >
            <div>
              <h1 className="font-size-26 text-white fw-700 mb-4">
                Contact & Support
              </h1>
              <ul
                style={{ listStyle: "none" }}
                className="font-size-16 text-white fw-700 m-0 p-0"
              >
                <li className="mb-2">Chicago, IL</li>
                <li className="mb-2">708-793-8338</li>
                <li className="mb-2">
                  <a
                    className="text-white"
                    target="_blank"
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=support@fromubaby-videos.com"
                  >
                    support@fromubaby-videos.com
                  </a>
                </li>
                <li className="mb-2">
                  <a
                    className="text-white"
                    target="_blank"
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=contact@fromubaby-videos.com"
                  >
                    contact@fromubaby-videos.com
                  </a>
                </li>

                <li className="mb-3">
                  <a
                    className="text-white"
                    target="_blank"
                    href="https://forms.gle/HZwtGb54SzWBKCZC6"
                  >
                    Inquiries and feedback
                  </a>
                </li>

                <li style={{ gap: "30px" }} className="mb-2 d-flex">
                  <a
                    href="https://www.facebook.com/fromuuniversity"
                    target="_blank"
                  >
                    <img className="cursor-pointer" width="17" src={fb} />
                  </a>
                  {/* <a
                    href="https://www.facebook.com/fromuuniversity"
                    target="_blank"
                  >
                    <img className="cursor-pointer" width="37" src={insta} />
                  </a> */}

                  <a
                    href="https://www.youtube.com/channel/UCaGfsaYElMafA8gHiPGV1bA"
                    target="_blank"
                  >
                    <img className="cursor-pointer" width="50" src={youtube} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center px-4 py-3"
        style={{ background: "rgba(0, 0, 0, 0.2)" }}
      >
        <div>
          <p className="color-white opacity-1 m-0 fw-500">
            © {new Date().getFullYear()} From “U” University. All rights
            reserved.
          </p>
        </div>
        <div>
          <p className="color-white fw-500 opacity-1 m-0">
            Designed and developed by <strong>InfinityBits</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
