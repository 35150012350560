import React from "react";
import choosePersonalHr from "../../Assets/images/landingpage/choosePersonalHr.svg";
import disc1 from "../../Assets/images/landingpage/disc1.png";
import disc2 from "../../Assets/images/landingpage/disc2.png";
import disc3 from "../../Assets/images/landingpage/disc3.png";
import { Link } from "react-router-dom";

const PackagesAndDetail = () => {
  const packages = [
    {
      id: 1,
      img: disc1,
      color: "pink",
      imgOption: "Option 1",
      imgText: "Little Know it All",
      heading: "Cute, Sweet, & Simple! 3 Personalization Features",
      para: "This option gets straight to the point and makes a great gift or keepsake!",
      button: "pink",
      link: "package/littleknowitall",
      listItem: [
        { id: 2, item: "Personalized video intro w/ child's name & photo" },
        { id: 3, item: "Written greeting message w/ photo of you" },
        {
          id: 4,
          item: "Optional audio message from you",
        },
        {
          id: 4,
          item: "Select your learning content (optional)",
        },
      ],
    },
    {
      id: 5,
      img: disc2,
      color: "blue",
      imgOption: "Option 2",
      imgText: "Varsity Baby",
      heading: "Handcrafted! Over 5 personalization Features",
      para: "With over 5 personalization features, this option allows you to create an ultra-unique learning experience personalized just for them!",
      button: "blue",
      link: "package/varsitybaby",
      listItem: [
        { id: 6, item: "Everything from Little Know It All (Option 1)" },
        {
          id: 7,
          item: "Animated spelling & pronunciation of child's name w/ photo",
        },
        {
          id: 8,
          item: "Animation of child's birthday w/photo",
        },
        {
          id: 10,
          item: "Family photos & titles  merged into alphabet",
        },
      ],
    },
    {
      id: 11,
      img: disc3,
      color: "purple",
      imgOption: "Option 3",
      imgText: "Upperclassmen",
      heading: "Tailor Made! Over 7 personalization Features",
      para: "Boasting over 7 personalization and customization features, this option lets you create the ultimate personalized learning experience for your learning little, allowing them to learn to your voice!",
      button: "purple",
      link: "package/upperclassmen",
      listItem: [
        { id: 12, item: "Everything from Option 1 & Option 2" },
        { id: 13, item: "We add your  voice to the learning content" },
        {
          id: 14,
          item: "Record up to 5 sections",
        },
        {
          id: 15,
          item: "Your baby learns to your Voice!",
        },
      ],
    },
  ];

  return (
    <div>
      <div className="container">
        <div className="my-5 text-center">
          <h1 className="font-size-38 color-darkGreen fw-600">
            Video Personalization Packages & Details
          </h1>
          <img src={choosePersonalHr} />
        </div>

        {packages.map((item) => (
          <div key={item.id} className="row d-flex justify-content-center mb-5">
            <div
              style={{ maxWidth: "361px", minWidth: "300px" }}
              className="col-md-4 d-flex flex-column"
            >
              <div key={item.id} className="d-flex justify-content-center">
                <div
                  className={`${
                    item.button == "pink"
                      ? "sliderCardPink"
                      : item.button == "blue"
                      ? "sliderCardBlue"
                      : "sliderCardPurple"
                  }  mx-1 w-100 p-3`}
                >
                  <div className="d-flex w-100 flex-column alilgn-items-center justify-content-center text-center">
                    <h1
                      className={`${
                        item.color == "pink"
                          ? "sliderOptionPink"
                          : item.color == "blue"
                          ? "sliderOptionBlue"
                          : "sliderOptionPurple"
                      } m-0 mb-3`}
                    >
                      {item.imgOption}
                    </h1>
                    <div className="w-100 d-flex justify-content-center mb-3">
                      <img width="250" src={item.img} />
                    </div>
                    <h1
                      className={`${
                        item.color == "pink"
                          ? "sliderOptionPink"
                          : item.color == "blue"
                          ? "sliderOptionBlue"
                          : "sliderOptionPurple"
                      } m-0 mb-3`}
                    >
                      {item.imgText}
                    </h1>
                  </div>
                </div>
              </div>

              <div>
                <Link to={item.link}>
                  <button
                    className={`p-3 w-100 br-10 mt-4 fw-700 ${
                      item.button === "pink"
                        ? "cardButtonPink"
                        : item.button === "blue"
                        ? "cardButtonBlue"
                        : "cardButtonPurple"
                    } `}
                  >
                    Explore More
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-8 mt-5 mt-lg-0">
              <h4 className="font-size-22 fw-600 color-black">
                {item.heading}
              </h4>
              <p className="color-Gray font-size-20 fw-600">{item.para}</p>
              <div className="">
                <h4 className="font-size-22 fw-600 color-black">
                  Personalized with:
                </h4>
                <ul className="color-Gray fw-600 font-size-20">
                  {item.listItem.map((item) => (
                    <li className="mb-2" key={item.id}>
                      {item.item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PackagesAndDetail;
