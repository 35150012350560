import React, { useEffect, useRef } from "react";

const PypalBtn = ({ amount, setPayPalInfo, videoCreate }) => {
  const paypal = useRef();
  console.log("amout:", amount);

  useEffect(() => {
    console.log("window?.paypal", window.paypal);
    if (window.myButton) window.myButton.close();

    if (window?.paypal) {
      window.myButton = window?.paypal.Buttons({
        createOrder: (data, actions, error) => {
          return actions.order.create({
            intent: "CAPTURE",

            purchase_units: [
              {
                description: "Cool Looking table",
                amount: {
                  currency_code: "USD",
                  value: amount,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          // console.log("successfull", order);
          // console.log("data", data);
          // console.log("actions", actions);
          // createVideo();
          setPayPalInfo(order);
          videoCreate(true);
        },
        onError: (err) => {
          console.log("Error:", err);
        },
      });
    }

    console.log("paypal current:", paypal.current);

    window.myButton.render(paypal.current);

    // setCount(1);
  });

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
};

export default PypalBtn;
