import React, { useState } from "react";

import { Button, Modal } from "react-bootstrap";
import cross from "Assets/images/myAccounts/cross.svg";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";

const FinalSubmitModal = ({
  modalCentered,
  setModalCentered,
  onSubmitPackage,
}) => {
  const onSubmit = () => {
    onSubmitPackage();
  };

  const modalLoading = useSelector(
    ({ PackageReducer }) => PackageReducer?.finalModalLoading
  );

  // finalModalLoading

  return (
    <>
      <Modal className="profileEditModal" centered show={modalCentered}>
        <div className="mb-3 p-4">
          <div
            style={{ height: "16px" }}
            className={`d-flex justify-content-end cursor-pointer mb-3`}
          >
            <img onClick={() => setModalCentered(!modalCentered)} src={cross} />
          </div>
          <div className="px-2 d-flex justify-content-center">
            <div>
              <p className=" font-size-20 fw-500">
                Are you sure you want to Submit now?
              </p>
              <div
                style={{ gap: "20px" }}
                className="d-flex justify-content-end"
              >
                <div>
                  <Button
                    className="fw-600"
                    onClick={() => setModalCentered(false)}
                    variant="danger"
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    className="fw-600"
                    onClick={onSubmit}
                    style={{
                      height: "38px",
                      width: "80px",
                    }}
                    variant="success"
                  >
                    {modalLoading ? (
                      <div className="spinner-border" role="status">
                        <span class="sr-only"></span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FinalSubmitModal;
