import React from "react";

const VarsityRemainigPreview = ({
  momPhoto,
  dadPhoto,
  brotherPhoto,
  sisterPhoto,
  familyPhoto,
  grandParentsPhoto,
}) => {
  return (
    <div className="">
      <div className="row">
        {/* Mom Photo */}
        <div className="col-md-6">
          <div className="mb-4">
            <h1 className="font-size-16 color-DarkGray fw-600 ">MOM Photo</h1>
            <div className="d-flex">
              {momPhoto?.map((item) => (
                <div className="m-2 previePageImage d-flex justify-content-center align-items-center">
                  <img
                    style={{ maxWidth: "77px", maxHeight: "77px" }}
                    src={item.PicLink}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Dad Photo */}
        <div className="col-md-6">
          <div className="mb-4">
            <h1 className="font-size-16 color-DarkGray fw-600 ">DAD Photo</h1>
            <div className="d-flex">
              {dadPhoto?.map((item) => (
                <div className="m-2 previePageImage d-flex justify-content-center align-items-center">
                  <img
                    style={{ maxWidth: "77px", maxHeight: "77px" }}
                    className="object-fit-cover"
                    src={item.PicLink}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* brother Photo */}
        <div className="col-md-6">
          <div className="mb-4">
            <h1 className="font-size-16 color-DarkGray fw-600 ">
              BROTHER(s) Photo
            </h1>
            <div className="d-flex">
              {brotherPhoto?.map((item) => (
                <div className="m-2 previePageImage d-flex justify-content-center align-items-center">
                  <img
                    style={{ maxWidth: "77px", maxHeight: "77px" }}
                    className="object-fit-cover"
                    src={item.PicLink}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Sister Photo */}
        <div className="col-md-6">
          <div className="mb-4">
            <h1 className="font-size-16 color-DarkGray fw-600 ">
              SISTER(s) Photo
            </h1>
            <div className="d-flex">
              {sisterPhoto?.map((item) => (
                <div className="m-2 previePageImage d-flex justify-content-center align-items-center">
                  <img
                    style={{ maxWidth: "77px", maxHeight: "77px" }}
                    className="object-fit-cover"
                    src={item.PicLink}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Family Additional Photo */}
        <div className="col-md-6">
          <div className="mb-4">
            <h1 className="font-size-16 color-DarkGray fw-600">
              FAMILY(additional photos)
            </h1>
            <div className="d-flex">
              {familyPhoto?.map((item) => (
                <div className="m-2 previePageImage d-flex justify-content-center align-items-center">
                  <img
                    style={{ maxWidth: "77px", maxHeight: "77px" }}
                    className="object-fit-cover"
                    src={item.PicLink}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* GrandParents Photo */}
        <div className="col-md-6">
          <div className="mb-4">
            <h1 className="font-size-16 color-DarkGray fw-600">
              GRANDPARENT(s) Photo
            </h1>
            <div className="d-flex">
              {grandParentsPhoto?.map((item) => (
                <div className="m-2 previePageImage d-flex justify-content-center align-items-center">
                  <img
                    style={{ maxWidth: "77px", maxHeight: "77px" }}
                    className="object-fit-cover"
                    src={item.PicLink}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VarsityRemainigPreview;
