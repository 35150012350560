import React from "react";

const PackageNav = ({ name, NavText }) => {
  const packageNavBg =
    name === "littleknowitall"
      ? "bg-lightPink"
      : name === "varsitybaby"
      ? "bg-lightblue"
      : name === "upperclassmen"
      ? "bg-Extralightpurple"
      : "packageNavBg";

  const fontColor =
    name === "littleknowitall"
      ? "color-lightPink"
      : name === "varsitybaby"
      ? "color-lightBlue"
      : name === "upperclassmen"
      ? "color-purple"
      : "color-darkGreen";

  return (
    <div>
      <div className="text-center">
        <div className={`${packageNavBg} p-4`}>
          <h4 className={`m-0 font-size-20 ${fontColor} fw-600`}>{NavText}</h4>
        </div>
      </div>
    </div>
  );
};

export default PackageNav;
