import React, { useEffect } from "react";
import "./style.css";
import loginImg from "../../Assets/images/login/loginImg.png";
import logo from "../../Assets/images/landingpage/logo.png";
import emailIcon from "Assets/images/login/emailIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "auth/store/actions";
import passIcon from "Assets/images/login/passIcon.svg";
import history from "@history";
import LinearProgress from "@material-ui/core/LinearProgress";

const EmailVerificationCode = () => {
  const dispatch = useDispatch();

  const loading = useSelector(({ auth }) => auth.login.loading);
  const authCheck = useSelector((auth) => auth?.auth?.login?.success);

  useEffect(() => {
    if (authCheck) {
      history.push("/");
    }
  }, [authCheck]);

  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
      email: e.target.email.value,
      emToken: e.target.code.value,
    };
    dispatch(authActions.loginLoading(true));
    dispatch(authActions.emailverify(data));
  };
  // 4d98afc124
  return (
    <div>
      {loading && <LinearProgress />}

      <div
        style={{
          backgroundImage: `url(${loginImg})`,
          height: "100vh",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="loginContainer p-5">
          <div className="text-center">
            <img className="w-100" src={logo} />
          </div>
          <form onSubmit={onSubmit}>
            <div>
              <h5 className="text-center color-darkGreen fw-700 mt-4 mb-3">
                Enter Email Verification Code
              </h5>
              <div className="d-flex align-items-center mt-2">
                <img className="inputIcon" src={emailIcon} />
                <input
                  name="email"
                  placeholder="Enter Email"
                  className="w-100 loginInput"
                  type="text"
                  required
                />
              </div>
              <div className="d-flex align-items-center mt-2">
                <img className="inputIcon" src={passIcon} />
                <input
                  name="code"
                  placeholder="Enter Email Verification Code Here"
                  className="w-100 loginInput"
                  type="text"
                  required
                />
              </div>
              <div className="text-center mt-4">
                <div>
                  <button type="submit" className="loginButton mb-2">
                    Verify
                  </button>
                </div>
                <span className="color-Gray font-size-16 fw-500">
                  Want to login?&nbsp;
                  <span
                    onClick={() => history.replace("/login")}
                    className="color-darkGreen cursor-pointer"
                  >
                    Login
                  </span>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationCode;
