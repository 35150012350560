import React from "react";

const UpperClassmenPreview = ({ videoAudio }) => {
  function capitalize(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }

  return (
    <div className="">
      <div className="row">
        {videoAudio?.map((item) => (
          <div className="col-md-6">
            <div className="mb-4">
              <h1 className="font-size-16 color-DarkGray fw-600 ">
                {capitalize(item?.videocontents?.VideoName)}
              </h1>

              <div className="d-flex">
                <div>
                  <audio
                    className="AudioRecorder"
                    src={item?.AudioLink}
                    controls="controlBar"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpperClassmenPreview;
