import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import cross from "Assets/images/myAccounts/cross.svg";
import back from "Assets/images/myAccounts/back.svg";
import { Form, FormGroup, Label, Input } from "reactstrap";
import editEmail from "../../Assets/images/myAccounts/editEmail.svg";
import "./style.css";
import { useDispatch } from "react-redux";
import * as authActions from "auth/store/actions";

const ProfileEditModal = ({
  modalCentered,
  setModalCentered,
  action,
  userData,
}) => {

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [edit, setEdit] = useState(null);
  const [password, setPassword] = useState(null);
  const dispatch = useDispatch();
  const onSubmitUserName = (e) => {
    e.preventDefault();
    // let data = {
    //   oldPassword: e.target.oldPassword.value,
    //   newPassword: e.target.newPassword.value,
    // };
    // if (data.oldPassword && data.newPassword) {
    //   dispatch(authActions.loginLoading(true));
    //   dispatch(authActions.changePassword(data));
    // }
  };

  const onSubmitUserPassword = (e) => {
    e.preventDefault();

    if (password.oldPassword && password.password) {
      if (password.password === password.newPassword2) {
        let data = {
          oldPassword: password.oldPassword,
          password: password.password,
          password2: password.newPassword2,
        };
        dispatch(authActions.loginLoading(true));
        dispatch(authActions.changePassword(data));
        // setPassword(null);
        // setModalCentered(false);
      } else {
        console.log(password);
      }
    }
  };

  const passwordChange = (e) => {
    const { name, value } = e.target;
    if (
      e.target.name !== "oldPassword" &&
      password.password !== e.target.value
    ) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }

    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Modal className="profileEditModal" centered show={modalCentered}>
        <div className="mb-3 p-4">
          <div
            style={{ height: "16px" }}
            className={`d-flex ${
              edit ? "justify-content-between" : "justify-content-end"
            } cursor-pointer mb-3`}
          >
            {edit ? <img onClick={() => setEdit(null)} src={back} /> : null}
            <img onClick={() => setModalCentered(!modalCentered)} src={cross} />
          </div>
          <div className="px-2">
            {edit === null ? (
              <Form onSubmit={onSubmitUserName}>
                <div>
                  <div className="mb-3">
                    <FormGroup>
                      <Label
                        className="font-size-25 fw-500 mb-2"
                        for="exampleText"
                      >
                        Username
                      </Label>
                      <Input
                        disabled
                        type="text"
                        value={userData?.username}
                        className="bg-grayModalInput"
                        name="text"
                        placeholder="John Wick"
                        id="exampleText"
                      />
                    </FormGroup>
                  </div>
                  <div className="mb-3">
                    <FormGroup>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="font-size-25 fw-500">Email</div>
                        {/* <div
                          onClick={() => setEdit("email")}
                          className="cursor-pointer"
                        >
                          <img src={editEmail} />
                        </div> */}
                      </div>
                      <Input
                        disabled
                        value={userData?.email}
                        type="text"
                        className="bg-grayModalInput"
                        name="text"
                        placeholder="example@email.com"
                        id="exampleText"
                      />
                    </FormGroup>
                  </div>
                  <div className="mb-4">
                    <FormGroup>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="font-size-25 fw-500">Password</div>
                        <div
                          onClick={() => setEdit("password")}
                          className="cursor-pointer"
                        >
                          <img src={editEmail} />
                        </div>
                      </div>
                      <Input
                        disabled
                        type="text"
                        className="bg-grayModalInput"
                        name="text"
                        placeholder="Password"
                        id="exampleText"
                      />
                    </FormGroup>
                  </div>
                  {/* <Button
                    type="submit"
                    className="w-100 fw-600 editProfileUpdateButton mt-2"
                  >
                    Update
                  </Button> */}
                </div>
              </Form>
            ) : (
              <>
                {edit === "email" ? (
                  <div>
                    <div className="mb-3">
                      <FormGroup>
                        <Label
                          className="font-size-25 fw-500 mb-2"
                          for="exampleText"
                        >
                          Current Email
                        </Label>
                        <Input
                          type="Email"
                          className="bg-grayModalInput"
                          name="text"
                          placeholder="email@examole.com"
                          id="exampleText"
                        />
                      </FormGroup>
                    </div>
                    <div className="mb-3">
                      <FormGroup>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="font-size-25 fw-500">New Email</div>
                          {/* <div
                              onClick={() => setEdit("email")}
                              className="cursor-pointer"
                            >
                              <img src={editEmail} />
                            </div> */}
                        </div>
                        <Input
                          type="email"
                          className="bg-grayModalInput"
                          name="text"
                          placeholder="example@email.com"
                          id="exampleText"
                        />
                      </FormGroup>
                    </div>
                    <div className="mb-4">
                      <FormGroup>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div className="font-size-25 fw-500">Password</div>
                          {/* <div
                              onClick={() => setEdit("password")}
                              className="cursor-pointer"
                            >
                              <img src={editEmail} />
                            </div> */}
                        </div>
                        <Input
                          type="text"
                          className="bg-grayModalInput"
                          name="text"
                          placeholder="Password"
                          id="exampleText"
                        />
                      </FormGroup>
                    </div>
                    <Button
                      type="submit"
                      className="w-100 fw-600 editProfileUpdateButton mt-2"
                    >
                      Update
                    </Button>
                  </div>
                ) : (
                  <>
                    {edit === "password" ? (
                      <Form onSubmit={onSubmitUserPassword}>
                        <div>
                          <div className="mb-3">
                            <FormGroup>
                              <Label className="font-size-25 fw-500 mb-2">
                                Old Password
                              </Label>
                              <Input
                                className="bg-grayModalInput"
                                onChange={passwordChange}
                                value={password?.oldPassword}
                                name="oldPassword"
                                type="password"
                                placeholder="Old Password"
                                required
                              />
                            </FormGroup>
                          </div>
                          <div className="mb-3">
                            <FormGroup>
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="font-size-25 fw-500">
                                  New Password
                                </div>
                              </div>
                              <Input
                                type="password"
                                minLength="8"
                                onChange={passwordChange}
                                value={password?.password}
                                className="bg-grayModalInput"
                                name="password"
                                placeholder="new password"
                                id="exampleText"
                                required
                              />
                            </FormGroup>
                          </div>
                          <div className="mb-4">
                            <FormGroup>
                              <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="font-size-25 fw-500">
                                  Confirm Password
                                </div>
                                {/* <div
                              onClick={() => setEdit("password")}
                              className="cursor-pointer"
                            >
                              <img src={editEmail} />
                            </div> */}
                              </div>
                              <Input
                                type="password"
                                minLength="8"
                                value={password?.newPassword2}
                                onChange={passwordChange}
                                className="bg-grayModalInput"
                                name="newPassword2"
                                placeholder="confirm password"
                                id="exampleText"
                                required
                              />
                            </FormGroup>
                          </div>
                            <span className="text-danger fw-500">
                            {passwordMatch && "* New Password & Confirm password do not match"}{" "}
                            </span>
                          <Button
                            type="submit"
                            className="w-100 fw-600 editProfileUpdateButton mt-2"
                          >
                            Update
                          </Button>
                        </div>
                      </Form>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProfileEditModal;
