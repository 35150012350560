import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "./style.css";
import history from "@history";
import { CopyToClipboard } from "react-copy-to-clipboard";
import fb from "Assets/images/copytoclipboard/fbcopy.png";
import yt from "Assets/images/copytoclipboard/ytcopy.png";
import web from "Assets/images/copytoclipboard/webcopy.png";

const PackageSubmittedModal = ({
  modalCentered,
  setModalCentered,
  successSubmittedModal,
}) => {
  const [copy, setCopy] = useState();
  const onSubmit = () => {
    setModalCentered(false);
    history.push("/");
  };

  const onCopy = (id) => {
    setCopy(id);
  };

  return (
    <>
      <Modal className="profileEditModal" centered show={modalCentered}>
        <div className="mb-3 p-4">
          <div className="px-2 d-flex justify-content-center">
            <div>
              <h3 className="fw-500">
                {successSubmittedModal === "success"
                  ? "Submission successful!"
                  : "Submission failed!"}
              </h3>
              {successSubmittedModal === "success" ? (
                <>
                  <p className=" font-size-16 fw-500 mb-2">
                    Your video will be ready within the hour!... We will send
                    you an email when your video is ready.
                  </p>
                  <p className=" font-size-16 fw-500">
                    Remember sharing is caring, so please use the links below to
                    share, our videos with family & friends!
                  </p>
                </>
              ) : (
                <p className=" font-size-16 fw-500 mb-2">
                  There is some error. Please try again later
                </p>
              )}
            </div>
          </div>
          <div style={{ gap: "30px" }} className="d-flex">
            <div className="d-flex flex-column align-items-center">
              <div style={{ height: "30px" }}>
                {copy === 1 ? (
                  <div>
                    <p className="m-0 font-size-13 fw-700">Copied!</p>
                  </div>
                ) : null}
              </div>
              <div>
                <CopyToClipboard text="fromubaby-videos.com/">
                  <button
                    onClick={() => onCopy(1)}
                    style={{ background: "none", border: "none" }}
                  >
                    <img width="48" src={web} />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div style={{ height: "30px" }}>
                {copy === 2 ? (
                  <div>
                    <p className="m-0 font-size-13 fw-700">Copied!</p>
                  </div>
                ) : null}
              </div>
              <div>
                <CopyToClipboard text="https://www.facebook.com/fromuuniversity">
                  <button
                    onClick={() => onCopy(2)}
                    style={{ background: "none", border: "none" }}
                  >
                    <img width="48" src={fb} />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div style={{ height: "30px" }}>
                {copy === 3 ? (
                  <div>
                    <p className="m-0 font-size-13 fw-700">Copied!</p>
                  </div>
                ) : null}
              </div>
              <div>
                <CopyToClipboard text="https://www.youtube.com/channel/UCaGfsaYElMafA8gHiPGV1bA">
                  <button
                    onClick={() => onCopy(3)}
                    style={{ background: "none", border: "none" }}
                  >
                    <img width="48" src={yt} />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>

          <div style={{ gap: "20px" }} className="d-flex justify-content-end">
            <div>
              <Button className="fw-600" onClick={onSubmit} variant="danger">
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PackageSubmittedModal;
