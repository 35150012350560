import React from "react";
import "./style.css";
import verticalLine from "../../Assets/images/myAccounts/verticalLine.svg";

const MyAccountNav = ({
  MyAccNavActive,
  togglePage,
  MyVideos,
  MediaGallery,
  Drafts,
  Blogs,
  draftCount,
  BlogDetail,
}) => {
  return (
    <div
      className={`d-flex justify-content-center align-items-center mt-4 mb-3`}
    >
      <div className="mx-2">
        <button
          onMouseOver={() => MyVideos.preload()}
          onClick={() => togglePage(1)}
          className={`${
            MyAccNavActive === 1
              ? "myAccountNavButtonActive"
              : "myAccountNavButtonBlack"
          } px-2 py-1`}
        >
          My Videos
        </button>
      </div>
      <div className="mx-2">
        <button
          onMouseOver={() => MediaGallery.preload()}
          onClick={() => togglePage(2)}
          className={`${
            MyAccNavActive === 2
              ? "myAccountNavButtonActive"
              : "myAccountNavButtonBlack"
          } px-2 py-1`}
        >
          Media Gallery
        </button>
      </div>
      <div>
        <img src={verticalLine} />
      </div>
      <div className="mx-2">
        <button
          onMouseOver={() => Drafts.preload()}
          onClick={() => togglePage(3)}
          className={`${
            MyAccNavActive === 3
              ? "myAccountNavButtonActive"
              : "myAccountNavButton"
          } px-2 py-1`}
        >
          Drafts ({draftCount ? draftCount : 0})
        </button>
      </div>
      <div className="mx-2">
        <button
          onMouseOver={() => Blogs.preload()}
          onClick={() => togglePage(4)}
          className={`${
            MyAccNavActive === 4
              ? "myAccountNavButtonActive"
              : "myAccountNavButton"
          } px-2 py-1`}
        >
          Blogs
        </button>
      </div>{" "}
      <div className="mx-2">
        <a target="_blank" href="https://portal-support.fromubaby.com/">
          <button
            style={{ color: "#7d00bd" }}
            className={`${"myAccountNavButton"} px-2 py-1`}
          >
            User Guide
          </button>
        </a>
      </div>
    </div>
  );
};

export default MyAccountNav;
