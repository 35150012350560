import React, { useEffect } from "react";
import "./style.css";
import loginImg from "../../Assets/images/login/loginImg.png";
import logo from "../../Assets/images/landingpage/logo.png";
import emailIcon from "Assets/images/login/emailIcon.svg";
import passIcon from "Assets/images/login/passIcon.svg";
import userIcon from "Assets/images/login/userIcon.svg";
import * as authActions from "../../auth/store/actions";
import { useDispatch, useSelector } from "react-redux";
import history from "@history";
import LinearProgress from "@material-ui/core/LinearProgress";

const SignUP = () => {
  const dispatch = useDispatch();
  const loading = useSelector(({ auth }) => auth.SignUP.loading);
  const authCheck = useSelector((auth) => auth?.auth?.login?.success);

  useEffect(() => {
    if (authCheck) {
      history.push("/");
    }
  }, [authCheck]);

  const onSubmit = (e) => {
    e.preventDefault();

    let data = {
      username: e.target.userName.value,
      email: e.target.email.value,
      password: e.target.password.value,
    };
    dispatch(authActions.SignUpLoading(true));
    dispatch(authActions.signUp(data));
  };

  return (
    <div>
      {loading && <LinearProgress />}
      <div
        style={{
          backgroundImage: `url(${loginImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="loginContainer p-5">
            <div className="text-center">
              <img className="w-100" src={logo} />
            </div>
            <form onSubmit={onSubmit}>
              <div>
                <h5 className="text-center color-darkGreen fw-700 mt-4 mb-3">
                  SIGN UP
                </h5>
                <div className="d-flex align-items-center my-3">
                  <img className="inputIcon" src={userIcon} />
                  <input
                    type="text"
                    name="userName"
                    placeholder="Username"
                    className="w-100 loginInput"
                    required
                  />
                </div>
                <div className="d-flex align-items-center my-3">
                  <img className="inputIcon" src={emailIcon} />
                  <input
                    name="email"
                    placeholder="Email"
                    className="w-100 loginInput"
                    type="email"
                    required
                  />
                </div>
                <div className="d-flex align-items-center">
                  <img className="inputIcon" src={passIcon} />
                  <input
                    type="password"
                    name="password"
                    minLength="8"
                    placeholder="Password"
                    className="w-100 loginInput"
                    required
                  />
                </div>
                <div className="text-center mt-4">
                  <div>
                    <button type="submit" className="loginButton mb-2">
                      Sign up
                    </button>
                  </div>
                  <span className="color-Gray font-size-16 fw-500">
                  Already have an account?&nbsp;
                    <span
                      onClick={() => history.replace("/login")}
                      className="color-darkGreen cursor-pointer"
                    >
                      Login
                    </span>
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUP;
