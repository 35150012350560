import * as Actions from "../actions";

const initialState = {
  drafts: null,
  giverInfo: null,
  userVideos: null,
  gallery: null,
  audioLinks: null,
  blogById: null,
  loading: false,
};

const myAccountReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.MY_ACCOUNT_DRAFTS: {
      return {
        ...state,
        drafts: action.payload,
      };
    }

    case Actions.MY_ACCOUNT_USER_DETAIL: {
      return {
        ...state,
        giverInfo: action.payload?.GiverInfo,
        userVideos: action.payload?.userVideos,
        gallery: action.payload?.Gallay,
        audioLinks: action.payload?.AudioLink,
      };
    }

    case Actions.MY_ACCOUNT_GET_ALL_BLOG: {
      return {
        ...state,
        allBlogs: action.payload,
        loading: false,
      };
    }

    case Actions.MY_ACCOUNT_GET_BLOG_BY_ID: {
      return {
        ...state,
        blogById: action.payload,
      };
    }

    case Actions.MY_ACCOUNT_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default myAccountReducer;
