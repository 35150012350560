import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

const PurchasedModal = ({ modalCentered, setModalCentered, onNext }) => {
  const onSubmit = () => {
    onNext();
    setModalCentered(false);
  };

  return (
    <>
      <Modal className="profileEditModal" centered show={modalCentered}>
        <div className="mb-3 p-4">
          <div
            style={{ height: "16px" }}
            className={`d-flex justify-content-end cursor-pointer mb-3`}>
            {/* <img onClick={() => setModalCentered(!modalCentered)} src={cross} /> */}
          </div>
          <div className="px-2 d-flex justify-content-center">
            <div>
              <p className="text-center font-size-20 fw-500">
                Purchase Successful!
              </p>
              <div
                style={{ gap: "20px" }}
                className="d-flex justify-content-center">
                <div>
                  <Link to="/">
                    <Button className="fw-600" variant="success">
                      Exit & Login Later
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button
                    className="fw-600"
                    onClick={onSubmit}
                    variant="success">
                    Create Video Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PurchasedModal;
