import React, { useEffect, useState } from "react";
import "./style.css";
import loginImg from "../../Assets/images/login/loginImg.png";
import logo from "../../Assets/images/landingpage/logo.png";
import emailIcon from "Assets/images/login/emailIcon.svg";
import passIcon from "Assets/images/login/passIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import * as authActions from "auth/store/actions";
import history from "@history";
import LinearProgress from "@material-ui/core/LinearProgress";
import error from "../../Assets/images/forgotpass/error.webp"
import success from "../../Assets/images/forgotpass/success.jpg"
import back from "../../Assets/images/forgotpass/arrow.webp"
import { useParams } from "react-router-dom";

const ForgetPass = () => {
  const {id} = useParams();
  console.log("ID:", id)

  const dispatch = useDispatch();

  const loading = useSelector(({ auth }) => auth.login.loading);
  const authCheck = useSelector((auth) => auth?.auth?.login?.success);

  const [resetPassword, setResetPassword] = useState(1);

  useEffect(() => {
    if (authCheck) {
      history.push("/");
    }
  }, [authCheck]);

  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
      email: e.target.email.value,
    };
    dispatch(authActions.loginLoading(true));
    dispatch(authActions.resetPasswordMail(data));
  };

  const [changePass, setChangePass] = useState({
    email:"",
    token:"",
    password:"",
    confirmPassword:"",
  })
  const onChangeReset = (e) =>{
    const {name, value} = e.target;
    setChangePass({
      ...changePass,
      [name]:value
    }) 
  }

  const onSubmitReset = (e) =>{
    e.preventDefault();
    console.log("password reset called")
    console.log("change pass details:", changePass)
    // dispatch(authActions.resetPassword(changePass));
  }

  return (
    <div>
      {" "}
      {loading && <LinearProgress />}
      <div
        style={{
          backgroundImage: `url(${loginImg})`,
          height: "100vh",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <div className="loginContainer p-5">
          <div className="text-center">
            <img className="w-100" src={logo} />
          </div>

          {
            resetPassword == 1 ? 
            <form onSubmit={onSubmit}>
              <div>
                <h5 className="text-center color-darkGreen fw-700 mt-4 mb-3">
                  Send Code to Email
                </h5>
                <div className="d-flex align-items-center mt-2">
                  <img className="inputIcon" src={emailIcon} />
                  <input
                    name="email"
                    placeholder="Email"
                    className="w-100 loginInput"
                  />
                </div>
                <div className="text-center mt-4">
                  <div>
                    <button type="submit" className="loginButton mb-2">
                      Submit
                    </button>
                  </div>
                  <span className="color-Gray font-size-16 fw-500">
                    Want to login?&nbsp;
                    <span
                      onClick={() => history.replace("/login")}
                      className="color-darkGreen cursor-pointer"
                    >
                      Login
                    </span>
                  </span>
                </div>
              </div>
            </form>
            :
            ""
            // resetPassword == 2 ?
            // <form onSubmit={onSubmitReset}>
            // <div>
            //   <h5 className="text-center color-darkGreen fw-700 mt-4 mb-3">
            //     Verify and Set New Password
            //   </h5>
            //   <div className="d-flex align-items-center mt-2">
            //     <img className="inputIcon" src={emailIcon} />
            //     <input
            //       name="email"
            //       value={changePass.email}
            //       placeholder="Email"
            //       className="w-100 loginInput"
            //       onChange={(e)=>onChangeReset(e)}
            //     />
            //   </div>
            //   <div className="d-flex align-items-center mt-2">
            //     <img className="inputIcon" src={passIcon} />
            //     <input
            //       name="token"
            //       value={changePass.token}
            //       placeholder="Token"
            //       className="w-100 loginInput"
            //       onChange={(e)=>onChangeReset(e)}
            //     />
            //   </div>
            //   <div className="d-flex align-items-center mt-2">
            //     <img className="inputIcon" src={passIcon} />
            //     <input
            //       name="password"
            //       value={changePass.password}
            //       placeholder="Password"
            //       className="w-100 loginInput"
            //       onChange={(e)=>onChangeReset(e)}
            //     />
            //   </div>
            //   <div className="d-flex align-items-center mt-2">
            //     <img className="inputIcon" src={passIcon} />
            //     <input
            //       name="confirmPassword"
            //       value={changePass.confirmPassword}
            //       placeholder="Confirm Password"
            //       className="w-100 loginInput"
            //       onChange={(e)=>onChangeReset(e)}
            //     />
            //   </div>
            //   <div className="text-center mt-4">
            //     <div>
            //       <button type="submit" className="loginButton mb-2">
            //         Submit
            //       </button>
            //     </div>
            //     {/* <span className="color-Gray font-size-16 fw-500">
            //       Want to login?&nbsp;
            //       <span
            //         onClick={() => history.replace("/login")}
            //         className="color-darkGreen cursor-pointer"
            //       >
            //         Login
            //       </span>
            //     </span> */}
            //   </div>
            // </div>
            // </form>
            // :
            // resetPassword == 3 ?
            // <>
            // <div className="d-flex justify-content-center">
            //   <img className="w-50 my-4" src={success} />
            // </div>
            // <p className="text-center" style={{fontStyle:"normal"}}> <b style={{color:"green"}}> Request Successfull ! </b> New Password has been updated <span className="text-success" style={{textDecoration:"underline", cursor:"pointer"}} onClick={()=>history.push(("/login"))}> Login </span> </p>
            // </>
            // :
            // resetPassword == 4 ?    
            //   <>
            //       <img className="w-100" src={error} />
            //       <img className="w-100" />
            //       <p className="text-center" style={{fontStyle:"normal"}}> <b style={{color:"red"}}> Request Error! </b> Could not send new password to your email addresss <span className="text-danger" style={{textDecoration:"underline", cursor:"pointer"}} onClick={()=>history.push(`${1}`)}> Try Again </span> </p>
            //   </>
            // :
            // ""
          }
          
        </div>
      </div>
    </div>
  );
};

export default ForgetPass;
