import React from "react";
import choosePersonalHr from "Assets/images/landingpage/choosePersonalHr.svg";
import tick from "Assets/images/landingpage/tick.svg";
import cross from "Assets/images/landingpage/cross.svg";
import { Link } from "react-router-dom";

const ComparisonChart = ({ packageData }) => {
  return (
    <div className="mt-5" id="comparison-chart">
      <div className=" px-2">
        <h1 className="font-size-38 color-darkGreen fw-600 text-center">
          Video Comparison Chart
        </h1>

        <div className="text-center">
          <p className="font-size-22 color-DarkGray fw-600">
            See what's different
          </p>
          <img src={choosePersonalHr} />
        </div>
      </div>
      <div className="container d-none d-xl-block my-5 py-4">
        <div className="comparisonChartDiv d-flex p-2">
          <div
            style={{ minWidth: "300px" }}
            className="supportedFeaturesBack m-2 p-3 flex-grow-1"
          >
            <div>
              <h4 className="mb-5 text-center font-size-25 fw-600 color-darkGreen">
                Supported Features
              </h4>
              <p className="mb-3 color-black fw-600 font-size-18">
                Personalized video Intro w/ photo
              </p>
              <p className="mb-3 color-black fw-600 font-size-18">
                Personalized greeting w/ photo
              </p>
              <p className="mb-3 color-black fw-600 font-size-18">
                Audio greeting message
              </p>
              <p className="mb-3 color-black fw-600 font-size-18">
                Choose your content
              </p>
              <p className="mb-3 color-black fw-600 font-size-18">
                Pre-selected content available
              </p>
              <p className="mb-3 color-black fw-600 font-size-18">
                Add voice to the learning content
              </p>
              <p className="mb-3 color-black fw-600 font-size-18">
                Name spelling scene w/ photo
              </p>
              <p className="mb-3 color-black fw-600 font-size-18">
                Birthday scene w/ photo
              </p>
              <p className="mb-3 color-black fw-600 font-size-18">
                Family Association Alphabet
              </p>
            </div>
          </div>
          <div
            style={{ minWidth: "220px" }}
            className="littleKnowItAllBack m-2 p-3"
          >
            <div className="text-center">
              <Link to="/package/littleknowitall">
                <h4 className="mb-3 font-size-22 text-decoration-inherit fw-600 color-lightPink text-nowrap">
                  Little Know It All
                  <br />$ {packageData && packageData[0]?.PackagePrice}
                </h4>
              </Link>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-4">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={cross} width="25" />
              </div>
              <div className="mb-4">
                <img src={cross} width="25" />
              </div>
              <div className="mb-3">
                <img src={cross} width="25" />
              </div>
              <div className="mb-4">
                <img src={cross} width="25" />
              </div>
            </div>
          </div>
          {/*  */}
          <div
            style={{ minWidth: "230px" }}
            className="varsityBabyBack m-2 p-3"
          >
            <div className="text-center">
              <Link to="/package/varsitybaby">
                <h4 className="mb-3 font-size-22 text-decoration-inherit fw-600 color-lightBlue">
                  Varsity Baby
                  <br />$ {packageData && packageData[1]?.PackagePrice}
                </h4>
              </Link>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-4">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={cross} width="25" />
              </div>
              <div className="mb-4">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
            </div>
          </div>
          {/*  */}
          <div
            style={{ minWidth: "230px" }}
            className="upperclassmenBack m-2 p-3"
          >
            <div className="text-center">
              <Link to="/package/upperclassmen">
                <h4 className="mb-3 font-size-22 text-decoration-inherit fw-600 color-lightPurple">
                  Upperclassmen
                  <br />$ {packageData && packageData[2]?.PackagePrice}
                </h4>
              </Link>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-4">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
              <div className="mb-4">
                <img src={tick} width="25" />
              </div>
              <div className="mb-3">
                <img src={tick} width="25" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* for mobile chart */}
      <div className="p-3">
        <div className=" d-block d-xl-none my-5 py-4 overflow-auto">
          <div className="d-flex">
            <div
              style={{ minWidth: "300px" }}
              className="littleKnowItAllBack m-2 p-3"
            >
              <div className="">
                <Link to="/package/littleknowitall">
                  <h4 className="mb-3 font-size-22 text-decoration-inherit fw-600 color-lightPink text-nowrap">
                    Little Know It All
                    <br />$ {packageData && packageData[0]?.PackagePrice}
                  </h4>
                </Link>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Personalized Intro w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Personalized greeting w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Audio greeting message
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Choose your content
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Pre-selected content available
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Add voice to the learning content
                  </p>
                  <img style={{ marginLeft: "20px" }} src={cross} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Name spelling scene w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={cross} width="20" />
                </div>{" "}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Birthday scene w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={cross} width="20" />
                </div>{" "}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Family Association Alphabet w/ photos
                  </p>
                  <img style={{ marginLeft: "20px" }} src={cross} width="20" />
                </div>
              </div>
            </div>
            {/*  */}
            <div
              style={{ maxWidth: "300px", minWidth: "300px" }}
              className="varsityBabyBack m-2 p-3"
            >
              <div className="">
                <Link to="/package/varsitybaby">
                  <h4 className="mb-3 text-decoration-inherit font-size-22 fw-600 color-lightBlue">
                    Varsity Baby
                    <br />$ {packageData && packageData[1]?.PackagePrice}
                  </h4>
                </Link>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Personalized Intro w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Personalized greeting w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Audio greeting message
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Choose your content
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Pre-selected content available
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Add voice to the learning content
                  </p>
                  <img style={{ marginLeft: "20px" }} src={cross} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Name spelling scene w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>{" "}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Birthday scene w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>{" "}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Family Association Alphabet w/ photos
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
              </div>
            </div>
            {/*  */}
            <div
              style={{ maxWidth: "300px", minWidth: "300px" }}
              className="varsityBabyBack2 m-2 p-3"
            >
              <div className="">
                <Link to="/package/upperclassmen">
                  <h4 className="mb-3 font-size-22 text-decoration-inherit fw-600 color-lightPurple">
                    Upperclassmen
                    <br />$ {packageData && packageData[2]?.PackagePrice}
                  </h4>
                </Link>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Personalized Intro w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Personalized greeting w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Audio greeting message
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Choose your content
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Pre-selected content available
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Add voice to the learning content
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Name spelling scene w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>{" "}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Birthday scene w/ photo
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>{" "}
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="m-0 color-black fw-600 font-size-14">
                    Family Association Alphabet w/ photos
                  </p>
                  <img style={{ marginLeft: "20px" }} src={tick} width="20" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonChart;
