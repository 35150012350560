import "./App.css";
import Landingpage from "routes/landingPage";
import Footer from "components/layout/footer";
import Header from "components/layout/header";
import { Route, Switch } from "react-router-dom";
import Packages from "routes/package";
import PackageDetail from "routes/packageDetail";
import Forms from "routes/forms";
import MyAccount from "routes/myAccount";
import LoginPage from "components/loginPages/loginPage";
import SignUP from "components/loginPages/signUp";
import ForgetPass from "components/loginPages/forgotPass";
import Preview from "routes/forms/preview";
import EmailVerificationCode from "components/loginPages/emailVerificationCode";
import ResendVerifyEmail from "components/loginPages/resendVerifyEmail";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import ForgetPassword from "components/loginPages/forgotPassword";
import FormStep1 from "routes/forms/step1";
import BlogDetail from "routes/myAccount/blogDetail";

function App(props) {
  const getUrl = (pathname) => {
    let pathArray = pathname.split("/");

    return `/${pathArray[1]}` === "/login"
      ? true
      : `/${pathArray[1]}` === "/verificationcode"
      ? true
      : `/${pathArray[1]}` === "/resendverifyemail"
      ? true
      : `/${pathArray[1]}` === "/signup"
      ? true
      : `/${pathArray[1]}` === "/forgetpassword"
      ? true
      : `/${pathArray[1]}` === "/forgetpasswordsend"
      ? true
      : false;
  };

  const NotFound404 = () => {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "39vh" }}
      >
        <h1>Not Found</h1>
      </div>
    );
  };

  return (
    <div className="App">
      <NotificationContainer />
      {getUrl(props.location.pathname) ? (
        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => <LoginPage {...props} />}
          />
          <Route
            exact
            path="/verificationcode"
            render={(props) => <EmailVerificationCode {...props} />}
          />
          <Route
            exact
            path="/resendverifyemail"
            render={(props) => <ResendVerifyEmail {...props} />}
          />
          <Route
            exact
            path="/signup"
            render={(props) => <SignUP {...props} />}
          />
          <Route
            exact
            path="/forgetpassword"
            render={(props) => <ForgetPass {...props} />}
          />
          <Route
            exact
            path="/forgetpasswordsend"
            render={(props) => <ForgetPassword {...props} />}
          />
        </Switch>
      ) : (
        <>
          <Header />
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Landingpage {...props} />}
            />
            <Route
              exact
              path="/packages"
              render={(props) => <Packages {...props} />}
            />
            <Route
              exact
              path="/payment/:name"
              render={(props) => <FormStep1 {...props} />}
            />
            <Route
              exact
              path="/package/:id"
              render={(props) => <PackageDetail {...props} />}
            />
            <Route
              exact
              path="/create/:name/:id"
              render={(props) => <Forms {...props} />}
            />
            <Route
              exact
              path="/myaccount"
              render={(props) => <MyAccount {...props} />}
            />
            <Route
              exact
              path="/preview/:name/:id"
              render={(props) => <Preview {...props} />}
            />
            <Route
              exact
              path="/blog/:name/:id"
              render={(props) => <BlogDetail {...props} />}
            />
            <Route render={(props) => <NotFound404 {...props} />} />
          </Switch>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
