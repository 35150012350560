// for backend local
// const Domain = "http://localhost:3001";

// local
// const Domain = "http://192.168.18.42:3001";

// live
const Domain = "https://api.fromubaby-videos.com";
// const Domain = "http://192.168.18.42:3001"

export default Domain;
