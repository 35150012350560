import React, { useState, useEffect } from "react";
import pinkHr from "Assets/images/packagesAndDetail/descriptionPinkHr.svg";
import blueHr from "Assets/images/landingpage/educationalHr.svg";
import purpleHr from "Assets/images/packagesAndDetail/purpleHr.svg";
import useRecorder from "components/voiceRecorder";
import mic from "Assets/images/forms/mic.gif";
import axios from "axios";
import Domain from "lib/Config";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions";
import ReactPlayer from "react-player";
import ReviewVideoModal from "components/modal/reviewVideoModal";
import playButton from "Assets/images/forms/play.png";
import reviewButton from "Assets/images/forms/reviewVideo.png";
import replayButton from "Assets/images/forms/retry.png";
import upload from "Assets/images/forms/upload.png";
import caution from "Assets/images/forms/caution.png";
import { uploadAudio } from "components/uploadFile/uploadFile";
import { NotificationManager } from "react-notifications";

const instance = new axios.create({});
delete instance.defaults.headers.common[`Authorization`];

const UpperClassmenStep5 = (props) => {
  const dispatch = useDispatch();

  const [userVideoData, setUserVideoData] = useState();
  const [modalCentered, setModalCentered] = useState(false);
  const [videoAndAudioData, setVideoAndAudioData] = useState(null);
  const [videoId, setVideoId] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    let data = {
      userVideoId: id,
    };
    dispatch(actions.packageLoading(true));
    dispatch(actions.getVideosDataForAudio(data));
  }, []);

  let [audioURL, audioData, isRecording, startRecording, stopRecording] =
    useRecorder();

  const VideoData = useSelector(
    ({ PackageReducer }) => PackageReducer?.videosData?.UserData
  );

  const upperclassmenStep5Rule = [
    {
      id: 1,
      listItem:
        "Click, play, and follow the video prompts for each audio upload you want.",
    },
    {
      id: 2,
      listItem:
        "Recording will start automatically after countdown & stop automatically when finished.",
    },
    {
      id: 3,
      listItem: "Have FUN, use voice INFLECTION, and EXCITEMENT!",
    },
    {
      id: 4,
      listItem:
        "Talk to the microphone as if you are talking to your little one!",
    },
    {
      id: 5,
      listItem: "Please, pretty please, limit background noise.",
    },
    {
      id: 6,
      listItem:
        "Lastly, please click the upload button upon completion for each recording.",
    },
  ];

  const onStopRecording = (mic) => {
    stopRecording(mic);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // set data from reducer
  useEffect(() => {
    setUserVideoData(VideoData);
  }, [VideoData]);

  useEffect(() => {
    let isEmpty =
      Object.keys(audioData).length === 0 && audioData.constructor === Object;
    if (!isEmpty) {
      const objKey = Object.keys(audioData);
      const audioObj = audioData[objKey];
      const objectURL = URL.createObjectURL(audioObj);
      setUserVideoData(
        userVideoData.map((el) =>
          el.videocontents?.VideoName == objKey[0]
            ? {
                ...el,
                AudioLink: objectURL,
                LocalServerAudioLink: objectURL,
                audioObj: { [objKey]: audioObj },
              }
            : el
        )
      );
      // audioHandler(audioData);
    }
  }, [audioData]);

  // audio Upload
  async function audioHandler(audio) {
    // loading and disable field states
    const objKey = Object.keys(audio);
    const audioObj = audio[objKey];
    setUserVideoData(
      userVideoData.map((el) =>
        el.videocontents?.VideoName == objKey[0]
          ? {
              ...el,
              [`localLoading`]: true,
              [`loading`]: true,
            }
          : el
      )
    );

    // upload promise called
    uploadAudio(audio, id)
      .then((res) => {
        setUserVideoData(
          userVideoData.map((el) =>
            el.videocontents?.VideoName == objKey[0]
              ? {
                  ...el,
                  AudioLink: res[1]?.data?.result?.Location,
                  LocalServerAudioLink: res[0]?.data?.FileData?.FileUrl,
                  localLoading: false,
                  audioObj: null,
                  loading: false,
                  upload: true,
                }
              : el
          )
        );
      })
      .catch((error) => {
        setUserVideoData(
          userVideoData.map((el) =>
            el.videocontents?.VideoName == objKey[0]
              ? {
                  ...el,
                  loading: false,
                  errorImg: caution,
                  LocalServerAudioLinkError: caution,
                  localLoading: false,
                }
              : el
          )
        );
      });
  }

  const onSubmit = () => {
    const userVideoAudio = userVideoData.filter((item) => {
      if (item.AudioLink != null) return item.AudioLink;
    });

    let data = {
      userVideoId: id,
      AudioLink: userVideoAudio.map((item) => item.AudioLink),
      VideoContentId: userVideoAudio.map((item) => item?.videocontents?.id),
      LocalServerAudioLink: userVideoAudio.map(
        (item) => item.LocalServerAudioLink
      ),
    };

    let uploaded = userVideoAudio.map((item) => item.upload);
    if (uploaded.length === 4) {
      dispatch(actions.packageLoading(true));
      dispatch(actions.addVideosDataForAudio(data, props.next));
    } else {
      NotificationManager.warning("Please record against all videos");
    }
  };

  const wizardActive =
    props.name === "littleknowitall"
      ? "circleActivePink"
      : props.name === "varsitybaby"
      ? "circleActiveBlue"
      : props.name === "upperclassmen"
      ? "circleActivePurple"
      : "circleActivePurple";

  const wizardComplete =
    props.name === "littleknowitall"
      ? "circleCompletePink"
      : props.name === "varsitybaby"
      ? "circleCompleteBlue"
      : props.name === "upperclassmen"
      ? "circleCompletePurple"
      : "circleCompletePurple";

  const HeadingHr =
    props.name === "littleknowitall"
      ? pinkHr
      : props.name === "varsitybaby"
      ? blueHr
      : props.name === "upperclassmen"
      ? purpleHr
      : purpleHr;

  const payButton =
    props.name === "littleknowitall"
      ? "payButtonPink"
      : props.name === "varsitybaby"
      ? "payButtonBlue"
      : props.name === "upperclassmen"
      ? "payButtonPurple"
      : "payButtonPurple";

  const duration = (du, data) => {
    if (Math.floor(du?.playedSeconds) === 65) {
      if (isRecording?.recording === false) {
        startRecording(data);
      }
    }
    if (Math.floor(du?.playedSeconds) === Math.floor(du?.loadedSeconds)) {
      if (isRecording?.recording === true) {
        onStopRecording(data);
      }
    }
  };

  const checkYourVideo = (data) => {
    setVideoAndAudioData(data);
    setModalCentered(true);
  };

  function capitalize(word) {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
  }

  console.log(userVideoData);
  return (
    <div className="p-3">
      <div className="my-4">
        <div className="text-center">
          <h1 className="font-size-22 m-0 color-DarkGray fw-600 ">
            Almost Done...
          </h1>
          <h3 className="font-size-16 m-0 color-DarkGray fw-600 ">
            Record your audio
          </h3>
          <div className="">
            {/* <p className="font-size-16 my-2  color-Gray fw-600">
              but first we need a few pieces of information from you
            </p> */}
            <img className="" src={HeadingHr} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 d-none d-md-block">
          {props.allSteps?.map((item) => (
            <div
              className={`cursor-pointer ${
                item.order < props.current
                  ? wizardComplete
                  : item.order === props.current
                  ? wizardActive
                  : "circle"
              } `}
              onClick={() => props.jump(item.order)}
            >
              {item.order}
            </div>
          ))}
        </div>
        <div className="col-md-9">
          <div>
            <ul className="p-3">
              {upperclassmenStep5Rule.map((item) => (
                <li
                  key={item.id}
                  className="color-lightPurple fw-500 font-size-16"
                >
                  {item.listItem}
                </li>
              ))}
            </ul>
          </div>

          {userVideoData?.map((item) => (
            <div keys={item?.videocontents?.id} className="mb-4">
              <h4 className="fw-700 color-Gray text-center font-size-22">
                {capitalize(item?.videocontents?.VideoName)}
              </h4>
              <div
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "7px",
                  overflow: "hidden",
                }}
                className="mb-3 position-R"
              >
                <div
                  className={`position-A m-2 ${
                    isRecording?.data === item?.videocontents?.VideoName
                      ? isRecording?.recording
                        ? "d-block"
                        : "d-none"
                      : "d-none"
                  }`}
                >
                  <img style={{ width: "60px" }} src={mic} />
                </div>
                <div
                  style={{ zIndex: 10 }}
                  className={`position-A w-100 h-100 align-items-center justify-content-center ${
                    videoId === item?.videocontents?.id ? "d-none" : "d-flex"
                  }`}
                >
                  {item?.loading || item?.loadingLocal ? (
                    <div class="spinner-border" role="status">
                      <span class="sr-only"></span>
                      {console.log(item?.loading)}
                    </div>
                  ) : (
                    <>
                      {item?.errorImg || item?.LocalServerAudioLinkError ? (
                        <div>
                          <div
                            className="d-flex flex-column align-items-center"
                            onClick={() => setVideoId(item?.videocontents?.id)}
                          >
                            <div style={{ width: "70px" }}>
                              <img
                                className="w-100 cursor-pointer"
                                src={
                                  item?.errorImg
                                    ? item?.errorImg
                                    : item?.LocalServerAudioLinkError
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            <div
                              className="d-flex flex-column align-items-center"
                              onClick={() =>
                                setVideoId(item?.videocontents?.id)
                              }
                            >
                              <div style={{ width: "70px" }}>
                                <img
                                  className="w-100 cursor-pointer"
                                  src={
                                    item?.AudioLink ? replayButton : playButton
                                  }
                                />
                              </div>
                              <p className="cursor-pointer text-center m-0 font-size-14 color-Gray fw-500">
                                {item?.AudioLink
                                  ? "Rerecord"
                                  : "Click to Begin Recording"}
                              </p>
                            </div>
                          </div>
                          {item?.AudioLink && (
                            <h1 className="color-Gray fw-700">
                              &nbsp;&nbsp;/&nbsp;&nbsp;
                            </h1>
                          )}

                          <div>
                            {item?.AudioLink && (
                              <div className="d-flex align-items-center">
                                <div
                                  style={{ width: "70px" }}
                                  className="cursor-pointer"
                                  onClick={() => checkYourVideo(item)}
                                >
                                  <img className="w-100" src={reviewButton} />
                                  <p className="text-center color-Gray m-0 font-size-14 fw-500">
                                    Review
                                  </p>
                                </div>

                                {item?.audioObj ? (
                                  <div className="d-flex align-items-center">
                                    <h1 className="color-Gray fw-700">
                                      &nbsp;&nbsp;/&nbsp;&nbsp;
                                    </h1>
                                    <div
                                      style={{ width: "70px" }}
                                      className="cursor-pointer"
                                      onClick={() =>
                                        audioHandler(item.audioObj)
                                      }
                                    >
                                      <img className="w-100" src={upload} />
                                      <p className="text-center color-Gray m-0 font-size-14 fw-500">
                                        upload
                                      </p>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>

                <ReactPlayer
                  playing={videoId === item?.videocontents?.id ? true : false}
                  height="100%"
                  pip={false}
                  width="100%"
                  onEnded={() => setVideoId(null)}
                  preload={true}
                  onProgress={(du) =>
                    duration(du, item?.videocontents?.VideoName)
                  }
                  url={item?.videocontents?.VideoLink}
                  controls={false}
                />
              </div>

              <div className="">
                <p className="m-0 color-Gray font-size-18 fw-600">
                  Don’t forget to click the upload icon for each video you
                  record
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-end mt-4 mb-3">
        <button
          // hidden={props.isFirst()}
          disabled={props.isFirst()}
          onClick={props.prev}
          className="backButton mx-3"
        >
          Back
        </button>
        <button
          onClick={onSubmit}
          // hidden={props.isLast()}
          disabled={props.isLast()}
          // onClick={props.next}
          className={payButton}
        >
          Save & Next
        </button>
      </div>
      <ReviewVideoModal
        modalCentered={modalCentered}
        setModalCentered={setModalCentered}
        videoAndAudioData={videoAndAudioData}
      />
    </div>
  );
};

export default UpperClassmenStep5;
