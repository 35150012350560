import React, { useEffect } from "react";
import MultiStepForm from "./multiStepForm";
import "./style.css";
import { useParams } from "react-router-dom";
import * as actions from "store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import history from "@history";
import LoadingOverlay from "react-loading-overlay";

const Forms = () => {
  const dispatch = useDispatch();
  const packageData = useSelector(({ PackageReducer }) => PackageReducer);
  const packages = packageData?.userPreviewData;
  const errorPreview = packageData?.userPreviewDataError?.response?.data?.msg;
  const loading = useSelector(
    (PackageReducer) => PackageReducer?.PackageReducer?.loading
  );

  const userCheck = useSelector(({ auth }) => auth?.login?.success);

  useEffect(() => {
    if (userCheck === false) {
      history.push("/");
    }
  }, [userCheck]);

  const { name } = useParams();

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        dispatch(actions.mediaPermisssion(stream));
        console.log(stream);
        // window.localAudio.srcObject = stream; // Bstream
      })
      .catch((err) => {
        console.log("u got an error:" + err);
      });
  }, []);

  const formBg =
    name === "littleknowitall"
      ? "bg-lightPink"
      : name === "varsitybaby"
      ? "bg-lightblue"
      : name === "upperclassmen"
      ? "bg-Extralightpurple"
      : "bg-Extralightpurple";

  return (
    <LoadingOverlay active={loading} spinner text="Loading your content...">
      <div className={formBg}>
        <div>
          <MultiStepForm
            error={errorPreview}
            packageData={packages}
            name={name}
          />
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Forms;
