import React, { useState, useEffect, useRef } from "react";
import pinkHr from "Assets/images/packagesAndDetail/descriptionPinkHr.svg";
import blueHr from "Assets/images/landingpage/educationalHr.svg";
import purpleHr from "Assets/images/packagesAndDetail/purpleHr.svg";
import fileUpload from "Assets/images/forms/fileUpload.svg";
import mic from "Assets/images/forms/mic.svg";
import audioGif from "Assets/images/forms/audio.gif";
import { FormGroup, Label, Input } from "reactstrap";
import useRecorder from "components/voiceRecorder";
import * as actions from "store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import ReactPlayer from "react-player";
import playButton from "Assets/images/forms/play.png";
import micGif from "Assets/images/forms/mic.gif";
import replayButton from "Assets/images/forms/retry.png";
import caution from "Assets/images/forms/caution.png";
import { NotificationManager } from "react-notifications";
import cross from "Assets/images/landingpage/cross.svg";
import * as moment from "moment";
import sampleAudio from "Assets/videos/sampleaudio.ogg";
import { uploadFile, uploadAudio } from "components/uploadFile/uploadFile";
import ImageCropModal from "components/modal/imageCropModal";

const greetingAndThemeRadio = [
  {
    id: 1,
    label: "Happy Birthday",
    value: "Happy Birthday",
    name: "personalGreetings",
  },
  {
    id: 2,
    label: "With Love...(using names listed below)",
    value: "With Love...(using names listed below)",
    name: "personalGreetings",
  },
  {
    id: 3,
    label: "Merry Christmas",
    value: "Merry Christmas",
    name: "personalGreetings",
  },
  {
    id: 4,
    label: "Happy Holidays",
    value: "Happy Holidays",
    name: "personalGreetings",
  },
];

const ifBirthDay = [
  {
    id: 1,
    label: "1st",
    labelValue: "1st",
    value: "1st",
    name: "ifBirthDAy",
  },
  {
    id: 2,
    label: "2nd",
    labelValue: "2nd",
    value: "2nd",
    name: "ifBirthDAy",
  },
  {
    id: 3,
    label: "3rd",
    labelValue: "3rd",
    value: "3rd",
    name: "ifBirthDAy",
  },
  {
    id: 4,
    label: "4th",
    labelValue: "4th",
    value: "4th",
    name: "ifBirthDAy",
  },
  {
    id: 5,
    label: "5th",
    labelValue: "5th",
    value: "5th",
    name: "ifBirthDAy",
  },
  {
    id: 6,
    label: "N/A",
    labelValue: "0",
    value: "N/A",
    name: "ifBirthDAy",
  },
];

const FormStep2 = (props) => {
  const dispatch = useDispatch();
  const [recipientName, setRecipientName] = useState("");
  const [whoIsInThePhotoText, setWhoIsInThePhotoText] = useState("");
  const [countDownName, setCountDownName] = useState("");
  const [nameAudioPlay, setnameAudioPlay] = useState(false);
  const [hbdAuioPlay, setHbdAuioPlay] = useState(false);
  const [fileUploadDisable, setFileUploadDisable] = useState(false);
  const [greetingPlay, setGreetingPlay] = useState(false);
  const [isDefaultMelody, setIsDefaultMelody] = useState(0);
  const [audioLoad, setAudioLoad] = useState({
    nameAudio: false,
    hbdAudio: false,
    greetingAudio: false,
  });
  const cancelFileupload = useRef();
  const onClearTimeout = useRef();
  const [file, setFile] = useState({
    childPhotoLoading: false,
    familyPhotoLoading: false,
    audioLoading: false,
    childPhotoLocal: null,
  });

  const [radioButtonId, setRadioButtonId] = useState();
  const [modalCentered, setModalCentered] = useState({
    bool: false,
    file: null,
  });

  const { id } = useParams();

  const imageEvent = useRef();

  let [audioURL, audioData, isRecording, startRecording, stopRecording] =
    useRecorder();

  const mediaPermission = useSelector(
    ({ PackageReducer }) => PackageReducer?.mediaPermisssion
  );
  const packageReducer = useSelector(
    (PackageReducer) => PackageReducer?.PackageReducer
  );
  let loading = packageReducer?.loading;
  let packageDetail = props?.packageData?.data?.package1recipients[0];
  let packageDetails = props?.packageData?.data;

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    setFile({
      ...file,
      recordchildhbd: packageReducer?.birthdayAudio?.AudioS3Link,
      recordchildhbdLocal: packageReducer?.birthdayAudio?.AudioLocalServerLink,
      greetingAudio: packageReducer?.greetingAudio?.AudioS3Link,
      greetingAudioLocal: packageReducer?.greetingAudio?.AudioLocalServerLink,
    });

    setIsDefaultMelody(packageReducer?.greetingAudio?.IsDefaultAudio);
  }, [packageReducer]);

  // console.log(packageReducer?.greetingAudio?.IsDefaultAudio);

  useEffect(() => {
    window.scroll(0, 0);
    let data = {
      userVideoId: id,
    };
    dispatch(actions.packageLoading(true));
    dispatch(actions.getBirthdayAudio(data));
    dispatch(actions.getGreetingAudio(data));
    dispatch(actions.getPreviewDataOfVideo(data));
  }, []);

  // save data in State from api useEffect
  useEffect(() => {
    // for Photos
    const childphoto = packageDetail?.Package1RecipientsChildpics[0]?.Link;
    const localChildPhotoLink =
      packageDetail?.Package1RecipientsChildpics[0]?.LocalServerPicLink;
    const familyPhoto =
      packageDetail?.package1Recipientsphotos[0]?.FamilyPhotoLink;
    const localFamilyPhoto =
      packageDetail?.package1Recipientsphotos[0]?.LocalServerFamilyPhotoLink;
    const whoIsAudio = packageDetail?.package1Recipientsphotos[0]?.AudioLink;
    const localWhoIsAudio =
      packageDetail?.package1Recipientsphotos[0]?.AudioLocalServerLink;

    setFile({
      ...file,
      childPhoto: childphoto ? childphoto : null,
      familyPhoto: familyPhoto ? familyPhoto : null,
      recipientName: whoIsAudio ? whoIsAudio : null,
      childPhotoLocal: localChildPhotoLink ? localChildPhotoLink : null,
      familyPhotoLocal: localFamilyPhoto ? localFamilyPhoto : null,
      recipientNameLocal: localWhoIsAudio ? localWhoIsAudio : null,
    });

    // for greetaings
    const greetingThemes = packageDetail?.GreetingThemeId;
    const ifForBirthDay = packageDetail?.BirthdayYear;
    setRadioButtonId({
      ...radioButtonId,
      personalGreeting: greetingThemes,
      ifForBirthDay: ifForBirthDay,
    });

    if (packageDetail) {
      setRecipientName(packageDetail?.RecipientsName);
      setWhoIsInThePhotoText(
        packageDetail?.package1Recipientsphotos[0]?.RelationWithChild
      );
    }
  }, [packageDetails]);

  useEffect(() => {
    if (Object.keys(audioData).length !== 0) {
      audioHandler(audioData);
    }
  }, [audioData]);

  // for RecipientName Voices
  var i = 0;
  function startNameCountDown() {
    let time = i === 0 ? 500 : 2000;
    setTimeout(function () {
      setCountDownName(recipientName[i]);
      i++;
      if (i < recipientName.length + 1) {
        startNameCountDown();
      } else {
        setCountDownName(recipientName);
      }
    }, time);
  }

  // onSubmit data
  const onSubmit = (e) => {
    e.preventDefault();
    var fullMonthName = moment(e.target.recipientBirth.value).format(
      "MMMM-D-yyyy"
    );
    let data = {
      RecipientsName: e.target.recipientName.value.toUpperCase(),
      userVideoId: id,
      // RecipientAge: e.target.recipientAge.value,
      RecipientBirthday: fullMonthName,
      GreetingThemeId: radioButtonId?.personalGreeting,
      BirthdayYear:
        radioButtonId?.personalGreeting === "1"
          ? radioButtonId?.ifForBirthDay
          : null,
      RelationWithChild: e.target.whoIsInThePhoto.value,
      RecipientChildPicdataLink: file?.childPhoto,
      LocalServerPicLink: file?.childPhotoLocal,
      AudioLink: file?.recipientName,
      AudioLocalServerLink: file?.recipientNameLocal,
      FamilyPhotoLink: file?.familyPhoto,
      LocalServerFamilyPhotoLink: file?.familyPhotoLocal,
    };

    let birthdAyData = {
      AudioS3Link: file?.recordchildhbd,
      AudioLocalServerLink: file?.recordchildhbdLocal,
      userVideoId: id,
    };

    let greetingData = {
      AudioS3Link: isDefaultMelody == 1 ? "google.com" : file?.greetingAudio,
      AudioLocalServerLink:
        isDefaultMelody == 1 ? "google.com" : file?.greetingAudioLocal,
      userVideoId: id,
      IsDefaultAudio: isDefaultMelody,
    };

    if (props.name === "littleknowitall") {
      if (
        data?.FamilyPhotoLink &&
        data?.RecipientChildPicdataLink &&
        greetingData?.AudioS3Link
      ) {
        dispatch(actions.packageLoading(true));
        dispatch(
          actions.uploadrecipientDataLittle(data, greetingData, props.next)
        );
      } else {
        NotificationManager.warning(
          "Name Recording, greeting Recording, Child Pic, Family Pic.",
          "Fields Missing",
          10000
        );
      }
    } else {
      if (
        data?.FamilyPhotoLink &&
        data?.RecipientChildPicdataLink &&
        greetingData?.AudioS3Link &&
        birthdAyData?.AudioS3Link &&
        data?.AudioLink
      ) {
        dispatch(actions.packageLoading(true));
        dispatch(
          actions.uploadrecipientData(
            data,
            birthdAyData,
            greetingData,
            props.next
          )
        );
      } else {
        NotificationManager.warning(
          "Name Recording, greeting Recording, Child Pic, Family Pic.",
          "Fields Missing",
          2000
        );
      }
    }
  };

  // file upload Image....................
  async function handleChange(event) {
    console.log(event.target.files[0]);
    // loading and disable field states
    setModalCentered({ bool: true, file: event.target.files[0] });
    imageEvent.current = event;
  }

  console.log(file, "gilessssss");

  async function uploadIamge(file) {
    let event = imageEvent.current;
    setFileUploadDisable(true);
    const loading = `${event.target.name}Loading`;
    setModalCentered({ bool: false, file: null });

    setFile((prev) => ({
      ...prev,
      [loading]: true,
      [event.target.name]: null,
    }));
    // upload promise called

    uploadFile(event, id, cancelFileupload, file)
      .then((res) => {
        setFile((prev) => ({
          ...prev,
          [event.target.name]: res[1]?.data?.result?.Location,
          [`${event.target.name}Local`]: res[0]?.data?.FileData?.FileUrl,
          [`${event.target.name}Error`]: null,
          [loading]: false,
        }));
        setFileUploadDisable(false);
        setModalCentered({ bool: false, file: null });
      })
      .catch((error) => {
        setFile({
          ...file,
          [`${event.target.name}Error`]: caution,
          [loading]: false,
        });
        setFileUploadDisable(false);
      });
  }

  // audio Upload.........................
  async function audioHandler(audio) {
    // loading and disable field states
    const objKey = Object.keys(audio);
    setFileUploadDisable(true);
    const loading = `${objKey}Loading`;

    console.log(loading, file.greetingAudioLoading);
    setFile({ ...file, [loading]: true, [objKey]: null });

    // upload promise called
    uploadAudio(audio, id)
      .then((res) => {
        setFileUploadDisable(false);
        setFile({
          ...file,
          [objKey]: res[1]?.data?.result?.Location,
          [`${objKey}Local`]: res[0]?.data?.FileData?.FileUrl,
          [loading]: false,
          audioError: null,
        });
      })
      .catch((error) => {
        setFileUploadDisable(false);
        setFile({
          ...file,
          audioError: caution,
          [loading]: false,
        });
      });
  }

  // fetch file from front end to upload
  let domainName = window?.location?.origin;
  async function createFile() {
    let response = await fetch(`${domainName}${sampleAudio}`);
    let data = await response.blob();
    let metadata = {
      type: "audio/ogg",
    };
    let file = new File([data], "melody.ogg", metadata);
    // ... do something with the file or return it
    let greetingAudio = { greetingAudio: file };
    console.log(greetingAudio?.greetingAudio?.name);
    audioHandler(greetingAudio);
  }

  // on Recording Voices

  const onRecording = (data, boolean) => {
    setIsDefaultMelody(0);
    setFile({
      ...file,
      audioError: null,
    });
    let nameLength = recipientName.length;
    let timeinSec = nameLength + 2;
    let timeinMilliSec = timeinSec * 1000;
    let totaltime = timeinMilliSec * 2;
    let time =
      data === "recipientName"
        ? totaltime
        : data === "greetingAudio"
        ? 10000
        : 5000;
    startRecording(data);

    // after some time recording stop
    onClearTimeout.current = setTimeout(function () {
      if (boolean) {
        onStopRecording(data);
        console.log("this is", time);
      }
    }, time);
  };

  // onClick to cancel  pics
  const onCancelPic = (data, localData) => {
    let state = { ...file };
    delete state?.[data];
    delete state?.[localData];
    console.log("cancel");
    setFile(state);
  };

  // onClick to cancel upload pics
  const cancelUpload = () => {
    console.log("cancel");
    if (cancelFileupload.current) {
      cancelFileupload.current("user cancel this req");
    }
  };

  // Navigator audio permission
  const audioPermission = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        dispatch(actions.mediaPermisssion(stream));
      })
      .catch((err) => {
        NotificationManager.warning(
          "Microphone is currently blocked please enable it"
        );
      });
  };

  // audio recorder
  const onStopRecording = (data) => {
    stopRecording(data);
    setFile({ ...file, [data]: null });
  };

  const onChangeRadio = (e) => {
    setRadioButtonId({ ...radioButtonId, [e.target.name]: e.target.id });
  };

  // loading component
  if (loading) {
    return <div style={{ height: "75vh" }}></div>;
  }

  // Error
  if (props?.error) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "50vh" }}>
        <h1>Error! refresh your app or come back later</h1>
      </div>
    );
  }
  // format date for date input

  var separators = ["-"];
  let x = packageDetail?.RecipientBirthday;
  if (navigator.userAgent.indexOf("Chrome") === -1) {
    for (var i = 0; i < separators.length; i++) {
      var rg = new RegExp("\\" + separators[i], "g");
      x = x?.replace(rg, " " + separators[i] + " ");
    }
  }
  var formatedDate = moment(x).format("YYYY-MM-DD");
  var todayDate = new Date().toISOString().slice(0, 10);

  // static Data
  const HeadingHr =
    props.name === "littleknowitall"
      ? pinkHr
      : props.name === "varsitybaby"
      ? blueHr
      : props.name === "upperclassmen"
      ? purpleHr
      : purpleHr;

  const payButton =
    props.name === "littleknowitall"
      ? "payButtonPink"
      : props.name === "varsitybaby"
      ? "payButtonBlue"
      : props.name === "upperclassmen"
      ? "payButtonPurple"
      : "payButtonPurple";

  const payButtonNotSelect =
    props.name === "littleknowitall"
      ? "payButtonPinkNotSelect"
      : props.name === "varsitybaby"
      ? "payButtonBlueNotSelect"
      : props.name === "upperclassmen"
      ? "payButtonPurpleNotSelect"
      : "payButtonPurpleNotSelect";

  const wizardActive =
    props.name === "littleknowitall"
      ? "circleActivePink"
      : props.name === "varsitybaby"
      ? "circleActiveBlue"
      : props.name === "upperclassmen"
      ? "circleActivePurple"
      : "circleActivePurple";

  const wizardComplete =
    props.name === "littleknowitall"
      ? "circleCompletePink"
      : props.name === "varsitybaby"
      ? "circleCompleteBlue"
      : props.name === "upperclassmen"
      ? "circleCompletePurple"
      : "circleCompletePurple";

  return (
    <div className="p-3">
      {/* first heading.............................
      ............................................ */}
      <div className="my-4">
        <div className="text-center">
          <h1 className="font-size-22 m-0 color-DarkGray fw-600 ">
            Let's start creating your video
          </h1>
          <div className="">
            <p className="font-size-16 my-2  color-Gray fw-600">
              Simple easy step by step process!
            </p>
            <img className="" src={HeadingHr} />
          </div>
        </div>
      </div>

      {/* Side form indicator .............................
      ............................................ */}
      <div className="row">
        <div className="col-md-3 d-none d-md-block">
          {props.allSteps?.map((item) => (
            <div
              className={`cursor-pointer ${
                item.order < props.current
                  ? wizardComplete
                  : item.order === props.current
                  ? wizardActive
                  : "circle"
              } `}
              onClick={() => props.jump(item.order)}>
              {item.order}
            </div>
          ))}
        </div>
        <div className="col-md-9">
          {/* Form submit .............................
               ............................................ */}
          <form onSubmit={onSubmit}>
            <div>
              {/* <form> */}
              <div className="row">
                {/* Recipient's Name * .............................
               ............................................ */}
                <div className="col-md-6 my-2">
                  <FormGroup>
                    <Label className="step2Label">
                      Recipient's Name * (Do not use special charaters i.e. "*"
                      "-" "'")
                    </Label>
                    <Input
                      className="step2Input"
                      maxLength="12"
                      type="text"
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^A-Za-z]/gi, "");
                        if (e.target.value.length <= 12) {
                          setRecipientName(value.toUpperCase());
                        }
                      }}
                      // onChange={(e) =>
                      //   setRecipientName(e.target.value.toUpperCase())
                      // }
                      name="recipientName"
                      style={{ textTransform: "uppercase" }}
                      value={recipientName}
                      required
                      id="exampleEmail"
                      placeholder="Enter Recipient's
                    Name"
                    />
                  </FormGroup>
                </div>
                {props.name === "littleknowitall" ? null : (
                  <div className="col-md-6 my-2">
                    {/*  Record recipient’s name letter by letter .
                    ............................................ */}
                    <div>
                      <Label className="step2Label">
                        Record recipient’s name letter by letter, followed by
                        pronunciation *
                      </Label>

                      <div className="d-flex">
                        <div
                          className={`d-flex justify-content-center mt-2 recordingbox ${
                            recipientName.length && "cursor-pointer"
                          }`}
                          onClick={
                            nameAudioPlay
                              ? null
                              : !mediaPermission?.id
                              ? audioPermission
                              : () => {
                                  !isRecording?.recording &&
                                    recipientName.length &&
                                    startNameCountDown();
                                  !isRecording?.recording &&
                                    recipientName.length &&
                                    onRecording("recipientName", true);
                                }
                          }>
                          <img
                            className="w-100"
                            src={
                              isRecording?.data === "recipientName" &&
                              isRecording.recording === true
                                ? audioGif
                                : mic
                            }
                          />
                        </div>
                        {file.audioError ? (
                          <img
                            className="mx-3"
                            width="55"
                            height="48"
                            src={file.audioError}
                          />
                        ) : (
                          <div className="mx-3 d-flex align-items-center">
                            {!nameAudioPlay && <h3>{countDownName}</h3>}
                            <ReactPlayer
                              playing={nameAudioPlay}
                              height="1%"
                              width="1%"
                              onProgress={(data) =>
                                console.log(data, "this is ready")
                              }
                              onReady={(data) =>
                                setAudioLoad({ ...audioLoad, nameAudio: true })
                              }
                              onEnded={() => setnameAudioPlay(false)}
                              preload={true}
                              url={file?.recipientName}
                              controls={false}
                            />
                            {file?.recipientName && (
                              <div
                                className={`mx-3 ${
                                  nameAudioPlay ? "" : "cursor-pointer"
                                }`}
                                onClick={
                                  nameAudioPlay
                                    ? null
                                    : () => {
                                        setnameAudioPlay(true);
                                        startNameCountDown();
                                      }
                                }>
                                {file?.recipientNameLoading === true ||
                                !audioLoad?.nameAudio ? (
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                ) : (
                                  <>
                                    {nameAudioPlay ? (
                                      <div
                                        className={`d-flex justify-content-center mt-2 recordingbox`}>
                                        <img className="w-100" src={audioGif} />
                                      </div>
                                    ) : (
                                      <img width="50" src={playButton} />
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                            {nameAudioPlay && <h3>{countDownName}</h3>}
                            {file?.recipientName && (
                              <div
                                className={`mx-3 ${
                                  nameAudioPlay ? "" : "cursor-pointer"
                                }`}
                                onClick={
                                  nameAudioPlay
                                    ? null
                                    : () => {
                                        !isRecording?.recording &&
                                          startNameCountDown();
                                        !isRecording?.recording &&
                                          onRecording("recipientName", true);
                                      }
                                }>
                                <img width="50" src={replayButton} />
                              </div>
                            )}
                            {file?.recipientNameLoading === true ? (
                              <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {/* Recipient's Age< .............................
                   ............................................ */}
                {/* <div className="col-md-6 my-2">
                  <FormGroup>
                    <Label className="step2Label">Recipient's Age</Label>
                    <Input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength="1"
                      className="step2Input"
                      type="text"
                      name="recipientAge"
                      defaultValue={packageDetail?.RecipientAge}
                      required
                      id="exampleEmail"
                      placeholder="Enter Recipient's Age"
                    />
                  </FormGroup>
                </div> */}
                {/* Recipient's Birthday .............................
                   ............................................ */}
                <div className="col-md-6 my-2">
                  <FormGroup>
                    <Label className="step2Label">
                      Recipient's Birthday * (year does not need to be exact)
                    </Label>
                    <Input
                      className="step2Input"
                      type="date"
                      max={todayDate}
                      name="recipientBirth"
                      defaultValue={formatedDate}
                      required
                      id="exampleEmail"
                      placeholder="Enter Recipient's Birthday"
                    />
                  </FormGroup>
                </div>
                {/*Record Child's Birthda.............................
                   ............................................ */}
                {props.name === "littleknowitall" ? null : (
                  <div className="col-md-6 my-2">
                    <div>
                      <Label className="step2Label">
                        Record child's name and birthday in month/day format
                        (i.e. Jessica's birthday is July 21st) (max 5 sec.)
                      </Label>
                      <div className="d-flex">
                        <div
                          className={`d-flex justify-content-center mt-2  cursor-pointer recordingbox`}
                          onClick={
                            hbdAuioPlay
                              ? null
                              : fileUploadDisable
                              ? null
                              : !mediaPermission?.id
                              ? audioPermission
                              : !isRecording?.recording
                              ? () => {
                                  onRecording("recordchildhbd", true);
                                }
                              : null
                          }>
                          <img
                            className="w-100"
                            src={
                              isRecording?.data === "recordchildhbd" &&
                              isRecording.recording === true
                                ? audioGif
                                : mic
                            }
                          />
                        </div>
                        <ReactPlayer
                          playing={hbdAuioPlay}
                          height="1%"
                          width="1%"
                          onProgress={(data) =>
                            console.log(data, "this is ready")
                          }
                          onReady={(data) =>
                            setAudioLoad({ ...audioLoad, hbdAudio: true })
                          }
                          onEnded={() => setHbdAuioPlay(false)}
                          preload={true}
                          url={file?.recordchildhbd}
                          controls={false}
                        />

                        {file?.recordchildhbd && (
                          <div
                            className={`mx-3 ${
                              hbdAuioPlay ? "" : "cursor-pointer"
                            }`}
                            onClick={() => {
                              setHbdAuioPlay(true);
                            }}>
                            {file?.recordchildhbd === true ||
                            !audioLoad?.hbdAudio ? (
                              <div class="spinner-border mt-2" role="status">
                                <span class="sr-only"></span>
                              </div>
                            ) : (
                              <>
                                {hbdAuioPlay ? (
                                  <div
                                    className={`d-flex justify-content-center mt-2 recordingbox `}>
                                    <img className="w-100" src={audioGif} />
                                  </div>
                                ) : (
                                  <img
                                    style={{ marginTop: "6px" }}
                                    width="50"
                                    src={playButton}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        )}
                        {file?.recordchildhbd && (
                          <div
                            className="mx-3"
                            onClick={
                              hbdAuioPlay
                                ? null
                                : () => {
                                    !isRecording?.recording &&
                                      onRecording("recordchildhbd", true);
                                  }
                            }>
                            <img width="50" src={replayButton} />
                          </div>
                        )}

                        {file?.recordchildhbdLoading === true ? (
                          <div class="spinner-border mt-2" role="status">
                            <span class="sr-only"></span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
                {/*  Upload a photo of child (to be used in video intro) .
                .......................................................*/}
                <div className="my-2">
                  <p className="step2Label">
                    Upload a photo of child (to be used in video intro)
                  </p>
                  <div className="d-flex justify-content-center justify-content-md-between">
                    <div
                      style={{ position: "relative" }}
                      className="imageUpload m-2 d-flex justify-content-center align-items-center">
                      <div
                        className={`${
                          file.childPhoto ? "d-block" : "d-none"
                        } cursor-pointer`}
                        onClick={() =>
                          onCancelPic("childPhoto", "childPhotoLocal")
                        }
                        style={{ position: "absolute", top: 5, right: 15 }}>
                        <img width="15" src={cross} />
                      </div>
                      {file?.childPhotoLoading ? (
                        <div
                          className={` cursor-pointer`}
                          onClick={() => cancelUpload()}
                          style={{ position: "absolute", top: 5, right: 15 }}>
                          <img width="15" src={cross} />
                        </div>
                      ) : null}

                      <label for="child-photo">
                        {file?.childPhotoLoading ? (
                          <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                          </div>
                        ) : (
                          <>
                            {file?.childPhotoError ? (
                              <img
                                className="cursor-pointer"
                                width="100"
                                src={file?.childPhotoError}
                              />
                            ) : (
                              <img
                                style={{
                                  maxWidth: "170px",
                                  maxHeight: "170px",
                                }}
                                className="cursor-pointer"
                                src={
                                  file?.childPhoto
                                    ? file?.childPhoto
                                    : fileUpload
                                }
                              />
                            )}
                          </>
                        )}
                      </label>

                      {/* <label
                        className={`${file?.childPhotoError ? "h-100" : ""}`}
                        for="child-photo"
                      >
                        {file?.childPhotoLoading ? (
                          <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                          </div>
                        ) : (
                          <>
                            {file?.childPhotoError ? (
                              <img
                                className="cursor-pointer"
                                src={file?.childPhotoError}
                              />
                            ) : (
                              <img
                                className="w-100 cursor-pointer"
                                src={
                                  file?.childPhoto
                                    ? file?.childPhoto
                                    : fileUpload
                                }
                              />
                            )}
                          </>
                        )}
                      </label> */}

                      {fileUploadDisable ? null : (
                        <Input
                          accept="image/x-png,image/jpeg"
                          id="child-photo"
                          name="childPhoto"
                          onChange={handleChange}
                          type="file"
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* choose Personal Greetings.................
                ............................................ */}
                <div className="my-2">
                  <div>
                    <p className="step2Label">
                      Choose personal greeting and theme *
                    </p>
                  </div>
                  <div className="row">
                    {greetingAndThemeRadio.map((item) => (
                      <div
                        key={item.id}
                        className="col-md-6 pb-2 d-flex align-items-center">
                        <input
                          className="mr-10"
                          type="radio"
                          id={item.id}
                          checked={
                            item.id ===
                            parseInt(radioButtonId?.personalGreeting)
                          }
                          name={"personalGreeting"}
                          required
                          onChange={onChangeRadio}
                          value={item.value}
                        />
                        <label className="font-size-16 color-Gray fw-500">
                          &nbsp;{item.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                {radioButtonId?.personalGreeting === "1" ||
                radioButtonId?.personalGreeting === 1 ? (
                  <div>
                    <div>
                      <p className="font-size-16 my-3  color-Gray fw-600">
                        If this is for a birthday, please indicate year *
                      </p>
                    </div>

                    <div className="row">
                      {ifBirthDay.map((item) => (
                        <div key={item.id} className="col-6 pb-2">
                          <input
                            className="mr-10"
                            type="radio"
                            checked={
                              item.labelValue === radioButtonId?.ifForBirthDay
                            }
                            id={item.labelValue}
                            name={"ifForBirthDay"}
                            required
                            onChange={onChangeRadio}
                            value={props?.state?.ifForBirthDay}
                          />
                          <label className="font-size-16 color-Gray fw-500">
                            &nbsp;{item.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {/* Upload a photo of yourself or family (to be used in video intro)*/}
                <div className="my-2">
                  <p className="step2Label">
                    Upload a photo of yourself (giver) or family photo (giver)
                    for personal greeting
                  </p>
                  <div className="d-flex justify-content-center justify-content-md-between">
                    <div
                      style={{ position: "relative" }}
                      className="imageUpload m-2 d-flex justify-content-center align-items-center">
                      <div
                        className={`${
                          file.familyPhoto ? "d-block" : "d-none"
                        } cursor-pointer`}
                        onClick={() =>
                          onCancelPic("familyPhoto", "familyPhotoLocal")
                        }
                        style={{ position: "absolute", top: 5, right: 15 }}>
                        <img width="15" src={cross} />
                      </div>

                      {file?.familyPhotoLoading ? (
                        <div
                          className={`cursor-pointer`}
                          onClick={() => cancelUpload()}
                          style={{ position: "absolute", top: 5, right: 15 }}>
                          <img width="15" src={cross} />
                        </div>
                      ) : null}

                      <label for="yourself-photo">
                        {file?.familyPhotoLoading ? (
                          <div class="spinner-border" role="status">
                            <span class="sr-only"></span>
                          </div>
                        ) : (
                          <>
                            {file?.familyPhotoError ? (
                              <img
                                className="cursor-pointer"
                                width="100"
                                src={file?.familyPhotoError}
                              />
                            ) : (
                              <img
                                style={{
                                  maxWidth: "204px",
                                  maxHeight: "170px",
                                }}
                                className="cursor-pointer"
                                src={
                                  file?.familyPhoto
                                    ? file?.familyPhoto
                                    : fileUpload
                                }
                              />
                            )}
                          </>
                        )}
                      </label>

                      {fileUploadDisable ? null : (
                        <Input
                          accept="image/x-png,image/jpeg"
                          id="yourself-photo"
                          name="familyPhoto"
                          onChange={handleChange}
                          type="file"
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <Label className="step2Label my-2">
                    Who is in the photo? (i.e. Mommy And Daddy, Auntie Carol,
                    Dyson Family, etc.)
                  </Label>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Input
                          className="step2Input"
                          maxlength="22"
                          type="text"
                          // onChange={(e) => {
                          //   let value = e.target.value;
                          //   value = value.replace(/[^A-Za-z]/gi, "");
                          //   if (e.target.value.length <= 12) {
                          //     setRecipientName(value.toUpperCase());
                          //   }
                          // }}
                          onChange={(e) => {
                            let value = e.target.value;
                            value = value.replace(/[^A-Za-z ]/gi, "");
                            if (e.target.value.length <= 22) {
                              setWhoIsInThePhotoText(value);
                            }
                          }}
                          name="whoIsInThePhoto"
                          value={whoIsInThePhotoText}
                          required
                          id="exampleEmail"
                          placeholder="Enter Name"
                        />
                      </FormGroup>
                      <div
                        style={{ background: "#eeebeb", borderRadius: "8px" }}>
                        <p className="my-2 p-2 color-DarkGray fw-600">
                          Max 22 characters (no special characters). If more
                          than 22 characters please use last name plus family
                          (i.e. The Carter Family)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Personalized greeeting msg  radio buttons*/}
                <Label className="step2Label my-3">
                  Select default melody or record a personal greeting message.
                  If recording you can stop/start/review/re-record, max 10
                  seconds.
                </Label>
                {isDefaultMelody == 1 ? (
                  <div className="fw-600">selected</div>
                ) : null}

                <div
                  style={{ gap: "20px" }}
                  className="d-flex align-items-center mb-3">
                  <div>
                    <button
                      className={
                        isDefaultMelody == 1 ? payButton : payButtonNotSelect
                      }
                      type="button"
                      onClick={() => {
                        setIsDefaultMelody(1);
                        setFile({ ...file, greetingAudio: null });
                      }}>
                      Default Melody
                    </button>
                  </div>
                  <div>
                    <div
                      className={`d-flex justify-content-center cursor-pointer recordingbox`}
                      onClick={
                        greetingPlay
                          ? null
                          : !mediaPermission?.id
                          ? audioPermission
                          : !isRecording?.recording
                          ? () => {
                              onRecording("greetingAudio", true);
                            }
                          : () => {
                              onStopRecording("greetingAudio");
                              clearTimeout(onClearTimeout.current);
                            }
                      }>
                      <img
                        className="w-100"
                        src={
                          isRecording?.data === "greetingAudio" &&
                          isRecording.recording === true
                            ? micGif
                            : mic
                        }
                      />
                    </div>
                  </div>
                  {/* <div style={{ width: "82px" }}>
                    <p
                      style={{ wordBreak: "break-all" }}
                      className="m-0 font-size-14 text-wrap fw-600"
                    >
                      (start/stop/max 10 sec.)
                    </p>
                  </div> */}
                </div>
                <div>
                  {file?.greetingAudio ? (
                    <ReactPlayer
                      disabl={true}
                      playing={false}
                      height="30px"
                      width="400px"
                      onProgress={(data) => console.log(data, "this is ready")}
                      onReady={(data) =>
                        setAudioLoad({ ...audioLoad, greetingAudio: true })
                      }
                      onStart={() => setGreetingPlay(true)}
                      onEnded={() => setGreetingPlay(false)}
                      preload={true}
                      url={
                        isRecording.recording === true
                          ? null
                          : file?.greetingAudio
                      }
                      controls={true}
                    />
                  ) : (
                    <>
                      {file?.greetingAudioLoading === true ? (
                        <div class="spinner-border" role="status">
                          <span class="sr-only"></span>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-end mt-4 mb-3">
                  <button
                    // hidden={props.isFirst()}
                    disabled={true}
                    onClick={props.prev}
                    className="backButton  mx-3">
                    Back
                  </button>
                  <button
                    // hidden={props.isLast()}
                    type={"submit"}
                    disabled={props.isLast()}
                    className={payButton}>
                    Save & Next
                  </button>
                </div>
              </div>
              {/* </form> */}
            </div>
          </form>
        </div>
      </div>
      <ImageCropModal modalCentered={modalCentered} uploadIamge={uploadIamge} />
    </div>
  );
};

export default FormStep2;
