import React from "react";
import Slider from "react-slick";
import choosePersonalHr from "Assets/images/landingpage/choosePersonalHr.svg";
import disc1 from "Assets/images/landingpage/disc1.png";
import disc2 from "Assets/images/landingpage/disc2.png";
import disc3 from "Assets/images/landingpage/disc3.png";
import { Link } from "react-router-dom";

const ChoosePersonalization = () => {
  const SliderData = [
    {
      id: 1,
      option: "Option 1",
      img: disc1,
      heading: "Little Know It All",
      para: "Features 3 personalization",
      color: "pink",
      link: "package/littleknowitall",
      listItem: [
        {
          id: 13,
          list: "Personalized video intro w/ child's name & photo",
        },
        {
          id: 14,
          list: "Written greeting message w/ photo of you ",
        },
        {
          id: 15,
          list: "Optional audio message from you",
        },
        {
          id: 16,
          list: "Select your learning content (optional)",
        },
      ],
    },
    {
      id: 2,
      option: "Option 2",
      img: disc2,
      heading: "Varsity Baby",
      para: "Features 5 personalization",
      color: "blue",
      link: "package/varsitybaby",
      listItem: [
        {
          id: 17,
          list: "Everything from Little Know It All",
        },
        {
          id: 18,
          list: "Animated spelling & pronunciation of child's name w/ photo",
        },
        {
          id: 19,
          list: "Animation of child's birthday w/photo",
        },
        {
          id: 20,
          list: "Family photos & titles  merged into alphabet",
        },
      ],
    },
    {
      id: 3,
      option: "Option 3",
      img: disc3,
      heading: "Upperclassmen",
      para: "Features 7 personalization",
      color: "purple",
      link: "package/upperclassmen",
      listItem: [
        {
          id: 21,
          list: "Everything from Option 1 & Option 2 ",
        },
        {
          id: 22,
          list: "We add your voice to the learning content ",
        },
        {
          id: 23,
          list: "Record up to 5 sections",
        },
        {
          id: 24,
          list: "Your baby learns to your Voice!",
        },
      ],
    },
  ];

  const settings = {
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <div className="my-5">
      <div className="">
        <h1 className="font-size-38 color-darkGreen fw-600 text-center">
          Choose Your Video Personalization Package
        </h1>
        <div className="choosePersonalizationBack">
          <div className="text-center">
            <p className="font-size-22 color-DarkGray fw-600">
              Three options to choose from
            </p>
            <img src={choosePersonalHr} />
          </div>
          <div className="container chooseVideo mt-5 pb-3">
            <Slider className="" {...settings}>
              {SliderData?.map((item) => (
                <div
                  key={item.id}
                  className="d-flex justify-content-center onHoverCard"
                >
                  <div
                    className={`${
                      item.color == "pink"
                        ? "sliderCardPink"
                        : item.color == "blue"
                        ? "sliderCardBlue"
                        : "sliderCardPurple"
                    }  mx-1 p-3`}
                  >
                    <div className="d-flex  flex-column alilgn-items-center justify-content-center text-center">
                      <p
                        className={`${
                          item.color == "pink"
                            ? "sliderOptionPink"
                            : item.color == "blue"
                            ? "sliderOptionBlue"
                            : "sliderOptionPurple"
                        } m-0 mb-3`}
                      >
                        {item.option}
                      </p>
                      <div className="w-100 d-flex justify-content-center mb-3">
                        <img width="250" src={item.img} />
                      </div>
                      <h4 className="m-0 mb-2 color-DarkGray font-size-25 fw-600">
                        {item.heading}
                      </h4>
                      <p className="m-0 mb-3 color-DarkGray font-size-16 fw-600">
                        {item.para}
                      </p>
                    </div>
                    <div style={{ height: "140px" }}>
                      <p className="font-size-16 color-Gray fw-600 m-0 p-0">
                        Personalized with:
                      </p>
                      <ul className="font-size-16 color-Gray fw-600 slickListItem">
                        {item.listItem.map((item) => (
                          <li key={item.id}>{item.list}</li>
                        ))}
                      </ul>
                    </div>
                    <Link
                      className="color-white text-decoration-none"
                      to={item.link}
                    >
                      <p
                        className={`${
                          item.color == "pink"
                            ? "sliderExplorePink"
                            : item.color == "blue"
                            ? "sliderExploreBlue"
                            : "sliderExplorePurple"
                        } text-center mt-4 cursor-pointer`}
                      >
                        Explore More
                      </p>
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosePersonalization;
