import React, { useState } from "react";
import { Link } from "react-router-dom";
import Scroll from "react-scroll";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import logo from "../../Assets/images/landingpage/logo.png";
import "./style.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as authActions from "auth/store/actions";
import LogoutModal from "components/modal/logoutModal";
import caretDown from "Assets/images/landingpage/caretDown.png";

const Header = () => {
  const dispatch = useDispatch();

  const authCheck = useSelector(({ auth }) => auth.login.success);
  const [open, isOpen] = useState(false);
  const [modalCentered, setModalCentered] = useState(false);
  const [dropDownKey, setDropDownKey] = useState(false);

  const path = window.location.pathname;

  const toggle = () => {
    isOpen(!open);
  };

  const logOut = () => {
    dispatch(authActions.logOut());
  };

  const dropDown = (key) => {
    if (dropDownKey === key) {
      setDropDownKey("");
    } else {
      setDropDownKey(key);
    }
  };

  return (
    <div style={{ height: "73px" }}>
      <div style={{ zIndex: "1" }} className="position-A w-100">
        <Navbar className="px-3" color="white" light expand="lg">
          <div className="logoDiv">
            <Link to="/">
              <img src={logo} className="w-100" />
            </Link>
          </div>

          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={open} navbar>
            <Nav style={{ marginLeft: "auto" }} navbar>
              <NavItem onClick={() => isOpen(false)}>
                <Link
                  className="p-0 mx-3 py-3 greenHr ffamilybaloo"
                  style={{ textDecoration: "none" }}
                  to="/"
                >
                  Home
                </Link>
              </NavItem>
              <div className="d-block d-lg-none">
                <NavItem
                  className="p-0 cursor-pointer py-3 orangePackages ffamilybaloo"
                  style={{ textDecoration: "none", margin: "0px 7px 0px 15px" }}
                  onClick={() => dropDown("1")}
                >
                  Packages
                  <img className="mb-1 mx-2" width="8" src={caretDown} />
                </NavItem>
                <div
                  className={`mx-4 ${
                    dropDownKey === "1" ? "d-child-159" : "hide-child"
                  }`}
                >
                  <NavItem onClick={() => isOpen(false)}>
                    <Link
                      className="p-0 mx-3 py-3 orangePackagesLittle ffamilybaloo"
                      style={{ textDecoration: "none" }}
                      to="/package/littleknowitall"
                    >
                      Little Know It All
                    </Link>
                  </NavItem>{" "}
                  <NavItem onClick={() => isOpen(false)}>
                    <Link
                      className="p-0 mx-3 py-3 orangePackagesVarsity ffamilybaloo"
                      style={{ textDecoration: "none" }}
                      to="/package/varsitybaby"
                    >
                      Varsity Baby
                    </Link>
                  </NavItem>{" "}
                  <NavItem onClick={() => isOpen(false)}>
                    <Link
                      className="p-0 mx-3 py-3 orangePackagesUpper ffamilybaloo"
                      style={{ textDecoration: "none" }}
                      to="/package/upperclassmen"
                    >
                      Upperclassmen
                    </Link>
                  </NavItem>
                </div>
              </div>
              <div className="d-none d-lg-block">
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    className="p-0 mx-3 py-3 orangePackages ffamilybaloo"
                    nav
                    caret
                  >
                    Packages
                  </DropdownToggle>
                  <DropdownMenu className="p-0" right>
                    <Link
                      onClick={() => isOpen(false)}
                      className="text-decoration-none"
                      to="/package/littleknowitall"
                    >
                      <DropdownItem className="navDrop-pink fw-600 font-size-14 py-2">
                        Little Know It All
                      </DropdownItem>
                    </Link>
                    <Link
                      onClick={() => isOpen(false)}
                      className="text-decoration-none"
                      to="/package/varsitybaby"
                    >
                      <DropdownItem className="navDrop-blue fw-600 font-size-14 py-2">
                        Varsity Baby
                      </DropdownItem>
                    </Link>
                    <Link
                      onClick={() => isOpen(false)}
                      className="text-decoration-none"
                      to="/package/upperclassmen"
                    >
                      <DropdownItem className="navDrop-purple fw-600 font-size-14 py-2">
                        Upperclassmen
                      </DropdownItem>
                    </Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
              <Scroll.Link
                activeClass="aboutActive"
                to="about"
                spy={true}
                smooth={true}
              >
                <NavItem
                  className="p-0 mx-3 cursor-pointer py-3 blueHr ffamilybaloo"
                  onClick={() => isOpen(false)}
                >
                  About
                </NavItem>
              </Scroll.Link>
              <Scroll.Link
                activeClass="aboutActive"
                to="contact"
                spy={true}
                smooth={true}
              >
                <NavItem
                  className="p-0 mx-3 py-3 skyBlueHr cursor-pointer ffamilybaloo"
                  onClick={() => isOpen(false)}
                >
                  Contact
                </NavItem>
              </Scroll.Link>

              <div className="d-block d-lg-none">
                <NavItem
                  className="p-0 py-3 cursor-pointer MyAccount ffamilybaloo"
                  style={{ textDecoration: "none", margin: "0px 7px 0px 15px" }}
                  onClick={() => dropDown("2")}
                >
                  My Account <i class="fa-caret-down"></i>
                  <img className="mb-1 mx-1" width="8" src={caretDown} />
                </NavItem>
                <div
                  className={`mx-4 ${
                    dropDownKey === "2" ? "d-child" : "hide-child"
                  }`}
                >
                  {authCheck === true ? (
                    <Link
                      onClick={() => isOpen(false)}
                      style={{ color: "black" }}
                      className="text-decoration-none cursor-pointer"
                      to="/myaccount"
                    >
                      <NavItem
                        onClick={() => isOpen(false)}
                        className="mx-3 fw-600 font-size-14 py-2"
                      >
                        My Account
                      </NavItem>
                    </Link>
                  ) : (
                    <NavItem
                      style={{ color: "gray" }}
                      disabled
                      className="mx-3  fw-600 cursor-pointer font-size-14 py-2"
                    >
                      My Account
                    </NavItem>
                  )}

                  <NavItem onClick={() => isOpen(false)}>
                    {authCheck === true ? (
                      <NavItem
                        onClick={() => setModalCentered(true)}
                        className="mx-3 fw-600 font-size-14 py-2 cursor-pointer"
                      >
                        Logout
                      </NavItem>
                    ) : (
                      <Link
                        style={{ color: "black" }}
                        onClick={() => isOpen(false)}
                        className="text-decoration-none cursor-pointer"
                        to="/login"
                      >
                        <NavItem className="mx-3 fw-600 font-size-14 py-2">
                          Login
                        </NavItem>
                      </Link>
                    )}
                  </NavItem>
                </div>
              </div>

              <div className="d-none d-lg-block">
                <UncontrolledDropdown className="myAccountMargin" nav>
                  <DropdownToggle
                    className="p-0 mx-3 py-3  MyAccount ffamilybaloo"
                    nav
                    caret
                  >
                    My Account
                  </DropdownToggle>
                  <DropdownMenu className="p-0" right>
                    {authCheck === true ? (
                      <Link
                        onClick={() => isOpen(false)}
                        className="text-decoration-none"
                        to="/myaccount"
                      >
                        <DropdownItem
                          onClick={() => isOpen(false)}
                          className="fw-600 font-size-14 py-2"
                        >
                          My Account
                        </DropdownItem>
                      </Link>
                    ) : (
                      <DropdownItem
                        onClick={() => isOpen(false)}
                        disabled
                        className="fw-600 font-size-14 py-2"
                      >
                        My Account
                      </DropdownItem>
                    )}
                    {authCheck === true ? (
                      <DropdownItem
                        onClick={() => setModalCentered(true)}
                        className="fw-600 font-size-14 py-2"
                      >
                        Logout
                      </DropdownItem>
                    ) : (
                      <Link
                        onClick={() => isOpen(false)}
                        className="text-decoration-none"
                        to="/login"
                      >
                        <DropdownItem className="fw-600 font-size-14 py-2">
                          Login
                        </DropdownItem>
                      </Link>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

              <NavItem>
                <Link
                  className={`mx-2 p-2 ${
                    path === "/packages"
                      ? "getStartedbgGreen"
                      : "getStartedGreen"
                  }   ffamilybaloo text-nowrap`}
                  onClick={() => isOpen(false)}
                  style={{ textDecoration: "none" }}
                  to="/packages"
                >
                  Create Video
                </Link>
              </NavItem>
              <NavItem onClick={() => isOpen(false)}>
                <a
                  className="p-0 mx-3 py-3 MyAccount ffamilybaloo"
                  style={{ textDecoration: "none" }}
                  href="https://help.fromubaby.com/"
                  target="_blank"
                >
                  Help
                </a>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>

        <LogoutModal
          setModalCentered={setModalCentered}
          modalCentered={modalCentered}
        />
      </div>
    </div>
  );
};

export default Header;
