import Domain from "lib/Config";
import axios from "axios";

export const uploadFile = (event, id, cancelFileupload, file) => {
  console.log(id, file, "this is file");
  // For Local file upload...........................
  const data = new FormData();
  data.append("UserFile", file);
  const fileObj = data.get("UserFile");
  const fileType = event.target.files[0].name.slice(
    ((fileObj.name.lastIndexOf(".") - 1) >>> 0) + 2
  );

  console.log(fileObj);
  data.append("VideoId", id);
  data.append("Fileformat", "png");
  let config = {
    method: "POST",
    url: "/userVideos/UploadFileToLocalServer",
    data: data,
    baseURL: Domain,
  };
  let localUpload = axios(config);

  // For s3 file upload...........................
  const s3Data = new FormData();
  s3Data.append("UserFile", file);
  let options = {
    cancelToken: new axios.CancelToken(
      (cancel) => (cancelFileupload.current = cancel)
    ),
  };
  let s3Upload = axios.post(
    `${Domain}/uservideos/uploadfiletosebucket`,
    s3Data,
    options
  );
  return Promise.all([localUpload, s3Upload]);
};

export const uploadAudio = (audio, id) => {
  const objKey = Object.keys(audio);
  if (audio[objKey].name !== "melody.ogg") {
    audio[objKey].name = "audio.ogg";
  }
  // for local server file upload
  const data = new FormData();
  data.append("UserFile", audio?.[objKey]);
  data.append("VideoId", id);
  data.append("Fileformat", ".ogg");
  let localUpload = axios.post(
    `${Domain}/userVideos/UploadFileToLocalServer`,
    data
  );

  // for s3 file upload
  const s3Data = new FormData();
  s3Data.append("UserFile", audio?.[objKey]);

  let s3Upload = axios.post(
    `${Domain}/uservideos/uploadfiletosebucket`,
    s3Data
  );

  return Promise.all([localUpload, s3Upload]);
};
