import React, { useEffect } from "react";
import PackageNav from "./packageNav";
import PackagesAndDetail from "./packagesAndDetail";
import "./style.css";

const Packages = () => {
  useEffect(() => {
    window.scroll(0, 0);
  });

  const NavText = "HOME / PRODUCTS";
  return (
    <div>
      <div className="">
        <PackageNav name="" NavText={NavText} />
        <PackagesAndDetail />
      </div>
    </div>
  );
};

export default Packages;
